import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

// i18N hook
import { Trans } from 'react-i18next';
import { withTranslation } from "react-i18next";

function Project() {
    return (
        <>
            <div className="section pt-1">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="penta-title mt-1">
                                <Trans>products.training.title</Trans>
                            </div>
                            <h5><Trans>products.training.description</Trans></h5>
                        </Col>

                    </Row>
                    <Row>
                        <Col md="4" className="align-self-start">
                            <center>
                                <div class="bg-primary-0 text-white indicator-div-sm"><span className="indicator-icon-sm">1</span></div>
                                <h5 className="font-weight-bold"><Trans>products.training.item1</Trans></h5>
                                <h5><Trans>products.training.itemdescription1</Trans></h5>
                            </center>
                        </Col>
                        <Col md="4">
                            <center>
                                <div class="bg-secondary-1-0 text-white indicator-div-sm"><span className="indicator-icon-sm">2</span></div>
                                <h5 className="font-weight-bold"><Trans>products.training.item2</Trans></h5>
                                <h5><Trans>products.training.itemdescription2</Trans></h5>
                            </center>
                        </Col>
                        <Col md="4">
                            <center>
                                <div class="bg-secondary-2-0 text-white indicator-div-sm"><span className="indicator-icon-sm">3</span></div>
                                <h5 className="font-weight-bold"><Trans>products.training.item3</Trans></h5>
                                <h5><Trans>products.training.itemdescription3</Trans></h5>
                            </center>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )
}

export default withTranslation()(Project);