import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";

import {
  Col,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";



const Annoucements = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const loadingFunction = () => {
    console.log("hola");
    props.country === "ES" ? setIsModalVisible(true) : setIsModalVisible(false);
  };

  useEffect(() => {
    loadingFunction();
  }, [props.country, props.region]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    console.log("holi");
    setIsModalVisible(false);
  };
  return (
    <>
      {props.country === "ES" ? (
        <>
          <Modal  isOpen={isModalVisible} onExit={handleOk} className='ignite-modal'>
            <ModalHeader style={{backgroundColor: '#FF6530'}} toggle={handleOk}  />
            <ModalBody style={{backgroundColor: '#FF6530'}}>
              <Row className="justify-content-center">
                <Col className="col-12">
                  <center>
                    <img
                      style={{ width: "auto", height: "20vh" }}
                      src={require("assets/img/lightbulb.png")}
                    />
                  </center>
                </Col>
                <Col className="col-12 my-4">
                  <center>
                    <h2
                      style={{
                        color: "white",
                        lineHeight: "normal",
                        display: "contents",
                        fontWeight: "bold",
                      }}
                    >
                      <Trans>landing2.title1</Trans>{" "}
                      <Trans>landing2.title2</Trans>
                    </h2>
                  </center>
                </Col>
                <Col className="col-12 my-4">
                  <h3
                    style={{
                      color: "white",
                      lineHeight: "normal",
                      display: "contents",
                    }}
                  >
                    <Trans>ignite.annoucement</Trans>
                  </h3>
                </Col>
                <Col className="col-12 my-4">
                  <center>
                    <Button
                      onClick={() =>
                        window.open(
                          props.i18n.language == "ca" ? "/encende" : "/enciende"
                        )
                      }
                      size={"lg"}
                      style={{
                        backgroundColor: "white",
                        color: "var(--color-secondary-2-0)",
                        fontWeight: "bold",
                      }}
                    >
                      <Trans>landing2.button3</Trans>
                    </Button>
                  </center>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </>
      ) : (
        <>
          <div className="section bg-secondary-2-0">
            <Container>
              <Row className="justify-content-center">
                <Col className="col-12">
                  <center>
                    <img
                      style={{ width: "auto", height: "20vh" }}
                      src={require("assets/img/lightbulb.png")}
                    />
                  </center>
                </Col>
                <Col className="col-12 my-4">
                  <center>
                    <h2
                      style={{
                        color: "white",
                        lineHeight: "normal",
                        display: "contents",
                        fontWeight: "bold",
                      }}
                    >
                      <Trans>landing2.title1</Trans>{" "}
                      <Trans>landing2.title2</Trans>
                    </h2>
                  </center>
                </Col>
                <Col className="col-12 my-4">
                  <h3
                    style={{
                      color: "white",
                      lineHeight: "normal",
                      display: "contents",
                    }}
                  >
                    <Trans>ignite.annoucement</Trans>
                  </h3>
                </Col>
                <Col className="col-12 my-4">
                  <center>
                    <Button
                      onClick={() =>
                        window.open(
                          props.i18n.language == "ca" ? "/encende" : "/enciende"
                        )
                      }
                      size={"lg"}
                      style={{
                        backgroundColor: "white",
                        color: "var(--color-secondary-2-0)",
                        fontWeight: "bold",
                      }}
                    >
                      <Trans>landing2.button3</Trans>
                    </Button>
                  </center>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default withTranslation()(Annoucements);
