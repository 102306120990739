export default {
    index: {
        header: {
            title: '¿Quieres que tus acciones formativas potencien el desarrollo de las habilidades sociales y personales?',
            subtitle: 'Desafiamos el statu quo, ayudando a individuos y organizaciones a ganar perspectiva y crecer desde dentro hacia afuera'
        },
        motivation: {
            title: '¿Por qué Pentabilities?',
            description1: 'Urge que las personas respondamos a este mundo que cambia rápidamente. Necesitamos herramientas que nos ayuden a aprender con propósito, para que podamos adaptarnos, participar y transformar el mundo.',
            taxonomy: 'The Taxonomy Project',
            taxonomydescription: 'en la Universidad de Harvard, describe cómo el aprendizaje social y emocional (SEL, por sus siglas en inglés) puede organizarse en tres áreas interrelacionadas: social, emocional y cognitivo.',
            description2: 'Actualmente centros educativos y de trabajo están perdiendo la oportunidad de desarrollar esas habilidiades, llevando a:',
            item1: 'El deterioro de las motivaciones internas para aprender, adaptarse y sobrevivir',
            item2: 'Ampliación de la brecha de habilidades',
            item3: 'Perpetuación de las desigualdades sociales',
            goal: 'Queremos propiciar que las organizaciones e individuos puedan ganar perspectiva y facilitar el crecimiento desde adentro hacia fuera, es decir, permitir a las personas aprender, adaptar, transformar y volver a pensar el mundo en que vivimos.',
            description3: 'Pentabilities reconoce tus motivaciones internas, identifica las habilidades claves para ti que no se miden en las prácticas tradicionales de aprendizaje, y define y promueve estas habilidades a través de evaluaciones basadas en evidencias.',
        },
        challenges: {
            title1: 'Retos actuales',
            description1: 'El problema es que la sociedad carece de un comprensión compartida acerca de lo que significan las habilidades socioemocionales, y de cómo desarrollarlas a través de la educación y el desarrollo profesional.',
            item1: 'Falta de comprensión compartida sobre las habilidades socioemocionales (SES, por sus siglas en inglés).',
            item2: 'Falta de conocimiento sobre cómo mejorar las SES en la educación y el desarrollo profesional.',
            item3: 'Falta de precisión y transparencia en el ámbito del aprendizaje socioemocional (SEL).',
            item4: 'Falta de un lenguaje común en la evaluación de la aptitud socioemocional.',
            title2: 'Nuestra solución',
            description2: 'Proponemos un conjunto finito de comportamientos concretos que puedan demostrar la adquisición de un amplio conjunto de habilidades sociales y emocionales.',
            item5: 'Emplear un conjunto finito de comportamientos concretos que correspondan a un amplio conjunto de SES.',
            item6: 'Crear un objetivo común claro y compartido mediante la comprensión de lo que implica SEL.',
            item7: 'Facilitar la recogida continua de evidencias por parte de los observadores.',
            item8: 'Permitir dar feedback concreto y basado en evidencias.',
            item9: 'Posibilitar la evaluación y mejora de las estrategias de aprendizaje.',
            description3: 'Comportamientos concretos para demostrar las habilidades socioemocionales'
        },
        projects: {
            title: 'Nuestros proyectos',
            title1: 'Cambiando las escuelas de secundaria en Cataluña (España)',
            caption1: 'Difundiendo la metodología y herramientas digitales de Pentabilities en toda la región',
            description1_1: 'Alrededor de 1.000 estudiantes de educación secundaria en Cataluña han participado de las pruebas de la metodología y herramientas digitales de Pentabilities.',
            description1_2: 'Estas escuelas son mentoras de otras escuelas a través de Escola Nova 21 - una red de ~ 500 escuelas que están implementando estrategias de aprendizaje colaborativo y autónomo. Pentabilities ha trabajado conjuntamente con los maestros para integrar la evaluación formativa del aprendizaje socioemocional en sus actividades y metodologías existentes.',
            title2: 'Un espacio concreto para el cambio',
            caption2: 'Creando un producto totalmente desarrollado que se puede incorporar en cualquier escuela española que quiera implementar la metodología Pentabilities',
            description2_1: 'Muchas escuelas utilizan la clase magistral como principal método pedagógico y no saben cómo iniciar el cambio. Para facilitar la transición, junto con la escuela secundaria Liceu Politècnic, hemos creado Fabilities.',
            description2_2: 'Fabilities es un ambiente de aprendizaje que permite a los estudiantes desarrollar proyectos STEM que son guiados y conectados a través de la historia y las humanidades. Las actividades de la clase y las evidencias recogidas durante todo el proyecto facilitan el proceso iterativo para encontrar soluciones innovadoras a la vez que permite el desarrollo de los estudiantes como individuos y como grupo.',
            description2_3: 'El objetivo del proyecto Fabilities es promover el cambio escalable en el sistema educativo español.',
            title3: 'De las escuelas de formación profesional al mercado laboral',
            caption3: 'El desarrollo de un sistema que reduce la brecha de habilidades y permite alinear las capacidades de los estudiantes con las necesidades del mercado laboral',
            description3_1: 'Pentabilities ha proporcionado formación a través del Ministerio de Educación a profesores de once centros de formación profesional que implementan prácticas avanzadas. Hemos ayudado a adaptar sus pedagogías para que puedan beneficiarse plenamente del ciclo de desarrollo Pentabilities - la recogida de evidencias y provisión de feedback. Los datos recogidos serán utilizados para alinear las capacidades de los estudiantes con las expectativas de las empresas.',
            description3_2: 'Pentabilites participó en el concurso “24 hores d´innovació”, donde 24 grupos formados por ocho miembros cada uno compitieron entre sí en sólo 24 horas para resolver retos relacionados con el mundo empresarial. Sus comportamientos y habilidades socioemocionales fueron registrados por observadores externos a través de la aplicación Pentabilities. Aquellos grupos que ganaron los premios del jurado eran los que tenían comportamientos que indicaban unas habilidades socioemocionales más fuertes.',
            title4: 'Educación superior',
            caption4: 'Transformando la educación superior en Europa',
            description4_1: 'Bonn University',
            description4_2: 'En asociación con el Centro de Innovación de la Universidad de Bonn, Pentabilities se utilizó en una clase de economía en línea por primera vez durante la pendemia de Covid-19. La Universidad quiere usar Pentabilities en otros cursos y disciplinas.',
            description4_3: 'ESADE Business School',
            description4_4: 'Respondiendo a las necesidades de nuestra sociedad, ESADE tiene como objetivo integrar el desarrollo de las habilidades socialemocionales en sus programas. Pentabilities se ha asociado con ESADE en la creación de un BA dirigido totalmente a la capacitación de estudiantes de negocios para convertirlos en los próximos agentes de cambio. Pentabilities será la espina vertebral en el diseño de este nuevo título de BA, que se implementará durante en los próximos cinco años.'
        },
        learning: {
            title: 'Pentabilities facilita el ciclo de desarrollo',
            description: 'Las habilidades, productos o ideas son generados siguiendo ciclos de desarrollo. ¡Pentabilities ayuda a definir tu propio ciclo de desarrollo y llevarlo a cabo con fuerza!',
            step1: 'Establece un objetivo:',
            step1description: 'nuestro conjunto de comportamientos concretos facilita crear objetivos específicos y compartidos.',
            step2: 'Diseño de proyectos y actividades:',
            step2description: 'Te ayudamos a diseñar nuevas estrategias para abordar tus objetivos.',
            step3: 'Observar y registrar evidencias:',
            step3description: 'nuestra aplicación facilita la recogida inmediata de evidencias por parte de los usuarios.',
            step4: 'Análisis:',
            step4description: 'nuestra aplicación web facilita la visualización y análisis de datos.',
            step5: 'Feedback para tomar conciencia:',
            step5description: 'te ayudamos a crear una cultura de crecimiento a través de feedback específico y constructivo.',
        },
        products: {
            title: '¿Qué ofrecemos?',
            description1: 'Ayudamos a',
            client1: 'escuelas y universidades',
            client2: 'centros de investigación',
            client3: 'organizaciones no gubernamentales (ONG)',
            client4: 'compañías',
            description2: 'a crear una cultura que potencia el desarrollo de habilidades socioemocionales y florece a través del feedback',
            producttitle1: 'Herramientas',
            productdescription1: 'Herramientas que permiten recopilar y analizar evidencias en tiempo real',
            producttitle2: 'Formación',
            productdescription2: 'Formación paso a paso sobre cómo introducir e implementar Pentabilities. Puedes ver nuestros videos introductorios a continuación:',
            producttitle4: 'Apoyo',
            productdescription4: 'Apoyo personalizado durante el proceso de construcción de una cultura Pentabilities en tu escuela/ONG/empresa',
        },
        testimonials: {
            title: 'Lo que dice la gente',
            testimonial1: 'El solo hecho de disponer de las evidencias es increíblemente útil para crear un lenguaje común y asegurarnos de que estamos hablando de lo mismo.',
            position1: 'Profesor y coordinador de IE Les Vinyes (Escuela Secundaria)',
            testimonial2: 'Vuestra propuesta evita cualquier reticencia potencial de la gerencia para poner en práctica programas corporativos que apoyan el proceso de feedback contínuo. Permite tener una filosofía de líderes a todos los niveles permitiendo a los empleados recibir feedback de su comunidad con un control centralizado.',
            position2: 'Antigua Jefe de Desarrollo de Talento, Educación y Diversidad en EMEA para CA Technologies',
            testimonial3: 'La comprensión de las habilidades sociales y personales de las personas en una organización es esencial para cualquier transformación cultural. Pentabilities permite un análisis preciso de las habilidades sociales y personales basado en evidencias.',
            position3: 'Agile Coach',

        },
        partners: {
            title: 'Con quién trabajamos',
        }
    },
    products: {
        tools: {
            title: 'Herramientas',
            description: 'Nuestras herramientas ofrecen a todos los usuarios soluciones simples para ayudarles en la captura, seguimiento y desarrollo de las habilidades socioemocionales. Las aplicaciones web y móvil son recursos vitales para la implementación de nuestra metodología. Aunque ya tenemos una plataforma genérica que funciona para cualquier organización, también ofrecemos hacer personalizaciones, para integrar Pentabilities con otras herramientas y/o tu intranet o añadir funcionalidades nuevas. Cuéntanos cuáles son tus necesidades.',
            mobile: 'Aplicación móvil',
            mobileshortdescription: 'La aplicación móvil facilita la recogida y clasificación de las evidencias observadas',
            mobileitem1: 'Acceso rápido a tus estudiantes y a los diferentes comportamientos',
            mobileitem2: 'Fácil habilitación de la auto y co-evaluación de grupos',
            mobileitem3: 'Etiqueta tus evidencias para enriquecer el análisis posterior',
            mobiledescription1: 'Ser consciente de uno mismo y capaz de ganar perspectiva para mejorar tus pentabilidades puede ser un gran reto. Las valoraciones se basan, por lo general, en percepciones subjetivas, limitadas y costosas. Además, carecemos de estándares para analizarlas y compararlas en diferentes contextos y a través del tiempo, característica fundamental para su implementación en entornos como escuelas, universidades, programas sociales o empresas.',
            mobiledescription2: 'El objetivo de la aplicación móvil es permitir una evaluación basada en evidencias de habilidades no cognitivas. Facilitamos la recolección de evidencias de comportamiento a través de una aplicación móvil, en cualquier momento y en cualquier lugar. Los comportamientos están definidos para cada habilidad, lo que permite a los observadores recolectar evidencias de estos comportamientos. El análisis de las evidencias introducidas por un amplio conjunto de observadores en diferentes contextos proporciona una imagen única de lo que eres a los ojos de los demás, cómo cambias cuando te enfrentas a diferentes desafíos. Por lo tanto es una imagen clara de tus fortalezas y debilidades y tu potencial para cambiarlas.',
            web: 'Aplicación web',
            webshortdescription: 'La aplicación web está diseñada para proporcionar las gráficas más útiles de los datos recogidos',
            webitem1: 'Análisis de los datos a nivel individual',
            webitem2: 'Análisis de los datos a nivel de grupo para replantear o mejorar el diseño de las actividades y de los entornos',
            webitem3: 'Generación de informes personalizados resumiendo las evidencias recogidas de cada estudiante/participante',
        },
        training: {
            title: 'Formación',
            subtitle: 'Orientación en la incorporación de Pentabilities y capacitación en cada paso de la transformación',
            description: 'Proporcionamos orientación en el proceso de implementación de Pentabilities, y formación de trabajadores y profesores en cada paso de la transformación',
            item1: 'Inducción',
            itemdescription1: 'Comprender la filosofía Pentabilities, comportamientos como demostraciones de constructos, herramientas multi-plataforma',
            item2: 'Implementación',
            itemdescription2: 'La creación de un contexto adecuado para observar comportamientos, aprendizaje basado en proyectos y actividades de colaboración, captura de evidencias, evaluaciones 360º',
            item3: 'Perspectiva',
            itemdescription3: 'Preparación de las sesiones de feedback basadas en la evidencia, la iteración y la adopción de estrategias que afronten los retos identificados que condicionaran la planificació futura.',
        },
        support: {
            title: 'Apoyo',
            description: 'Cada institución tiene sus particularidades y contexto. Pentabilities te ayuda a integrar tu realidad en nuestro sistema. Te ayudamos a pentabilizar tu escuela / ONG / empresa, es decir, crear las herramientas, entornos y cultura para que Pentabilities pueda catalizar tus objetivos.',
            item1: 'Te ayudamos a adaptar tus entornos y herramientas existentes para facilitar la observación y recogida de evidencias',
            item2: 'Adaptamos nuestro portal de analíticas y te ayudamos a adaptar tus entornos para fomentar el feedback interpersonal y promover el crecimiento',
            item3: 'Te ayudamos a abordar tus debilidades, creando planes de acción para mejorar el aprendizaje socioemocional en tu institución',

        }
    },
    research: {
        system: {
            shorttitle: "El sistema",
            title: "¿Qué es el sistema Pentabilities?",
            description: "El sistema Pentabilities está diseñado para desarrollar las cinco grandes habilidades sociales y emocionales, lo que llamamos las “pentabilities” o pentabilidades: Responsabilidad, Cooperación, Autonomía e iniciativa, Gestión emocional y Habilidades de pensamiento. Se ha demostrado que unas buenas habilidades sociales e interpersonales ayudan a mejorar nuestro bienestar y el de nuestro entorno durante toda la vida, además de fomentar el éxito académico y adaptación al mercado de trabajo. El sistema, destinado a ser utilizado en los centros educativos y organizaciones, comienza con la creación de un marco común y se estructura en tres niveles: observación de evidencias, registro de evidencias, y dar un buen feedback.",
            description1: "Pentabilities es el sistema para el desarrollo de las cinco grandes habilidades sociales y emocionales, las pentabilidades: Responsabilidad, Cooperación, Autonomía e iniciativa, Gestión emocional y Habilidades de pensamiento.",
            description2: "Se ha demostrado que unas buenas habilidades sociales e interpersonales ayudan a mejorar nuestro bienestar y el de nuestro entorno durante toda la vida, además de fomentar el éxito académico y adaptación al mercado de trabajo.",
            description3: "El sistema, destinado a ser utilizado en los centros educativos y organizaciones, comienza con la creación de un marco común y se estructura en tres niveles: observar evidencias, registrar evidencias y dar un buen feedback.",
        },
        steps: {
            title1: "Observar Evidencias",
            description1: "A la hora de valorar cada pentabilidad, proponemos acciones específicas, las evidencias, para hacerlo. Las evidencias nos permiten crear un lenguaje común y concreto a la hora de definir las pentabilidades. Además, las evidencias nos ayudarán a proporcionar un buen feedback. Ponemos en práctica la observación de evidencias en diferentes actividades planificadas.",
            fulldescription11: "Las evidencias de las pentabilidades las pueden observar y registrar profesores, monitores y compañeros, en distintos contextos y frente a retos distintos. Esto proporciona una imagen rica, tanto de la variabilidad de las pentabilidades en función de los entornos diseñados, como de la variación de su percepción según situaciones y personas.",
            fulldescription12: "Antes de empezar con las observaciones y los registros, es indispensable leer las definiciones de las pentabilidades y las correspondientes evidencias que las justifican, y consensuar el significado para establecer un lenguaje común y concreto que sirva para delimitar las observaciones.",
            fulldescription13: "Es útil centrarse primero en una sola pentabilidad y sus correspondientes evidencias para adquirir una nueva mirada sin prejuicios. Hacer una diagnosis con el alumnado o con miembros de la organización, consensuar las pentabilidades a incluir en actividades planificadas y construir un marco de confianza son los primeros pasos para trabajar la observación de evidencias.",
            fulldescription14: "Al comienzo, hay que pactar con la imperfección. Es práctico dejarse un tiempo para ejercitar la observación y la escucha activa, sin prejuicios, antes de incorporar también el registro de evidencias. Sin embargo, una vez ejercida una nueva mirada, la observación y el registro forman una acción conjunta.",
            fulldescription15: "Aquí encontraréis el listado de evidencias, o acciones específicas, que justifican las diferentes pentabilidades. Se trata de dedicar tiempo para consensuar el significado y priorizar las pentabilidades y escoger las evidencias en función de la actividad y del contexto de cada centro educativo u organización.",
            title2: "Registrar Evidencias",
            description2: "Planificamos la observación y el registro de evidencias por actividades, sesiones y grupos. Registramos lo que vemos: valoramos la pentabilidad y registramos también la valoración de las evidencias que la justifican. Realizamos las observaciones y los registros a lo largo del tiempo para conseguir una muestra representativa y una visión global del alumnado.",
            fulldescription21: "El registro de las evidencias se tiene que planificar durante las actividades en que el alumnado interaccione. Registramos lo que vemos: valoramos la pentabilidad y registramos también la valoración de las evidencias que la justifican.",
            fulldescription22: "Nuestro objetivo tiene que ser que la observación sea representativa, es decir, no tiene que responder a situaciones extremas que se den en el aula. Por lo tanto, es muy importante que la observación sea aleatoria. Cuantas más observaciones se realicen por alumno, mejor, pero no hay que ir a máximos, sino buscar representatividad en las evidencias recogidas.",
            fulldescription23: "El registro tiene que ser inmediato a la observación de una acción específica. De este modo, evitamos que nuestra memoria nos conduzca a simplificar y etiquetar, y así conseguiremos una visión global. Para facilitar el registro de evidencias y la anotación de comentarios, y posibilitar así el buen feedback posterior, podemos utilizar la aplicación móvil/tableta Pentabilities app. Esta aplicación, desarrollada específicamente para el proyecto Pentabilities, permite el registro y el análisis de las evidencias de las pentabilidades en tiempo real en el aula, ahorrando mucho tiempo.",
            fulldescription24: "Para registrar las evidencias, es recomendable seguir un método, y partir de una programación realista y asumible, sobre todo al principio. Podemos ir por grupos, observar e interactuar con los alumnos entre 5 y 10 minutos a lo largo de una actividad, y, antes de ir al siguiente grupo, registrar lo que hemos visto. Primero valoramos la pentabilidad y después las evidencias que justifican la valoración de la pentabilidad. En algunos casos, será útil añadir comentarios o cualquier otra nueva evidencia o comportamiento.",
            fulldescription25: "Finalizado un grupo, repetimos el procedimiento en otro grupo, y así sucesivamente. Antes de acabar una sesión, también es preferible destinar entre 5 y 10 minutos por grupo para co-evaluar los diferentes miembros. Una opción muy interesante es que cada grupo pare su actividad durante 5-10 minutos y cada miembro observe y registre las pentabilidades de otro grupo. Educar la mirada del observador es una muy buena manera de aprender de uno mismo. Siempre es mejor observar y registrar en diferentes sesiones que no hacer una única observación larga.",
            fulldescription26: "Para registrar las evidencias podemos utilizar la aplicación Pentabilities:",
            fulldescription27: "Cabe tener en cuenta que el registro de evidencias supone un cambio de hábitos. La adaptación es lenta y puede parecer pesada. Es muy importante no registrar tan solo aquellas conductas que confirman las ideas que ya tenemos sobre los alumnos. Las conductas varían según el contexto, y es interesante identificar aquellas situaciones en las que el alumno saca lo mejor de sí mismo, así como aquellas en las que tiene que centrar su mejora.",
            fulldescription28: "La riqueza del sistema se basa en disponer de un registro de comportamientos en diferentes momentos de las actividades y fases de los proyectos, desde el punto de vista de diferentes profesores, materias, compañeros, etc. Esto aporta información muy útil para conocer las motivaciones y los ambientes y entornos que provocan conductas positivas y conductas a evitar de cada individuo.",
            title3: "Dar un buen feedback",
            description3: "El buen feedback se da o en el momento de la observación o posteriormente, según la situación. Un buen feedback es una acción periódica basada en el análisis de las evidencias registradas en un periodo de tiempo. Solo a partir de las evidencias podemos dar un buen feedback, honesto, concreto y útil.",
            fulldescription31: "Dar un buen feedback es también una gran habilidad de comunicación, que requiere tiempo y mucha práctica. No se trata de dar una indicación general, ni tampoco de escribir un comentario al final de una actividad para justificar una nota. Dar un buen feedback es un trabajo sistemático dentro de un marco de confianza y de seguridad emocional. El alumnado tiene que percibir el feedback como una actividad central, útil y de valor en su aprendizaje, y no como un juicio.",
            fulldescription32: "Respuestas habituales como “te tienes que organizar” o “tienes que razonar mejor” son indicaciones generales, pero no dan pistas de cómo mejorar. Hay que partir de las evidencias observadas y registradas, enmarcarlas y conducir una conversación constructiva, con preguntas abiertas y evitando afirmaciones imperativas. Siempre con un tono amable, pero sin huir del rigor y de la honestidad, tanto para mejorar puntos débiles como para celebrar puntos fuertes.",
            fulldescription33: "El mensaje tiene que ser concreto, coherente, claro y útil, alejado de la abstracción. Por este motivo, la recogida de evidencias es la clave y la vía para que el alumnado pueda entender aquello que no acaba de hacer bien. Un buen feedback sistemático orienta al alumnado para saber en qué situación se encuentra y qué puede hacer para mejorar. Este conocimiento personal es el punto de partida para poner en práctica las indicaciones recibidas en la siguiente tarea o proyecto que se llevará a cabo y que por lo tanto habrá que planificar. Y así sucesivamente.",
            fulldescription34: "Una buena manera de planificar las siguientes acciones de mejora en una actividad es utilizando la aplicación móvil/tableta Pentabilities. De manera rápida, podremos visualizar el desarrollo de las pentabilidades en su conjunto, ver y contextualizar la valoración de evidencias por fechas y actividades, entre otras, y extraer conclusiones. Esto nos permite adaptarnos más rápidamente y nos ayuda a personalizar el aprendizaje.",
        },
        matter: {
            shorttitle: "Relevancia",
            title1: "¿Por qué son importantes?",
            description11: "Está demostrado que unas buenas habilidades sociopersonales ayudan a mejorar nuestro bienestar y el de nuestro entorno a lo largo de la vida, además de facilitar el camino hacia el éxito académico y la adaptación al mercado laboral.",
            description12: "Las pentabilidades tienen un impacto fundamental en el bienestar a lo largo de la vida de una persona. Están relacionadas con la toma de decisiones adecuadas; el acceso, la estabilidad y la incorporación al mercado laboral; mantener hábitos saludables y establecer relaciones personales positivas. Tanto es así, que las desigualdades sociales se explican en gran medida por las diferencias en estas habilidades, y no tanto por las habilidades cognitivas.",
            description13: "Las pentabilidades son una condición necesaria para que se produzca el aprendizaje: la resiliencia, la perseverancia, la autonomía, la autoconfianza y la capacidad de comunicación son habilidades imprescindibles en el éxito académico. Pero la investigación demuestra que las habilidades sociales y emocionales no se desarrollan de forma espontánea, hay que guiarlas. Por otro lado, a pesar de que tienen una base genética, también son moduladas por el ambiente: familia, escuela, compañeros. Incluso durante la adolescencia son más maleables que las habilidades cognitivas.",
            description14: "Así pues, dado el impacto de las pentabilidades en las desigualdades sociales, es fundamental actuar sobre estas habilidades en el entorno escolar, tanto por un tema de justicia como de eficiencia.",
            description15: "Referencias científicas y recursos de la información dada:",
            description16: "Los cuatro pilares de la formación integral, según el Informe Jacques Delors de la Organización de las Naciones Unidas para la Educación, la Ciencia y la Cultura (UNESCO) son aprender a conocer: adquirir los instrumentos de la comprensión; aprender a hacer: para poder influir en el entorno; aprender a convivir: para participar y cooperar con los demás en toda actividad humana; aprender a ser: para poder tomar decisiones de forma autónoma y con espíritu crítico.",
            description17: "Las pentabilidades engloban el aprender a ser y el aprender a convivir, y están estrechamente relacionadas con el aprender a conocer y el aprender a hacer. También recogen las cinco grandes categorías de personalidad, centrales, entre otras, en el marco de trabajo de la Organización por la Cooperación y el Desarrollo Económico (OCDE). Por su relevancia, deberán incluirse como objetivo de aprendizaje a lo largo de toda la escolaridad y la vida.",
            reference11: "Heckman, JJ Y Kautz, T. 2012.",
            reference12: "Kautz et al., 2014.",
            reference13: "Deming, D. “The Growing Importance of Social Skills in the Labor Market”. NBER working paper 21473.",
            reference14: "The Myth of Achievement Tests. University of Chicago Press. 2014. Ed. Heckman, J.J. , Humphries, J.E., Kautz, T.",
            reference15: "Skills For Social Progress by James Heckman",
            reference16: "Hard Facts Soft Skills",
            title2: "¿En qué se basan?",
            description21: "A pesar de su importancia, muy descrita y reconocida, las habilidades sociopersonales no se trabajan y evalúan de forma sistemática en las instituciones educativas. El obstáculo principal para visualizar el progreso y abordar las carencias de estas habilidades es la ausencia de medidas fiables y viables para evaluarlas de forma extensiva. Registrar determinados comportamientos en diferentes contextos pone en evidencia el desarrollo de las diferentes habilidades, y el feedback que se desprende es necesario para trabajar estas capacidades apropiadamente. La recogida de evidencias para facilitar este feedback es la piedra angular de la enseñanza y aprendizaje, tanto para las disciplinas académicas como para las habilidades socioemocionales.",
            description22: "El sistema Pentabilities se basa en un ciclo -observar, registrar y dar buen feedback – donde la clave son los comportamientos y acciones concretas que observamos, registramos y a partir de los cuales damos el feedback. Estos comportamientos y acciones concretas son las evidencias que nos permiten valorar cada una de las cinco habilidades que incluyen las habilidades sociopersonales básicas. ",
            description23: "Proponemos, pues, un lenguaje común, en forma de evidencias (acciones concretas), a partir del cual trabajar las habilidades en entornos educativos como escuelas, centros de educación profesional, de educación superior y en el marco laboral, por medio del sistema Pentabilities: observar, registrar, dar buen feedback.",
            description24: "Para saber más sobre el marco de las habilidades y las evidencias que describimos, podéis ",
            description25: "clicar en este enlace.",
            title3: "¿Evidencias para qué?",
            description31: "Para el feedback, clave en el proceso de aprendizaje y crecimiento del alumno.",
            description32: "Para facilitar el análisis de qué estrategias educativas facilitan que afloren y se trabajen las pentabilidades y por qué. Es también una herramienta que permite entender cómo y qué se observa, y detectar y corregir posibles sesgos en la percepción.",
            description33: "Poder comunicar a terceros (padres, universidades y empresas) el recorrido de la persona en un lenguaje común.",
        },
        project: {
            title: "El proyecto",
            description11: "El sistema Pentabilities para el desarrollo de las habilidades sociopersonales en educación y en organizaciones es la consecuencia del estudio financiado por el Starting Grant del European Research Council (ERC) CompSCHoice 638893, concedido en 2014 a Caterina Calsamiglia. Esta investigación, basada en el método científico, busca la definición de indicadores sociales y emocionales objetivos, medibles y comunicables.",
            description12: "En este estudio también participan escuelas y centros educativos que están implementando estrategias pedagógicas en educación que incorporan el desarrollo de habilidades sociales y emocionales, las cuales se integran en el resto de las competencias cognitivas.",
            description13: "El sistema refleja las cinco grandes categorías de personalidad, es decir, la tendencia de las personas a responder de determinada manera en circunstancias concretas. Una parte importante de las evidencias que apoyan cada una de estas pentabilidades se observa y se desarrolla durante el trabajo colaborativo.",

        }

    },
    nav: {
        home: "Inicio",
        products: "Productos y servicios",
        framework: "Nuestro marco",
        blog: "Blog",
        contact: "Equipo",
        login: "Iniciar sesión",
        knowledge: 'Centro de aprendizaje'
    },
    misc: {
        solutions: "Soluciones",
        visit: "Visitar web",
        knowmore: "¿Quieres saber más?",
        help: "¡Dinos como te  podemos ayudar!",
        contact: "Contáctanos",
        changelanguage: "Cambiar idioma",
        made: "Hecho con",
        in: "en",
        name: "Tu nombre",
        email: "Dirección de correo electrónico",
        message: "Escribir un mensaje",
        sendmessage: "Enviar mensaje",
        learnmore: "Ver más",
        expandknowledge: "Amplia tus conocimientos",
        articles: "Artículos",
        books: "Libros",
        videos: "Vídeos",
        seebehaviours: "Ver los comportamientos",
        readmore: "Leer más",
        readless: "Leer menos",
        demo: "Ver demo",
        linkedin: "Seguir en LinkedIn",
        know: 'Saber más'
    },
    connectors: {
        and: 'Y'

    },
    pentabilities: {
        evidences: "Evidencias de Pentabilities",
        title: "Las Pentabilities",
        description11: "Las pentabilidades son las cinco grandes habilidades sociales y emocionales de Pentabilities: Responsabilidad, Cooperación, Autonomía e iniciativa, Gestión emocional y Habilidades de pensamiento.",
        description12: "Las definiciones de las pentabilidades propuestas recogen los conceptos básicos y prioritarios de cada habilidad, pero con una visión global y holística. El objetivo es crear un lenguaje común y concreto, alejado de los tecnicismos, la polisemia y la abstracción.",
        description13: "Las pentabilidades se dirigen hacia una finalidad tangible para garantizar una comunicación orientadora y un buen feedback constructivo, imprescindibles en todo aprendizaje.",
        description14: "La conexión entre las diferentes habilidades es tan relevante que a menudo son vasos comunicantes. Por eso, a veces, para mejorar una, será necesario y más efectivo potenciar otra.",
        categories: {
            category0: {
                title: "Responsabilidad",
                description: "Habilidad que hace referencia a la autodisciplina para alcanzar un objetivo, y a mantener una actitud de trabajo y esfuerzo hacia las tareas a realizar.",
                behaviours: {
                    total: "7",
                    behaviour0: "Trabaja de forma constante.",
                    behaviour1: "Se mantiene conectado/a a la actividad del grupo.",
                    behaviour2: "Hace comentarios relacionados con la tarea a realizar.",
                    behaviour3: "Realiza las tareas de forma eficiente.",
                    behaviour4: "Realiza las tareas con cuidado.",
                    behaviour5: "Persevera ante las dificultades.",
                    behaviour6: "Respeta las normas.",
                }
            },
            category1: {
                title: "Cooperación",
                description: "Habilidad que incluye los comportamientos que facilitan el trabajo en grupo: acciones relacionadas con la participación, la escucha activa, la toma de decisiones compartidas y la resolución de conflictos.",
                behaviours: {
                    total: "7",
                    behaviour0: "Escucha a los demás.",
                    behaviour1: "Incorpora lo que dicen los demás.",
                    behaviour2: "Fomenta la participación de los miembros del grupo.",
                    behaviour3: "Participa en la toma de decisiones consensuadas.",
                    behaviour4: "Facilita la resolución de conflictos.",
                    behaviour5: "Reconoce sus responsabilidades y las de los demás.",
                    behaviour6: "Ayuda a los demás de forma desinteresada.",
                }
            },
            category2: {
                title: "Autonomía e iniciativa",
                description: "La Autonomía implica trabajar de forma independiente, sin necesidad de supervisión y pidiendo ayuda sólo cuando se requiere. La Iniciativa indica confianza para sacar adelante las tareas y proyectos, por ejemplo proponiendo ideas y buscando soluciones a los problemas cuando es necesario.",
                behaviours: {
                    total: "6",
                    behaviour0: "Aporta ideas.",
                    behaviour1: "Hace preguntas cuando se encalla.",
                    behaviour2: "Toma decisiones para que el proyecto avance.",
                    behaviour3: "Sabe convencer al grupo de sus propuestas.",
                    behaviour4: "Trabaja con determinación.",
                    behaviour5: "Cree que puede iniciar cambios.",
                }
            },
            category3: {
                title: "Gestión emocional",
                description: "Habilidad que implica ser consciente de las emociones propias y las de los demás, y gestionarlas adecuadamente.",
                behaviours: {
                    total: "6",
                    behaviour0: "Transmite alegría.",
                    behaviour1: "Se muestra tranquilo/a en situaciones de presión.",
                    behaviour2: "Controla las emociones en situaciones de conflicto.",
                    behaviour3: "Asume la posibilidad de equivocarse.",
                    behaviour4: "Acepta que los cambios que propone no salgan adelante.",
                    behaviour5: "Adecúa el comportamiento a las circunstancias.",
                }
            },
            
            category4: {
                title: "Habilidades de pensamiento",
                description: "Habilidades que permiten captar información y procesarla para construir y organizar el conocimiento, de manera que se pueda utilizar para resolver problemas en diferentes situaciones. Implican indagación intelectual, generación de ideas y habilidades de metacognición. ",
                behaviours: {
                    total: "9",
                    behaviour0: "Relaciona contenidos nuevos con conocimientos previos.",
                    behaviour1: "Hace buenas reflexiones sobre los contenidos trabajados.",
                    behaviour2: "Hace buenas reflexiones sobre sus procesos personales internos.",
                    behaviour3: "Hace buenas preguntas (para entender o para permitir avanzar).",
                    behaviour4: "Tiene ideas creativas (explora caminos alternativos).",
                    behaviour5: "Plantea buenas estrategias de resolución de problemas.",
                    behaviour6: "Planifica y prioriza las tareas.",
                    behaviour7: "Tiene interés en explorar perspectivas diferentes.",
                    behaviour8: "Expresa eficazmente las ideas (corrección, precisión y estructura).",
                }
            }
        }
    },
    team: {
        title: "Equipo",
        memberstitle: "Miembros del equipo",
        caterina: "Obtuvo el PhD al Departamento de Economía de la Universidad de Yale. Centra su investigación en el diseño y evaluación de políticas educativas y en particular en aquellas centradas en el desarrollo de las habilidades no cognitivas. Es miembro del Human Capital and Economic Opportunity Group de la Universidad de Chicago, dirigido por el Premio Nobel James Heckman. Investigadora Principal de la ERC Starting Grant 638893, que financia el desarrollo de Pentabilities.",
        maria: "Doctora en el área de Ciencias de la Salud de la Universidad de Minnesota, tiene experiencia en la investigación de ciencias experimentales. Profesora de ciencias de ESO y Bachillerato durante más de 14 años, ha diseñado y dirigido el programa Jóvenes y Ciencia de Catalunya Caixa y coordinado el programa de excelencia Ciencias y Matemáticas de la Fundación CELLEX (CiMs+CELLEX). También ha implementado y coordinado el programa de Bachillerato Internacional (BI) en Aula Escuela Europea. Este programa, basado en la investigación educativa contemporánea, plantea la enseñanza con una visión holística, en el que tanto los aspectos cognitivos como los no-cognitivos tienen la misma importancia.",
        juan: "Máster en Innovación y Emprendimiento en ESADE y con un posgrado en Gestión Tecnológica. Fue director de tecnología en Innpactia, un emprendimiento dedicado a encontrar oportunidades de financiación para compañías del tercer sector de Latinoamérica. Además, trabajó en diferentes proyectos de tecnología en Anheuser-Busch InBev y Accenture. Por último, ha trabajado de forma activa como voluntario, entrenador, y presidente local en CISV, una organización dedicada al desarrollo de habilidades, actitudes y conocimientos que buscan inspirar y educar acciones por un mundo más pacífico y justo.",
        ece: "Con un contexto multicultural que incluye desde Canadá a Sudáfrica, Ece tiene dos títulos de máster en Economía, especializandose en economía del desarrollo y ciencias del comportamiento. Combinando la investigación rigurosa y el trabajo de campo aplicado, se centra en proporcionar soluciones de comportamiento informado a los diversos retos socioeconómicos, que van desde el acceso a la energía sostenible al empoderamiento de los jóvenes. Es consultora para el Banco Mundial como economista y dirige trabajo de campo, así como RCTs, en el África subsahariana.",
        mikel: "Desarrollador web y diseñador, con dos títulos superiores de desarrollo web y multiplataforma. Desarrolla e implementa nuevas funcionalidades y se asegura de que todo funcione sin problemas en Pentabilities.",
        jacek: "Se aferra a las experiencias. Aficionado a la tecnología con experiencias que van desde infraestructuras críticas en entornos civiles y gubernamentales, pasando muchos años en Fintechs y programación de escala, y haciendo investigación sobre desarrollo de herramientas tecnológicas para el mundo del emprendimiento. Desde hace más de 15 años Jacek intenta combinar las necesidades del mundo de los negocios con la tecnología más avanzada. Posee varias patentes y es autor de numerosas publicaciones técnicas y científicas.",
    },
    terms: {
        line1: "AVISO LEGAL",
        line2: "Esta aplicación es la propiedad de Pentabilities S.L., titular de CIF B67363291 con domicilio social en Panamá, 1, 08173 Sant Cugat del Vallès, Cataluña, España; e inscrita en el Registro Mercantil de Barcelona: vol. 46746, hoja 56, Entrada 1.",
        line3: "Para cualquier consulta o propuesta, contáctenos en admi",
        line4: "TÉRMINOS Y CONDICIONES",
        line5: "Esta aplicación se rige por la normativa exclusivamente aplicable en España, tanto a los usuarios nacionales y extranjeros de esta aplicación estar sujetos a los mismos.",
        line6: "el acceso del usuario a nuestra aplicación es gratis y no depende de la lectura y la plena y aceptar expresamente y sin reservas estos TÉRMINOS Y CONDICIONES & nbsp; que están en vigor en el momento del acceso, que le rogamos que lean atentamente. Al utilizar nuestro portal, sus contenidos o servicios, el usuario & nbsp; acepta y se somete a las condiciones generales para el uso de la misma forma expresa. Si el usuario no estar de acuerdo con estos términos y condiciones, él o ella debe abstenerse de utilizar este portal y operar a través de él.",
        line7: "PROPIEDAD INTELECTUAL",
        line8: "Todos los contenidos, textos, imágenes y códigos fuente son propiedad de Pentabilities S.L. o terceros por la cual los derechos de explotación fueron adquiridas y están protegidos por los derechos de Propiedad Intelectual e Industrial.",
        line9: "El usuario únicamente tiene derecho a realizar un uso privado de los mismos, sin ánimo de lucro, y necesita autorización expresa de modificar, reproducir, distribuir o explotar tales o ejercer cualquier derecho perteneciente a su titular.",
        line10: "CONDICIONES DE ACCESO",
        line11: "El acceso a nuestra aplicación es gratis y no requiere previa suscripción o registro.",
        line12: "Sin embargo, los servicios específicos pueden estar restringidas a usuarios específicos y requieren un proceso de registro y / o la identificación del usuario utilizando una contraseña. En cualquier caso, estos servicios serán debidamente identificados en la aplicación.",
        line13: "Cualquier enviar sus datos personales implica la aceptación expresa por parte del USUARIO de nuestra política de privacidad.",
        line14: "El usuario debe acceder a nuestra aplicación con el principio de la buena fe, las normas de orden público y las presentes condiciones de acceso y uso. El acceso a nuestra aplicación es exclusivamente bajo la responsabilidad del usuario y será responsable de los daños que puedan causar a nosotros oa terceros.",
        line15: "Teniendo en cuenta la imposibilidad de controlar la información, contenidos y servicios contenidos en otros sitios web de terceros que son accesibles a través de los enlaces en nuestra página web, le informamos que nosotros no seremos responsables de ningún daño de ningún tipo que pudieran derivarse del uso de estas páginas web de terceros por parte del usuario.",
        line16: "CONDICIONES GENERALES DE USO DE LA SOLICITUD",
        line17: "Los usuarios que acceden y utilizan la aplicación se comprometen a cumplir con las siguientes condiciones de uso:",
        line18: "Esta aplicación no debe utilizarse para fines personales o fines distintos de aquellos para los que fue creado.",
        line19: "Contenido que puede ser ilegal, contrario a la moral o al orden público o que es un lenguaje inapropiado y / o usos de carácter ofensivo no debe ser publicada.",
        line20: "Los usuarios no deben emitir información falsa, engañosa, comentarios o información engañosos o fraudulentos.",
        line21: "Los usuarios deben preservar la confidencialidad de las credenciales de autenticación y la información incluida en la solicitud.",
        line22: "Los usuarios no deben suplantar la identidad de otros usuarios.",
        line23: "Los usuarios no deben utilizar técnicas para alterar, desmontar o eludir las limitaciones técnicas de los mecanismos de productos o de seguridad, o permitir que un tercero lo haga en su nombre.",
        line24: "Los usuarios no deben usar u obtener los servicios, productos y contenidos ofrecidos en la presente solicitud, por procedimientos distintos a los estipulados en las presentes condiciones de uso.",
        line25: "Los usuarios no deben llevar a cabo cualquier tipo de acción de nuestra página web que causa una excesiva sobrecarga de funcionamiento a nuestros sistemas informáticos, así como la introducción de virus, o instalación de robots, o software que altere el normal funcionamiento de nuestra aplicación, o pueden en última instancia, causar daños a nuestros sistemas informáticos.",
        line26: "Pentabilities S.L. se reserva el derecho unilateral de cancelar sin previo aviso cualquier usuario de que la organización entiende que ha violado las condiciones que rigen el uso de nuestra aplicación, sin que el usuario tenga derecho a ningún tipo de reclamación por este tipo de acciones. Así mismo se reserva el derecho de ejercer las acciones legales pertinentes contra aquellos que violan las presentes condiciones generales de uso, aceptando el USUARIO que la no iniciación de estas acciones no constituye una renuncia formal, permaneciendo en vigor hasta que los plazos legales de prescripción de las infracciones .",
        line27: "Del mismo modo, Pentabilities S.L. Se reserva el derecho a modificar la presentación y configuración de nuestra aplicación, ampliar o reducir servicios, e incluso suprimirla, o la carga de la aplicación y / o servicios y el contenido proporcionado, en cualquier momento y todo ello de forma unilateral y sin previo aviso.",
        line28: "Limitación de responsabilidad",
        line29: "No asumimos ninguna responsabilidad derivada, o que pudiera derivarse, de la información, contenidos, evaluaciones, opiniones y comentarios que son publicados por los usuarios. Del mismo modo, no vamos a ser responsable de la pérdida, daño directo o indirecto de cualquier naturaleza derivados del acceso, uso o mal uso de la aplicación atribuibles a la entidad contratante y / o a los usuarios autorizados por él.",
        line30: "Condiciones de uso para las Entidades Contratantes",
        line31: "La aplicación permite la creación de diferentes perfiles de usuario que determinan el grado de acceso a la información que se introduce posteriormente en la aplicación.",
        line32: "El registro de usuarios y estudiantes por el administrador u otros usuarios de la entidad.",
        line33: "Las entidades que contrato el servicio tendrá acceso a la aplicación a través de códigos de acceso y será el encargado de dar acceso a los diferentes usuarios, así como limitar el grado de este acceso a la información contenida en la solicitud. En el caso de la identificación de los usuarios con sus datos personales, éstos serán utilizados exclusivamente para fines de identificación.",
        line34: "El usuario administrador de la entidad contratante será el único usuario con la capacidad para determinar los perfiles de acceso establecidos, de modo que, en cualquier caso, será la entidad contratante y, en su caso, el Administrador, el responsable de determinar correctamente los perfiles y limitaciones El acceso de usuario. En cualquier caso, Pentabilities S.L. no se hace responsable de este hecho.",
        line35: "La entidad contratante será el único responsable de proporcionar la información necesaria a las personas cuyos datos personales se utilizan, en cuanto a los propósitos y la forma en que sus datos personales serán tratados en esta solicitud y, en su caso, será responsable de obtener su consentimiento cuando esto es necesario.",
        line36: "La evaluación de los estudiantes.",
        line37: "La aplicación es una herramienta cuya finalidad es la evaluación de los aspectos socio-emocionales de los estudiantes, por lo que los usuarios autorizados puedan evaluar los distintos parámetros establecidos, así como añadir otras evaluaciones que consideren pertinentes. Las evaluaciones individuales y otra información recogida servirá de base para las estadísticas y generar una visión general de la evolución de los diferentes aspectos del estudiante, por lo que es necesario que las evaluaciones realizadas son verdaderas y las observaciones registradas en el sistema son relevantes para cumplir este propósito. En cualquier caso, los usuarios deben usar un lenguaje apropiado. Las evaluaciones pueden ser visibles a los usuarios autorizados, teniendo en cuenta las limitaciones de acceso establecidas por la entidad contratante.",
        line38: "CONDICIONES PARA EL TRATAMIENTO DE DATOS PERSONALES",
        line39: "Cuando Pentabilities S.L. procesa los datos personales de los empleados, colaboradores y estudiantes de la entidad contratante, dicha entidad actuará como la persona a cargo del procesamiento de los datos personales de estas partes interesadas, mientras que Pentabilities SL, actuará como el Administrador de tratamiento, ya que la disposición de la los servicios actuales implica el acceso a los datos personales.",
        line40: "OBLIGACIONES DE Pentabilities S.L. PROCESADOR como datos.",
        line41: "El procesador de datos y todo su personal están comprometidos con el cumplimiento de todas las obligaciones siguientes con el fin de procesar el usuario o los datos personales de otras personas en nombre del tratamiento de los datos:",
        line42: "Utilizar los datos personales procesados ​​o recogido para su inclusión con el único fin de este compromiso. En ningún caso se pueden utilizar los datos para sus propios fines.",
        line43: "Procesar la conservación de los datos con las instrucciones del controlador de datos. informar inmediatamente al responsable del tratamiento en el caso de que los datos que estime procesador cualquiera de las instrucciones infrinja ninguna ley de protección de datos.",
        line44: "Cuando así lo exija la ley de protección de datos, para mantener un registro de las categorías de procesamiento de las actividades llevadas a cabo en la cuenta del controlador que contiene los requisitos de la ley de protección de datos.",
        line45: "No revelan los datos a terceras personas a menos que este último está expresamente autorizado por el controlador de datos en un escenario legalmente admisible.",
        line46: "No subcontratar cualquiera de los servicios que entren dentro del objeto de este contrato que puede implicar el tratamiento de datos personales, con excepción de los servicios auxiliares que puedan ser necesarios para la correcta ejecución de los servicios.",
        line47: "Si fuera necesario subcontratar cualquier actividad de procesamiento, el controlador debe ser informado por escrito previamente con preaviso de 1 mes, indicando el procesamiento puede ser subcontratado y clara e inequívoca identificación del subcontratista y sus datos de contacto. La subcontratación puede llevarse a cabo si el controlador no se opone dentro del plazo indicado.",
        line48: "Al aceptar los presentes Términos y Condiciones, el tratamiento de los datos permite y autoriza la subcontratación de la aplicación de servicios de alojamiento a Google Ireland Limited o Google LCC.",
        line49: "En el caso de que la información almacenada por Google se encuentra fuera del Área Económica Europea, las partes han firmado las cláusulas contractuales tipo aprobadas por la Comisión Europea. Además, Google cumple con la UE - Privacidad marcos Shield.",
        line50: "Cumplir con su deber de confidencialidad respecto a los datos personales que ha tenido acceso al amparo de esta, incluyendo la posterior a la terminación de su propósito.",
        line51: "Asegúrese de que las personas autorizadas para tratar los datos personales se comprometen, de forma expresa y por escrito, a respetar su confidencialidad ya cumplir con las medidas de seguridad pertinentes, que deben ser debidamente informado de.",
        line52: "Cuando los afectados ejercer sus derechos de acceso, rectificación, cancelación, oposición y otros derechos contenidos en la ley de protección de datos vis-à-vis el procesador de datos, éste deberá informar de inmediato al controlador y bajo ninguna circunstancia, a más tardar el siguiente día hábil recepción de la solicitud, junto con toda la información relevante para el cumplimiento de la solicitud.",
        line53: "En el caso de que el procesador de datos de conocimiento de cualquier violación de la seguridad de datos que constituye un riesgo para los derechos y libertades de las personas físicas, lo notificará al responsable del tratamiento sin demora indebida y dentro de un plazo de 72 horas, junto con toda la información relevante para documentar e informar sobre el incidente.",
        line54: "Proporcionar al controlador toda la información necesaria para demostrar el cumplimiento de sus obligaciones y para permitir la realización de auditorías o inspecciones llevadas a cabo por el controlador o el otro auditor autorizado por ella.",
        line55: "Aplicar las medidas de seguridad necesarias de acuerdo con la naturaleza, el alcance, el contexto y los propósitos del procesamiento con el fin de garantizar la confidencialidad, integridad, disponibilidad y capacidad de recuperación en curso de los sistemas y servicios de procesamiento y verificar, evaluar y valorar de forma regular la eficacia de las medidas técnicas y organizativas introducidas para garantizar la seguridad del tratamiento.",
        line56: "A la terminación de este contrato, el procesador de datos, de acuerdo con las instrucciones del responsable del tratamiento, debe eliminar o devolver todos los datos personales en su poder almacenada en papel o digitales copias o, en su caso, la transferencia a otros procesos designados por el Controlador de datos. Cualquiera de las dos opciones significa que el procesador no tendrá en su poder los datos personales en poder del titular del tratamiento a menos que el procesador debe almacenarlo, debidamente bloqueados, mientras que pueden surjan pasivos de la prestación del servicio.",
        line57: "OBLIGACIONES DEL datos de controlador",
        line58: "El controlador de datos deberá:",
        line59: "Entregar al procesador de los datos necesarios para prestar el servicio como se describe en el presente documento.",
        line60: "Llevar a cabo las consultas previas pertinentes.",
        line61: "Supervisar el procesamiento, incluyendo la realización de inspecciones y auditorías.",
        line62: "DECLARACIÓN DE PROCESADOR RESPONSABLE DATOS PROCESAMIENTO por los datos",
        line63: "Afirma el procesador de datos que cumple los requisitos de procesamiento con los del presente Reglamento y garantiza la protección de los derechos de la parte interesada, en particular:",
        line64: "Que en el curso de su actividad que cumple con las obligaciones y los principios contenidos en el Reglamento General de Protección de Datos (UE 2016/679).",
        line65: "Que mantiene un registro de los datos de procesamiento de actividades realizadas bajo su responsabilidad.",
        line66: "Que ha adoptado las medidas de seguridad necesarias de acuerdo con la naturaleza, el alcance, el contexto y los propósitos del procesamiento con el fin de garantizar la confidencialidad y seguridad de los datos tratados en el papeleo y los medios digitales.",
        line67: "Se compromete a cumplir con el deber de confidencialidad, incluyendo la posterior a la terminación de la relación.",
        line68: "Que se lleva a cabo de forma proactiva la verificación periódica del cumplimiento de las obligaciones y las medidas de seguridad técnicas y organizativas.",
        line69: "POLÍTICA DE PRIVACIDAD",
        line70: "La confidencialidad y la seguridad son valores fundamentales de Pentabilities S.L. y por lo tanto nos comprometemos a garantizar la privacidad del usuario en todo momento y no recoger toda la información innecesaria. A continuación ofrecemos toda la información necesaria con respecto a nuestra política de privacidad en relación con los datos personales recogidos, explicando:",
        line71: "¿Quién es el responsable de sus datos.",
        line72: "¿Qué son los datos que recogemos.",
        line73: "El propósito para el que se reúnen los datos solicitados.",
        line74: "¿Cuál es la legitimación para su procesamiento.",
        line75: "La longitud de tiempo que almacenar los datos.",
        line76: "Quien sus datos están asignados.",
        line77: "Cuáles son sus derechos y cómo pueden ejercerlos.",
        line78: "CONTROLADOR DE DATOS:",
        line79: "El controlador de sus datos es Pentabilities S.L., titular del CIF & nbsp; & nbsp B67363291; cuya sede social se encuentre en Panamá, 1, 08173 Sant Cugat del Vallès, Cataluña, España.",
        line80: "DATOS RECOLECTADOS:",
        line81: "Dependiendo de la finalidad para la que se procesa su información, que necesitamos para procesar la siguiente información:",
        line82: "Datos de identidad, dirección, e información de contacto para el procesamiento de sus solicitudes.",
        line83: "PROPÓSITOS, legitimación y almacenamiento de los TRATAMIENTO DE DATOS.",
        line84: "Dependiendo de la forma de interactuar con nuestra aplicación, vamos a tratar sus datos para los siguientes propósitos:",
        line85: "entidad de registro",
        line86: "Propósito: El proceso de registro para convertirse en una entidad registrada para gestionar los servicios solicitados. Con el tiempo, enviarle información comercial sobre nuestros productos y servicios, incluidos los medios electrónicos también.",
        line87: "Legitimación: Ejecutar y cumplir las obligaciones de la relación contractual. Nuestro interés legítimo que le envíe información comercial en su calidad de cliente / usuario registrado.",
        line88: "Conservación: Durante el tiempo en que la entidad se ha registrado en el servicio.",
        line89: "Uso de Cookies:",
        line90: "Esta aplicación puede utilizar cookies, pero en ningún caso estos tratar los datos personales, los hábitos de navegación del usuario captura o se utilizan con fines publicitarios, y por lo tanto están exentos del cumplimiento de las obligaciones establecidas en el artículo 22 de la Ley de Servicios de la Sociedad de la Información. Sin embargo, le informamos que podemos utilizar cookies que nos permiten la comunicación entre el equipo del usuario y la red, proporcionamos un servicio expresamente solicitado por el usuario, autenticación o identificación del usuario (única sesión), la seguridad de usuario Give, sesiones de reproductor de medios, sesiones para equilibrar la carga, la personalización de la interfaz de usuario y los complementos (plug-in) y el intercambio de contenidos sociales. En cualquier caso, el usuario puede, si lo desea, desactivar y / o eliminar estas cookies siguiendo las instrucciones de su navegador de Internet.",
        line91: "Obligación de proporcionar sus datos personales y las consecuencias de no hacerlo.",
        line92: "El suministro de los datos personales requiere una edad mínima de 14 años, o, si la capacidad legal aplicable, suficiente para entrar en un contrato.",
        line93: "Proporcionar información personal no es obligatoria en cualquier caso, por lo tanto, los datos de identificación pueden ser sustituidos por códigos u otros conceptos.",
        line94: "Destinatarios de los datos",
        line95: "Sus datos son confidenciales y no serán transferidos a terceros, de acuerdo con las Condiciones de Procesamiento de Datos (véase la sección D). Sin embargo, podemos transferir sus datos personales en el caso de las obligaciones legales o cuando el servicio implica la necesidad de una relación contractual con los proveedores de procesadores de datos que no va a tratar datos personales para sus propios fines.",
        line96: "DERECHOS A LA PROTECCIÓN DE DATOS PERSONALES.",
        line97: "Usted puede ejercer los siguientes derechos:",
        line98: "Solicitud de acceso a sus datos personales o su rectificación cuando inexacta.",
        line99: "Solicitar su eliminación cuando, entre otras razones, que ya no es necesario para el propósito para el que fueron gobernados son.",
        line100: "Solicitar la limitación de tratamiento en determinadas circunstancias.",
        line101: "Solicitud oposición al tratamiento de sus datos debido a su situación particular.",
        line102: "solicitud de portabilidad de los datos cuando se proporciona por la ley.",
        line103: "Otros derechos reconocidos por la ley aplicable.",
        line104: "¿Dónde y cómo ejercer sus derechos: Por escrito al controlador de datos Pentabilities S.L. con la referencia “Datos Personales” o similar y que indica el derecho a ser ejercida y con respecto a que el procesamiento en la dirección postal o electrónico (admi",
        line105: "En el caso de cualquier conflicto con la empresa en lo que respecta al tratamiento de sus datos puede presentar una reclamación ante la Autoridad de Protección de Datos (www.agpd.es).",
        line106: "SEGURIDAD DE SUS DATOS PERSONALES",
        line107: "Con el fin de salvaguardar la seguridad de sus datos personales, le informamos de que hemos adoptado todas las medidas necesarias de índole técnica y organizativas necesarias que garanticen la seguridad de sus datos personales contra la alteración, pérdida y tratamiento no autorizado y el acceso.",
        line108: "ACTUALIZACIÓN DE DATOS",
        line109: "Es importante que nos informe siempre que haya habido alguna modificación para que podamos actualizar sus datos personales; de lo contrario no podemos ser responsables de ella.",
        line110: "Nosotros no seremos responsables de la política de privacidad respecto a los datos personales que pueda suministrar a terceros a través de los enlaces en nuestra página web.",
        line111: "Podemos modificar estas condiciones de privacidad para adaptarlas a las modificaciones realizadas en nuestra página web, junto con las modificaciones legales o jurisprudenciales respecto a los datos personales que pueden aparecer, y por lo tanto se le pide que lea esta política cada vez que proporcione sus datos a través de esta aplicación.",
        line112: "RESPONSABILIDADES",
        line113: "Al colocar esta aplicación a disposición del usuario, deseamos ofrecer un servicio de calidad aplicando la máxima diligencia en la prestación de los mismos y a los medios tecnológicos utilizados. Sin embargo, nosotros no seremos responsables de la presencia de virus u otros elementos que puedan dañar el sistema informático del usuario.",
        line114: "Pentabilities S.L. no garantiza que la disponibilidad del servicio es continuo e ininterrumpido, debido a circunstancias causadas por problemas en la red Internet, averías en los dispositivos u otras circunstancias imprevisibles de computación, por lo que el usuario se compromete a apoyar estas circunstancias dentro de límites razonables, por lo que expresamente Renuncia afirman que el nombre del cliente ninguna responsabilidad por posibles fallos, errores y uso del servicio.",
        line115: "El usuario asume toda la responsabilidad derivada del uso de nuestra aplicación, siendo el único responsable de todo efecto directo o indirecto que pueda derivarse de la aplicación, incluyendo, pero no limitado a, cualquier resultado económico, técnico y / o jurídico adverso, así como la decepción de las expectativas generadas por nuestro portal, el usuario está obligado a mantener indemne _____________ por reclamaciones derivadas, directa o indirectamente de tales hechos.",
        line116: "_____________ no garantiza la exactitud, veracidad y vigencia de los contenidos de esta solicitud, ya sea propia, de terceros, o vinculado a otros sitios web, siendo eximir totalmente de cualquier responsabilidad derivada de la utilización de los mismos.",
        line117: "_____________ queda exenta de cualquier responsabilidad derivada de cualquier reclamación, incluido el pago de honorarios de abogados y reclamaciones originadas por terceros por el incumplimiento por parte del USUARIO de nuestras condiciones de uso, acceso y política de privacidad, o cualquier otra reclamación por el incumplimiento con la legislación vigente.",
        line118: "El USUARIO reconoce la comprensión de toda la información relativa a las condiciones de uso de nuestro portal, y reconoce que son suficientes para la exclusión del error en ellos y, por lo tanto, las acepta íntegramente y de forma expresa.",
        line119: "El USUARIO es plenamente consciente de que la mera navegación a través de esta aplicación, así como el uso de sus servicios, implica la aceptación de estas condiciones de uso.",
        line120: "Todo lo relacionado con nuestra aplicación, se rige exclusivamente por las leyes españolas. En el caso de cualquier tipo de discrepancia o diferencia entre las partes en relación con la interpretación y contenido de esta solicitud, todas las partes se someten, con renuncia expresa a cualquier otro fuero, a los Juzgados y Tribunales de Barcelona",
        line121: "Validez de las condiciones generales de la aplicación",
        line122: "Estos términos y condiciones han sido modificadas con fecha 10/01/2020, pero en cualquier momento podemos proceder a modificarlos. Por favor, compruebe la fecha de emisión cada vez que se conecte a nuestra solicitud y tendrá la seguridad de que no ha habido ningún cambio que le afecta.",
    },
    infographics: {
        key: {
            emotional: "Emocional",
            cognitive: "Cognitivo",
            social: "Social",
            key1: "Habilidades claves que determinan",
            key2: "la capacidad para superar",
            key3: "retos",
        },
        doodle: {
            concept11: "Socioemocional",
            concept12: "habilidades",
            concept2: "Carácter",
            concept31: "Trabajando",
            concept32: "Memoria",
            concept4: "Autoeficacia",
            concept51: "K12 SEL",
            concept52: "Estándares",
            concept6: "CASEL",
            concept7: "OCDE",
            concept81: "Hábitos",
            concept82: "de la mente",
            concept91: "aprendizaje del IB",
            concept92: "Perfil",
            concept101: "Siglo 21",
            concept102: "Habilidades",
            concept111: "Big Five",
            concept112: "Rasgos de personalidad",
            concept121: "Todos las queremos, pero también",
            concept122: "nos sentimos abrumados por ellas",

        },
        cycle: {
            step11: "Definir un",
            step12: "objetivo",
            step21: "proyectos de diseño",
            step22: "y actividades",
            step31: "observar y",
            step32: "registrar evidencias",
            step4: "Análisis",
            step51: "Feedback para tomar",
            step52: "conciencia",
        },
        header1: {
            link: "assets/img/header/HeaderPics-01.png",
        },
        header2: {
            link: "assets/img/header/HeaderPics-05.png",
            description: "Mantuvo la calma aunque se acercaba la entrega",
        },
        header3: {
            link: "assets/img/header/HeaderPics-06.png",
            description1: "Has mejorado mucho en",
            description2: "aportar ideas",
        },
        header4: {
            link: "assets/img/header/HeaderPics-07.png",
            description1: "¡Debemos repensar cómo estimular",
            description2: "Autonomía e iniciativa!",
        }
    },
    landing: {
        title: 'Conoce mejor Pentabilities',
        video1title: '¿Por qué las habilidades socioemocionales?',
        video1key1: 'Las habilidades socioemocionales son clave para el éxito personal, académico y profesional a lo largo de la vida.',
        video1key2: 'Las métricas de logro académico convencionales, como las notas de examen, no evalúan las habilidades socioemocionales.',
        video1key3: 'Las habilidades socioemocionales son intangibles y difíciles de medir, pero Pentabilities ofrece herramientas para hacerlo.',
        video1description: '',
        video2title: 'Pentabilizando la evaluación de las habilidades socioemocionales',
        video2key1: 'Pentabilities se basa en cinco rasgos de la personalidad y sus comportamientos asociados, que se utilizan como expresiones y evidencias de estas habilidades.',
        video2key2: 'Pentabilities propone la evaluación formativa para desarrollar las habilidades socioemocionales: registrar los comportamientos observados durante el proceso y dar el correspondiente feedback. ',
        video2key3: 'Las evaluaciones de las habilidades y comportamientos forman la base de los análisis a los que se puede acceder a través de la plataforma web y utilizarse en las sesiones de feedback.',
        video2key4: ' ',
        video2description: '',
        video3title: 'Cómo funciona el sistema Pentabilities',
        video3key1: 'Cómo prepararse para utilizar la aplicación Pentabilities.',
        video3key2: "Los datos recogidos tienen un doble uso: como feedback a las estrategias que utilizan los profesores en clase y para ofrecer un feedback a los alumnos basado en evidencias.",
        video3key3: 'Como guía de enseñanza y evaluación de las habilidades socioemocionales.',
        video3description: '',
        alert1: "El curso completo de Pentabilities se compone de 12 módulos. Aquí encontrará los primeros 3 videos introductorios. Si quieres acceder al resto de módulos:",
        alert2: "¿Ya tienes una cuenta de Pentabilities? Sólo",
        alert3: "haz click aquí",
        alert4: "¿Eres nuevo en Pentabilities?",
        alert5: "Haz click aquí",
        alert6: "para obtener más información sobre la membresía."
    },
    landing2: {
        button1: "Quiero saber más",
        button2: "Únete al proyecto",
        button3: 'Apúntate',
        button4: 'Preinscripción',
        button5: 'Descarga',
        alert: 'Ven a la sesión informativa del 28 de junio a las 16.00h en la Universidad Pompeu Fabra de Barcelona',
        alert2: 'Descárgate aquí la hoja de solicitud para realizar la inscripción. Una vez completado, envíalo a info@pentabilities.com',
        title1: 'Enciende',
        title2: 'habilidades de vida',
        subtitle1: 'Programa gratuito para integrar el trabajo de las habilidades sociales y personales en el aula durante el curso 2022-2023',
        subtitle2: "Proyecto implementado por la Fundación José Manuel Lara, financiado con fondos Next Generation y desarrollado bajo la dirección científica y académica del Instituto de Economía Política y Gobernanza (IPEG) de la Universitat Pompeu Fabra (UPF) de Barcelona",
        section1_1: '¡Todavía estás a tiempo! Si eres un profesor de secundaria o diriges un instituto y quieres integrar la educación socioemocional en el aula para la',
        section1_2: 'mejora del éxito escolar,',
        section1_3: "haz tu preinscripción aquí y te contactaremos en breve.",
        section2_1: '5 razones para sumarte',
        section2_2: 'Éxito escolar',
        section2_3: "Trabajar las habilidades sociales y personales (‘soft skills’) implica una mejora de las ratios de absentismo, resultados académicos y participación al aula, según se desprende de una amplia investigación internacional en esta área.",
        section2_4: "Efectividad",
        section2_5: "Son en gran medida estas habilidades las que hacen posible un aprendizaje significativo y no a la inversa. De acuerdo con varios estudios del Education Endowment Foundation (EFF), hacer reflexionar sobre el propio aprendizaje puede suponer un progreso de siete meses.",
        section2_6: "Oportunidades",
        section2_7: "Los programas de educación social y emocional son especialmente beneficiosos para los alumnos de un menor nivel socioeconómico y de bajo rendimiento.",
        section2_8: "Día a día",
        section2_9: "La mejora de las competencias transversales es más probable cuando la educación socioemocional se incorpora a la práctica educativa rutinaria y está apoyada por el desarrollo profesional y la formación del personal.",
        section2_10: "Inclusión social",
        section2_11: "El dominio efectivo de las habilidades sociales y emocionales apoya el logro de resultados positivos en la vida, incluyendo una buena salud, el bienestar social y educativo y la ocupación profesional mientras evita dificultades como adicciones, enfermedades mentales y conductas criminales.",
        section3_1: "Students who, because of their careers in the regular classroom, their future seemed very limited, now we see that the impact is real and we see that what we are doing leads them to lead work teams and take responsibility.",
        section3_2: "Pere Mas, professor del Fab Lab del Liceu Politècnic",
        section4_1: "Ahora hemos comprendido que las habilidades sociales y personales son importantes, se pueden medir y nospueden ayudar a dotar de herramientas a nuestros jóvenes para ser aquello que quieren ser y convertirse en su mejor versión",
        section4_2: "James Heckman, Premio Nobel de Economía",
        section5_1: "¿Qué dicen los profesores que han usado Pentabilities en sus aulas?",
        section5_2: "No solo tiene un impacto dentro del aula, sino también en su vida personal",
        section5_3: "(Pere Mas, Liceu Politècnic)",
        section5_4: "Trabajarlas conduce a pequeños progresos, a reflexionar, a reconocer dónde hay dificultades. Y, de rebote, todo esto afecta a su entorno.",
        section5_5: "(Laia Creixells, educadora social en la Escola Prim)",
        title3: "¿Cómo funciona Pentabilities en el aula?",
        menu1: "Quiénes somos",
        menu2: "El proyecto",
        menu3: "Características",
        menu4: "Contacto",
        menu5: 'Inicio',
        join1: {
            title: 'Diseño de proyectos y actividades',
            short: 'Promueve una dinámica en el aula que haga aflorar las habilidades que deseas trabajar.',
            description1: 'Para empezar, promueve una dinámica en el aula que haga aflorar las habilidades que quieres observar y trabajar. Es decir, es la acción en el aula aquello que facilita la observación de las habilidades que nos interesa observar y trabajar, y no a la inversa.',
            description2: 'Esto pasa por definir cómo se desarrollará la clase –hablo yo y los alumnos escuchan, lanzo la pregunta y ellos discuten, trabajan solos o por parejas, etcétera–. Y pasa también por definir los comportamientos y las actitudes que quiero observar.'
        },
        join2: {
            title: 'Observa y registra los comportamientos',
            short: 'Profesores y alumnos valoran las conductas observadas cuando los alumnos interactúan.',
            description1: 'En segundo lugar, los profesores y también los mismos alumnos valoran las conductas observadas durante la interacción. Los momentos de auto y coevaluación los determina el profesor.',
            description2: "Mientras los alumnos piensan, planifican, buscan o debaten, fíjate en las habilidades que te has propuesto observar e interactúa con ellos, si lo crees necesario, preguntándoles o guiándoles. Es así como emergen las conductas que, de otra manera, no se expresan. Los comportamientos se registran a nivel individual. No tiene sentido observar todos los estudiantes en una sola sesión ni evaluar los 35 comportamientos de la lista. Planifica qué alumnos observarás en qué sesiones y solo registra los comportamientos que has podido observar.",
            description3: "Pero Pentabilities no es tanto una herramienta para evaluar como para desarrollar. Este trabajo es mucho más efectivo si los alumnos también se auto y coevalúan, porque toman conciencia de ellos mismos e interiorizan el significado y relevancia de las habilidades que trabajan."

        },
        join3: {
            title: 'Análisis',
            short: 'Visualiza los datos registrados en informes multidimensionales: por personas, por grupos, por habilidades, etcétera.',
            description: "Las observaciones registradas de profesores, alumnos y compañeros resultan en unos informes multidimensionales que agregan los datos por personas, por grupos, por habilidades y más. Estos muestran las evidencias recogidas de forma que cada estudiante y docente puede extraer fácilmente un significado y unas conclusiones que, a su vez, sirven para iniciar una reflexión y una conversación."
        },
        join4: {
            title: "Reflexión y conversación",
            short: "El feedback facilita poner en común puntos fuertes y puntos de mejora para tomar conciencia.",
            description: "La reflexión se inicia a partir del feedback del profesor al alumno, pero sobre todo a partir del sentido que extrae el propio alumno del análisis. El ideal es que el propio estudiante mire el informe y se plantee qué le sorprende, qué puntos le facilitan establecer dinámicas positivas y qué puntos interfieren en su desarrollo dentro de un contexto determinado. El profesor escucha o lee las reflexiones personales y, a continuación, fomenta una conversación entre los miembros del equipo."
        },
        join5: {
            title: "Establece objetivos",
            short: "Partiendo del listado de comportamientos, crea objetivos específicos y compartidos.",
            description: "Los objetivos son fruto de la reflexión y la conversación con el equipo. Así, una vez los alumnos han tomado conciencia de los puntos fuertes y débiles, profesores y alumnos definen las metas. En este punto, el maestro también puede valorar qué dinámica de clase es más favorable para conseguir determinados objetivos individuales o colectivos y, en definitiva, para mejorar las habilidades socioemocionales de los estudiantes y así lograr un aprendizaje más significativo."
        }
    },
    landinges: {
        alert: "Atiende a la sesión informativa online del 29 de junio",
        section1_1: '¡Todavía estás a tiempo! Si eres un profesor de secundaria o diriges un instituto y quieres integrar la educación socioemocional en el aula para la',
        section1_2: 'mejora del éxito escolar,',
        section1_3: "haz tu preinscripción aquí y te contactaremos en breve.",
        subtitle2: "Proyecto bajo la dirección científica y académica del Instituto de Economía Política y Gobernanza (IPEG) de la Universitat Pompeu Fabra (UPF) de Barcelona y financiado con fondo Next Generation",

    },
    whats: {
        title: "El proyecto ‘Pentabilities, enciende habilidades de vida’",

        text1: "Enciende habilidades de vida",
        text2: "es un programa de mejora de las habilidades sociales y emocionales (‘soft skills’) a las aulas de secundaria con una herramienta validada y una metodología científica:",
        text3: "Pentabilitites.",
        text4: "Pentabilities se encuentra actualmente en fase de pilotaje en 35 centros de Andalucía y Ceuta y Melilla, en las provincias de Jaén, Almería, Málaga y Huelva. Todo ello permitirá al equipo de investigación sacar conclusiones más precisas y fiables sobre el impacto del desarrollo de la inteligencia socioemocional en el alumnado, así como analizar la elaboración de políticas públicas en base a la evidencia recogida.",
        text5: "¿Qué implica sumarse?",
        text6: "Obtener acceso a una",
        text7: "app ágil",
        text8: ", diseñada con un lenguaje",
        text9: "comprensible y adaptable",
        text10: "a cualquier contexto: Pentabilities es una herramienta para convertir las evidencias en palanca para la mejora de las ‘soft skills’. ",
        text11: "Acceso a una plataforma para agregar e interpretar las valoraciones de alumnos, compañeros y profesores con",
        text12: "informes multidimensionales",
        text13: "como herramienta de conversación y reflexión para guiar el desarrollo de las habilidades socioemocionales.",
        text14: "Desarrollo profesional del profesorado.",
        text15: "Formación de un grupo de dos a seis profesores para implementar Pentabilities en uno de sus cursos de primero o tercero de la ESO en espacios donde los alumnos interactúan entre ellos.",
        text16: "Asesoramiento",
        text17: ", soporte técnico y acompañamiento durante todo el curso. El equipo pondrá a disposición de los institutos el material necesario para la implementación de la herramienta.",
        text18: "Focus groups",
        text19: "trimestrales con otros profesores y centros participantes.",
        text20: "Fablab del Liceu Politècnic de Sant Cugat del Vallès."
    },
    characteristics: {
        title: "Características del proyecto",
        text1: "Pentabilities se estructura en base a 35 comportamientos sociales y personales reunidos en cinco grandes grupos para que docentes y estudiantes puedan identificar, valorar, modelar y guiar las habilidades socioemocionales.",
        text2: "Enfoque 360º:",
        text3: "En las actividades de clase, profesores, alumnos y compañeros aprenden a identificar los comportamientos y las actitudes, que a lo largo de las sesiones son anotados y utilizados como evidencias para la reflexión, la interiorización y el trabajo socioemocional.",
        text4: "Agilidad:",
        text5: "La aplicación permite grabar a tiempo real las diferentes conductas de forma rápida y sistemática.",
        text6: "Feedback:",
        text7: "Pentabilities permite visualizar los resultados en el tiempo en unos informes claros y precisos. El objetivo de ese feedback es identificar los puntos fuertes y de mejora para proponer metas concretas ajustadas a cada caso, siempre favoreciendo la reflexión y el diálogo con uno mismo y los otros observadores.",
        text8: "Transversal:",
        text9: "El trabajo socioemocional se integra con los aprendizajes curriculares, ofreciendo una diversidad de espacios y situaciones para que las habilidades emerjan con el fin de guiar su desarrollo.",
        text10: "Contrastable:",
        text11: "Descubre las estrategias y dinámicas que funcionan mejor y también aquellas que conviene cambiar en base a la evidencia."
    },
    ignite: {
        annoucement: "Abierta la preinscripción para nuestro programa piloto para integrar el desarrollo de las habilidades socioemocionales en tu aula",
        caterina: "Directora de evaluación de impacto del piloto en estrecha colaboración con entidades internacionales independientes de excelencia en investigación como J-PALO, The Abdul Latif Jameel Poverty Action Lab, y CEMFI. Doctorada en el Departamento de Economía de la Universidad de Yale. Investigadora ICREA del Institute for Political Economy and Governance (IPEG) de la Universitat Pompeu Fabra, centra su investigación en el diseño y evaluación de políticas educativas y, en particular, en aquellas centradas en el desarrollo de las habilidades socioemocionales. Es miembro del Human Capital and Economic Opportunity Group de la Universidad de Chicago, dirigido por el Premio Nobel James Heckman e investigadora Principal de la ERC Starting Grant 638893, que ha financiado el desarrollo de Pentabilities.",
        maria: "Directora pedagógica del proyecto. Doctora en Medicina Veterinaria por la Universidad de Minnesota y después investigadora del CReSA-IRTA. Profesora de ciencias de ESO y Bachillerato durante más de 14 años, ha diseñado y dirigido el programa Jóvenes y Ciencia de Catalunya Caixa y coordinado el programa de excelencia Ciencias y Matemáticas de la Fundación CELLEX (CiMs+CELLEX). También ha implementado y coordinado el programa de Bachillerato Internacional (BI) en Aula Escuela Europea. Actualmente hace investigación educativa en el IPEG de la Universitat Pompeu Fabra, desarrollando e implementando indicadores para la evaluación y desarrollo de habilidades socioemocionales."
    },
    faq: {
        title: "Preguntas Frecuentes",
        question1: "¿Qué significa ‘Pentabilities. Enciende habilidades de vida’?",
        answer1: "Las habilidades sociales y personales (popularmente conocidas como ‘soft skills’) cada día se muestran más determinantes en la trayectoria vital y el éxito escolar de los niños y adolescentes: son, para nosotros, habilidades de vida. Por eso hemos diseñado una herramienta que permite identificar, valorar y modelar estas competencias con base a la estrategia número 1 del aprendizaje: el feedback para impulsar una mejora. Nuestra metodología parte de la observación de treinta y cinco comportamientos que muestran las diferentes dimensiones de las cinco grandes habilidades que cubren todo el rango de la personalidad basadas en el marco más aceptado de la psicología, los ‘Big Five’, y que es también con el que trabaja la Organización para la Cooperación y el Desarrollo Económico (OCDE). De aquí viene el nombre de Pentabilities.",
        question2: "Pero...¿Se pueden educar estas habilidades?",
        answer2: "Históricamente, se han concebido las habilidades socioemocionales como una característica intrínseca e inamovible de la persona. Por eso, hasta hace pocos años no se han incluido en los programas educativos. Pero después de años de investigación, hemos aprendido que hacer frente a la incertidumbre, generar una actitud de esfuerzo o escuchar a los otros son competencias que se pueden aprender. Y, además, hacerlo implica dar herramientas para superar desigualdades sociales. De acuerdo con varios psicólogos como Brent Roberts, estos rasgos son modulados sobre todo por el ambiente —la familia, la escuela, los compañeros y otros factores— y, sobre todo durante la adolescencia y la juventud, son más maleables que las habilidades cognitivas. De hecho, Roberts incluye en su investigación varios programas que han resultado efectivos contra el bullying en las escuelas. Estos ejemplos demuestran que los comportamientos mantenidos en el tiempo afectan los rasgos de carácter. De este modo, las habilidades socioemocionales se pueden educar y se tienen que incluir entre los objetivos de aprendizaje.",
        question3: "¿Cuál es el principal beneficio de implementar Pentabilities a la dinámica diaria de clase?",
        answer3: "Mejorar la eficiencia del aprendizaje del alumnado es uno de los principales beneficios de introducir Pentabilities en el aula. El programa supone una guía y un acompañamiento para trabajar las competencias socioemocionales que revertirá de manera notable en la asimilación de conocimientos de los estudiantes: son en gran medida estas habilidades las que hacen posible un aprendizaje significativo y no a la inversa. De hecho, según un estudio de la Education Endowment Foundation, hacer reflexionar sobre el propio aprendizaje puede suponer un progreso adicional de siete meses. Aun así, potenciar y hacer crecer las habilidades de vida del alumnado tiene otra gran ventaja: dar oportunidades reales al alumnado de entornos desfavorecidos. Mientras que el desarrollo de las habilidades socioemocionales aporta un beneficio de uno o dos puntos al alumnado de mayor nivel socioeconómico, para los de un menor nivel los puede aportar un beneficio de hasta seis puntos.",
        question4: "Como docente, ¿Qué carga de trabajo me comportará implementar el programa o, como director/a, qué recursos tendré que invertir?",
        answer4: "El trabajo socioemocional se integra de manera transversal en la propia actividad docente y no resulta en trabajo extra. Así pues, encender habilidades de vida implica, sobre todo, entrenar la mirada y seguir dando clase a tu manera aprovechando los espacios donde los alumnos interactúan —bien sea pensante, planificando, debatiendo o buscando información— para hacer aflorar, evaluar y guiar las habilidades no cognitivas de los estudiantes. El trabajo del profesor consiste sobre todo en promover una dinámica que haga emerger las competencias sociales y emocionales (cooperación, responsabilidad, autonomía...) que quiere trabajar. A partir de aquí, se trata de observar y registrar los comportamientos durante la clase, extraer un significado a partir de las evidencias recogidas con la ayuda del propio alumnado y ofrecer un feedback para que tomen conciencia de los puntos fuertes y de mejora para establecer nuevos objetivos.",
        question5: "¿En que consiste la formación y el acompañamiento?",
        answer5: "Por un lado, impartimos una formación inicial en línea y asincrónica que introduce el marco conceptual, la descripción de los indicadores, el funcionamiento de la herramienta, la metodología de observación en clase y de las sesiones de feedback con el alumnado. Consiste en la visualización de doce píldoras formativas en formato de video y un formulario de preguntas después de cada video. Por otro lado, impartimos una formación presencial y muy práctica antes de empezar a aplicar: haremos una sesión en grupo de profesores participantes donde pondremos la herramienta en práctica, simularemos una dinámica de grupo, trabajaremos con casos reales, prepararemos planificaciones para diseñar espacios donde poder hacer aflorar y evaluar las habilidades socioemocionales para, después, interpretar los datos y dar un feedback.",
        question6: "¿Qué información se recogerá sobre los alumnos y quién tendrá acceso a estos datos?",
        answer6: "La información que se recoge son comportamientos, es decir, habla de cómo reacciona la gente en los entornos que creamos. Solamente los centros tienen acceso a los nombres y apellidos. En ningún momento salen de allí. La investigación se nutre de datos encriptados: no se ligan a una persona en concreto. El estudio se realizará siguiendo un protocolo aceptado por un comité ético y de protección de datos que garantiza los derechos de los menores y sus familias.",
        question7: "¿Qué pasa si no somos objetivos?",
        answer7: "La objetividad en la evaluación es muy difícil de lograr, y más si evaluamos temas de significado amplio y genérico, como son las pentabilidades. Si registramos evidencias conductuales parece que la subjetividad disminuye, pero, aun así, hay una interpretación personal de las acciones de los alumnos. La idea es que a partir de muchas subjetividades (las de cada profesor y alumno), recogidas en diferentes momentos y contextos, nos permitirán tener una idea más objetiva de cada alumno.",
        question8: "¿Quién financia este proyecto?",
        answer8: "Esta iniciativa es una de las propuestas seleccionadas por el Ministerio de Inclusión, Seguridad Social y Migraciones a través de un llamamiento abierto a entidades de acción social, administraciones autonómicas y locales para el desarrollo de itinerarios de inclusión social y su evaluación. Es un piloto impulsado con fondos europeos NextGeneration del Plan de Recuperación, Transformación y Resiliencia por un periodo de 2 años."
    }
};