/*

=========================================================
* Now UI Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/master/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/css/pentabilities.css";

import "assets/css/now-ui-kit.min.css";
import "assets/css/demo.css";
import "assets/css/font-awesome.min.css";
import "assets/css/nucleo-icons-page-styles.css";
// pages for this kit
import Index from "views/Index";
import Research from "views/Research";
import Products from "views/Products";
import Terms from "views/Terms";
import Team from "views/Team";
import Landing from "views/Landing";
import LandingNext from "views/landing/LandingNext";
import LandingCat from "views/landing/LandingNextCat";
import FuelSkills  from "views/landing/Whats";
import Characteristics  from "views/landing/Characteristics";
import FuelTeam from "views/landing/Team";
import FAQ from "views/landing/Faq";

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import GA from './utilities/GoogleAnalytics';

import MetaTags from 'react-meta-tags';


ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    
    <MetaTags>
    <meta property="og:image" content="/resources/og.png" />
    <meta property="twitter:image" content="/resources/og.png" />
    </MetaTags>
    <BrowserRouter>
    { GA.init() && <GA.RouteTracker /> }
      <Switch>
        <Switch>
          <Route path="/index" render={props => <Index {...props} />} />
          <Route
            path="/research"
            render={props => <Research {...props} />}
          />
          <Route
            path="/products"
            render={props => <Products {...props} />}
          />
           <Route
            path="/team"
            render={props => <Team {...props} />}
          />
           <Route
            path="/terms"
            render={props => <Terms {...props} />}
          />
          <Route
            path="/landing-videos"
            render={props => <Landing {...props} />}
          />
          <Route path="/landing" render={props => <LandingCat {...props} />} />
          <Route path="/encende" render={props => <LandingCat {...props} />} />
          <Route path="/enciende" render={props => <LandingNext {...props} />} />
          
          <Route path="/project" render={props => <FuelSkills {...props} />} />
          <Route path="/characteristics" render={props => <Characteristics {...props} />} />
          <Route path="/skills-team" render={props => <FuelTeam {...props} />} />
          <Route path="/faq" render={props => <FAQ {...props} />} />
          <Redirect to="/index" />
          <Redirect from="/" to="/index" />
        </Switch>
      </Switch>
    </BrowserRouter>
  </I18nextProvider>,
  document.getElementById("root")
);
