import React, { useState } from "react";
import { Badge, List, Button } from "antd";
import BigModal from "./BigModal";
import PentaModal from "../../components/General/Modal";

// i18N hook
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";



const Steps = (props) => {
  return (
    <>
      <List itemLayout="vertical" size="large">
        <Badge count="1">
          <List.Item
            key={<Trans>landing2.join1.title</Trans>}
            actions={[]}
            extra={
              <>
                <img
                  width={200}
                  height={100}
                  style={{ objectFit: "cover" }}
                  alt="logo"
                  src={require("assets/img/pentagon/pentagon1.jpg")}
                />
                <br />
                <br />{" "}
                <center>
                  <PentaModal
                    footer={false}
                    buttonLabel={<Trans>misc.know</Trans>}
                    header={
                      <h5>
                        1. <Trans>landing2.join1.title</Trans>
                      </h5>
                    }
                    bodyHtml={<h5><Trans>landing2.join1.description1</Trans>< br/>< br/><Trans>landing2.join1.description2</Trans></h5>}
                  />
                </center>
              </>
            }
          >
            <List.Item.Meta title={<Trans>landing2.join1.title</Trans>} />
            <Trans>landing2.join1.short</Trans>
          </List.Item>
        </Badge>

        <Badge count="2">
          <List.Item
            key={<Trans>landing2.join2.title</Trans>}
            actions={[]}
            extra={
              <>
                <img
                  width={200}
                  height={100}
                  style={{ objectFit: "cover" }}
                  alt="logo"
                  src={require("assets/img/pentagon/pentagon2.jpg")}
                />
                <br />
                <br />{" "}
                <center>
                  <PentaModal
                    footer={false}
                    buttonLabel={<Trans>misc.know</Trans>}
                    header={
                      <h5>
                        2. <Trans>landing2.join2.title</Trans>
                      </h5>
                    }
                    bodyHtml={<h5><Trans>landing2.join2.description1</Trans>< br/>< br/><Trans>landing2.join2.description2</Trans>< br/>< br/><Trans>landing2.join2.description3</Trans></h5>}
                  />
                </center>
              </>
            }
          >
            <List.Item.Meta title={<Trans>landing2.join2.title</Trans>} />
            <Trans>landing2.join2.short</Trans>
          </List.Item>
        </Badge>

        <Badge count="3">
          <List.Item
            key={<Trans>landing2.join3.title</Trans>}
            actions={[]}
            extra={
              <>
                <img
                  width={200}
                  height={100}
                  style={{ objectFit: "cover" }}
                  alt="logo"
                  src={require("assets/img/pentagon/pentagon3.jpg")}
                />
                <br />
                <br />{" "}
                <center>
                  <PentaModal
                    footer={false}
                    buttonLabel={<Trans>misc.know</Trans>}
                    header={
                      <h5>
                        3. <Trans>landing2.join3.title</Trans>
                      </h5>
                    }
                    bodyHtml={<h5><Trans>landing2.join3.description</Trans></h5>}
                  />
                </center>
              </>
            }
          >
            <List.Item.Meta title={<Trans>landing2.join3.title</Trans>} />
            <Trans>landing2.join3.short</Trans>
          </List.Item>
        </Badge>
        <Badge count="4">
          <List.Item
            key={<Trans>landing2.join4.title</Trans>}
            actions={[]}
            extra={
              <>
                <img
                  width={200}
                  height={100}
                  style={{ objectFit: "cover" }}
                  alt="logo"
                  src={require("assets/img/pentagon/pentagon4.jpg")}
                />
                <br />
                <br />{" "}
                <center>
                  <PentaModal
                    footer={false}
                    buttonLabel={<Trans>misc.know</Trans>}
                    header={
                      <h5>
                        4. <Trans>landing2.join4.title</Trans>
                      </h5>
                    }
                    bodyHtml={<h5><Trans>landing2.join4.description</Trans></h5>}
                  />
                </center>
              </>
            }
          >
            <List.Item.Meta title={<Trans>landing2.join4.title</Trans>} />
            <Trans>landing2.join4.short</Trans>
          </List.Item>
        </Badge>
        <Badge count="5">
          <List.Item
            key={<Trans>landing2.join5.title</Trans>}
            actions={[]}
            extra={
              <>
                <img
                  width={200}
                  height={100}
                  style={{ objectFit: "cover" }}
                  alt="logo"
                  src={require("assets/img/pentagon/pentagon5.png")}
                />
                <br />
                <br />{" "}
                <center>
                  <PentaModal
                    footer={false}
                    buttonLabel={<Trans>misc.know</Trans>}
                    header={
                      <h5>
                        5. <Trans>landing2.join5.title</Trans>
                      </h5>
                    }
                    bodyHtml={<h5><Trans>landing2.join5.description</Trans></h5>}
                  />
                </center>
              </>
            }
          >
            <List.Item.Meta title={<Trans>landing2.join5.title</Trans>} />
            <Trans>landing2.join5.short</Trans>
          </List.Item>
        </Badge>
      </List>
    </>
  );
};

export default withTranslation()(Steps);
