import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
    Card,
    CardImg,
    CardBody,
    CardText,
} from "reactstrap";

// i18N hook
import { Trans } from 'react-i18next';
import { withTranslation } from "react-i18next";

function Project() {
    return (
        <>
            <div className="section pt-1">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="penta-title mt-1">
                                <Trans>products.support.title</Trans>
                            </div>
                            <h5><Trans>products.support.description</Trans></h5>




                        </Col>

                    </Row>
                    <Row>
                        <Col md="4" className="align-self-start">
                            <Card>
                                <CardImg top width="100%" src={require("assets/img/support-1.png")} alt="Card image cap" />
                                <CardBody>
                                    <CardText><Trans>products.support.item1</Trans></CardText>
                                </CardBody>
                            </Card>
                            
                        </Col>
                        <Col md="4">

                        <Card>
                        <CardImg top width="100%" src={require("assets/img/support-2.png")} alt="Card image cap" />
                                <CardBody>
                                    <CardText><Trans>products.support.item2</Trans></CardText>
                                </CardBody>
                                
                            </Card>
                            

                        </Col>
                        <Col md="4">
                        <Card>
                                <CardImg top width="100%" src={require("assets/img/support-3.png")} alt="Card image cap" />
                                <CardBody>
                                    <CardText><Trans>products.support.item3</Trans></CardText>
                                </CardBody>
                            </Card>
                           

                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )
}

export default withTranslation()(Project);