/*eslint-disable*/
import React from "react";
import ReactDOM from "react-dom";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";
// core components

// the hook
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";

import PentaCarousel from "components/General/Carousel";

import Player from "components/Video/YouTubeResponsive";
import YouTube from "react-youtube";
//scroller
import * as Scroll from "react-scroll";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const items = {
  ca: [
   
    {
      src: require("assets/img/partners/recuperacion.png"),
      src_ca: require("assets/img/partners/recuperacion_ca.png"),
      link: "",
    },
    {
      src: require("assets/img/partners/next.jpg"),
      src_ca: require("assets/img/partners/next_ca.jpg"),
      link: "",
    },
    {
      src: require("assets/img/partners/ipeg.png"),
      src_ca: require("assets/img/partners/ipeg_ca.png"),
      link: "",
    },
  ],
  es: [
   
    {
      src: require("assets/img/partners/recuperacion.png"),
      src_ca: require("assets/img/partners/recuperacion_ca.png"),
      link: "",
    },
    {
      src: require("assets/img/partners/next.jpg"),
      src_ca: require("assets/img/partners/next_ca.jpg"),
      link: "",
    },
    {
      src: require("assets/img/partners/ipeg.png"),
      src_ca: require("assets/img/partners/ipeg_ca.png"),
      link: "",
    },
  ],
};

class PentaHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      i18n: props.i18n,
      video: props.video?props.video:'4MTeGiw8PBM?cc_load_policy=1'
    };
  }

  handleClick() {
    // Somewhere else, even another file
    scroller.scrollTo("offer", {
      duration: 1500,
      delay: 100,
      smooth: true,
      containerId: "products",
      offset: 50, // Scrolls to element + 50 pixels down the page
    });
  }
  handleScroll(id) {
    const item = ReactDOM.findDOMNode(this.refs[id]);
    console.log(this.refs[id]);
    //window.scrollTo(item.offsetTop);
  }
  render() {
    return (
      <>
        <div className="page-header landing-header">
          <Container>
            <Row className="row align-items-center">
              <Col md="6" xs="12" className="align-items-center">
                <Row className="align-items-top no-gutters">
                  <Col xs="3">
                    <img src={require("assets/img/lightbulb.png")} />
                  </Col>
                  <Col xs="9">
                    <h1
                      style={{
                        color: "white",
                        fontWeight: "lighter",
                        lineHeight: "normal",
                        display: "contents",
                      }}
                    >
                      <Trans>landing2.title1</Trans>
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        <Trans>landing2.title2</Trans>
                      </span>
                    </h1>
                    <h4
                      style={{
                        color: "white",
                        fontFamily: "DIN Alternate",
                        lineHeight: "normal",
                      }}
                    >
                      <Trans>landing2.subtitle1</Trans>
                    </h4>
                    <Button
                      onClick={() =>
                        window.open(
                          this.state.i18n.language == "ca"
                            ? "https://forms.office.com/r/0j9zhwvQFz"
                            : "https://forms.office.com/r/hzU9bQPdpb",
                          "_blank"
                        )
                      }
                      size={"lg"}
                      style={{
                        backgroundColor: "var(--color-primary-0)",
                        fontWeight: "bold",
                      }}
                    >
                      <Trans>landing2.button4</Trans>
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Col md="6" xs="12">
                <Player youtubeId={this.state.video} />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="12">
                <center>
                  <h5
                    style={{
                      color: "white",
                      fontFamily: "DIN Alternate",
                      lineHeight: "normal",
                    }}
                  >
                    {window.location.pathname==='/enciende'?(<Trans>landinges.subtitle2</Trans>):(<Trans>landing2.subtitle2</Trans>)}
                  </h5>
                </center>
              </Col>
            </Row>
            <Row className="justify-content-center">
              {
                window.location.pathname==='/enciende'?items.es.map((item) => {
                  return (
                    <>
                      <Col xs="2">
                        <div
                          className="rounded img-thumbnail"
                          style={{
                            minHeight: "1px",
                            width: "100%",
                            paddingTop: "100%",
                            //backgroundImage: "url(" + source + ")",
                            backgroundImage:
                              "url(" +
                              (this.state.i18n.language == "ca"
                                ? item.src_ca
                                : item.src) +
                              ")",
                            backgroundPosition: "99% 50%",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundColor: "none",
                          }}
                        />
                      </Col>
                    </>
                  );
                }):items.ca.map((item) => {
                  return (
                    <>
                      <Col xs="2">
                        <div
                          className="rounded img-thumbnail"
                          style={{
                            minHeight: "1px",
                            width: "100%",
                            paddingTop: "100%",
                            //backgroundImage: "url(" + source + ")",
                            backgroundImage:
                              "url(" +
                              (this.state.i18n.language == "ca"
                                ? item.src_ca
                                : item.src) +
                              ")",
                            backgroundPosition: "99% 50%",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundColor: "none",
                          }}
                        />
                      </Col>
                    </>
                  );
                })
              }
              
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default withTranslation()(PentaHeader);
