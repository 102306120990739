/*eslint-disable*/
import React from "react";
import ReactDOM from "react-dom";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";
// core components

// the hook
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";

import PentaCarousel from "components/General/Carousel";

//scroller
import * as Scroll from "react-scroll";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

class PentaHeader extends React.Component {
  constructor(props) {
    super(props);
  }
  handleClick() {
    // Somewhere else, even another file
    scroller.scrollTo("offer", {
      duration: 1500,
      delay: 100,
      smooth: true,
      containerId: "products",
      offset: 50, // Scrolls to element + 50 pixels down the page
    });
  }
  handleScroll(id) {
    const item = ReactDOM.findDOMNode(this.refs[id]);
    console.log(this.refs[id]);
    //window.scrollTo(item.offsetTop);
  }
  render() {
    return (
      <>
        <div className="page-header penta-header" style={{backgroundColor:'var(--color-alternative-2-0)'}}>
          <Container>
            <Row className="justify-content-start">
              <Col md="7" className="align-self-end">
                <h1 className="title text-left">
                  <Trans>index.header.title</Trans>
                </h1>
                {/*<h4 className="text-left text-white">
                  <Trans>index.header.subtitle</Trans>
    </h4>*/}
                <div class="text-left">
                <a href="#products">
                  <Button
                    className="btn-outline-secondary-2-0"
                    style={{
                      marginBottom: "1rem",
                      fontSize: "15px",
                    }}
                    onClick={this.handleScroll.bind(this, "products")}
                  >
                    <Trans>misc.solutions</Trans>
                  </Button>
                  </a>
                  <a href="#contact">
                    <Button
                      className="btn-outline-secondary-2-0"
                      style={{
                        marginBottom: "1rem",
                        fontSize: "15px",
                      }}
                    >
                      <Trans>misc.contact</Trans>
                    </Button>
                  </a>
                </div>
              </Col>
              <Col md="5">
                <PentaCarousel />
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default withTranslation()(PentaHeader);
