/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components

// the hook
import { Trans } from 'react-i18next';

import { withTranslation } from "react-i18next";

const PentaHeader = (props) => {
  let backgroundColor = props.backColor?props.backColor:'var(--color-alternative-2-0)';
  return(
    <>
    <div className="page-header penta-header" style={{backgroundColor:backgroundColor}}>
    <Container>
      <h1 className="penta-generic-title my-0 my-4">{props.pageTitle}</h1>
    </Container>
  </div>
    </>
  );
}

export default withTranslation()(PentaHeader);