import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row
} from "reactstrap";

// i18N hook
import { Trans } from 'react-i18next';
import { withTranslation } from "react-i18next";

// FontAwesome hook
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSmile, faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faBullhorn } from '@fortawesome/free-solid-svg-icons'

// core components
class SignUp extends React.Component {

    state = {};

    constructor(props) {
        super(props);
        this.state = { name: '', email: '', message: '' };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;
        this.setState({
            [id]: value
        });
    }
    handleSubmit(event) {
        const name = this.state.name;
        const email = this.state.email;
        const message = this.state.message;
        var href = 'mailto:info@pentabilities.com?cc=' + email + '&subject=' + name + ' wants to contact Pentabilities&body=' + message;
        window.open(href);
        event.preventDefault();
    }


    render() {
        return (
            <>
                <div
                    className="section section-signup"
                    style={{
                        background: "white url(" + require("assets/img/pentabilities/category3.jpg") + ")",

                        backgroundSize: "cover",
                        backgroundPosition: "top center",
                        minHeight: "700px",

                    }}
                    id="contact"
                >

                    <Container>
                        <Row>
                            <Card className="card-signup" data-background-color="blue">
                                <Form action="" className="form" method="">
                                    <CardHeader className="text-center">
                                        <CardTitle tag="h3">
                                            <Trans>misc.help</Trans>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <FontAwesomeIcon icon={faSmile} />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                id='name'
                                                placeholder="Name..."
                                                type="text"
                                                onFocus={e => this.setState({ nameFocused: true })}
                                                onBlur={e => this.setState({ nameFocused: false })}
                                                value={this.state.name}
                                                onChange={this.handleChange}
                                            ></Input>
                                        </InputGroup>

                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <FontAwesomeIcon icon={faEnvelope} />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                id='email'
                                                placeholder="Email..."
                                                type="email"
                                                onFocus={e => this.setState({ emailFocused: true })}
                                                onBlur={e => this.setState({ emailFocused: false })}
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                            ></Input>
                                        </InputGroup>

                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <FontAwesomeIcon icon={faBullhorn} />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                id='message'
                                                placeholder="Message..."
                                                type="textarea"
                                                onFocus={e => this.setState({ messageFocused: true })}
                                                onBlur={e => this.setState({ messageFocused: false })}
                                                value={this.state.message}
                                                onChange={this.handleChange}
                                                cols="80"
                                                row="4"
                                            ></Input>
                                        </InputGroup>


                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Button
                                            className="btn-neutral btn-round"
                                            color="info"
                                            href="#pablo"
                                            onClick={this.handleSubmit}
                                            size="lg"
                                        >
                                            Send Email
                                        </Button>
                                    </CardFooter>
                                </Form>
                            </Card>
                        </Row>

                    </Container>
                </div>
            </>

        );

    }

}

export default withTranslation()(SignUp);