import React from "react";
import { Row, Col, Container } from "reactstrap";
import { BackTop } from "antd";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar";
import PentaHeader from "components/Headers/PentaHeader";
import PentaFooter from "components/Footers/PentaFooter";

// Other elements
import Member from "components/General/Member";

// i18N hook
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";

function Terms() {
  const team = [
    {
      photo: require("assets/img/team/caterina.jpg"),
      name: "Caterina Calsamiglia",
      position: "CEO and Co-founder",
      description: <Trans>team.caterina</Trans>,
      linkedIn: "https://www.linkedin.com/in/caterina-calsamiglia-a601b3a/",
      twitter: "https://google.com",
      email: "caterina.calsamiglia@pentabilities.com",
    },
    {
      photo: require("assets/img/team/maria.jpg"),
      name: "María Calsamiglia",
      position: "Co-founder",
      description: <Trans>team.maria</Trans>,
      linkedIn: "https://www.linkedin.com/in/maria-calsamiglia-costa-a029b916/",
      twitter: "https://google.com",
      email: "maria.calsamiglia@pentabilities.com",
    },
    {
      photo: require("assets/img/team/jacek.jpg"),
      name: "Jacek Dominiak",
      position: "XXXX",
      description: <Trans>team.jacek</Trans>,
      linkedIn: "https://www.linkedin.com/in/jacekdominiak/",
      twitter: "https://google.com",
      email: "jacek.dominiak@pentabilities.com",
    },
    {
      photo: require("assets/img/team/juan.jpg"),
      name: "Juan David Valencia Mendieta",
      position: "Entrepreneur in Residence",
      description: <Trans>team.juan</Trans>,
      linkedIn: "https://www.linkedin.com/in/valenciajd/",
      twitter: "https://google.com",
      email: "juan.valencia@pentabilities.com",
    },
  ];

  return (
    <>
      <BackTop />
      <IndexNavbar />
      <div className="wrapper">
        <PentaHeader pageTitle={<Trans>team.title</Trans>} />
        <div className="main">
          <div className="section pt-2">
            <Container>
              <Row>
                <Col md="12">
                  <h4>
                    <Trans>research.project.description11</Trans>
                    <br></br>
                    <br></br>
                    <Trans>research.project.description12</Trans>
                    <br></br>
                    <br></br>
                    <Trans>research.project.description13</Trans>
                  </h4>
                  <div className="penta-title">
                    <Trans>team.memberstitle</Trans>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                {team.map((member) => {
                  return (
                    <Col md="6">
                      <Member
                        photo={member.photo}
                        name={member.name}
                        position={member.position}
                        description={member.description}
                        linkedIn={member.linkedIn}
                        twitter={member.twitter}
                        email={member.email}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </div>
        </div>
        <PentaFooter />
      </div>
    </>
  );
}

export default withTranslation()(Terms);
