import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
  
} from "reactstrap";

// i18N hook
import { Trans } from 'react-i18next';
import { withTranslation } from "react-i18next";

const items = [
    
    {
        src: require("assets/img/partners/worldbank.png"),
        link: 'https://www.worldbank.org/'
    },
    {
        src: require("assets/img/partners/esade.svg"),
        link: 'https://www.esade.edu/en/'
    },
    {
        src: require("assets/img/partners/ipeg.png"),
        link: 'http://barcelona-ipeg.eu/'
    },
    {
        src: require("assets/img/partners/upf.jpg"),
        link: 'https://www.upf.edu/'
    },
    {
        src: require("assets/img/partners/bonn.png"),
        link: 'https://www.zei.uni-bonn.de/education'
    },
   
    {
        src: require("assets/img/partners/chicago.svg"),
        link: 'https://cehd.uchicago.edu/'
    },
    
  
]
const items2 = [
    {
        src: require("assets/img/partners/activafp.jpg"),
        link: 'https://agora.xtec.cat/formacio/form810/categoria/impuls-fp/activa-fp/'
    },
    {
        src: require("assets/img/partners/liceu.png"),
        link: 'https://www.liceupolitecnic.es/'
    },
    {
        src: require("assets/img/partners/virolai.jpg"),
        link: 'https://www.virolai.com/'
    },
    {
        src: require("assets/img/partners/lesvinyes.jpg"),
        link: 'https://agora.xtec.cat/ielesvinyes/'
    },
    {
        src: require("assets/img/partners/horitzo.png"),
        link: 'https://www.escola-horitzo.cat/'
    }
   

]

function Customers() {
    return (
        <>
            <div className="section">
                <Container>
                
              <h4 style={{color:'black',fontFamily: "'DIN Alternate', 'Roboto', sans-serif",fontWeight:'bold', marginTop: '0', marginBottom: '0'}}>
                  <Trans>index.partners.title</Trans></h4>

                  < br/>
                    <Row className="justify-content-center">
                        {items.map(item =>{
                            return (
                                <>
                                

                               <Col md="2">
                                   <a href={item.link} rel="noopener noreferrer" target="_blank">

                                <div
                                className="rounded img-thumbnail"
                                style={{
                                    minHeight: "1px",
                                    width: "100%",
                                    paddingTop: "100%",
                                    backgroundImage: "url("+item.src+")",
                                    backgroundPosition: "99% 50%",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                  }}
                                />
                                </a>
                                

                                    
                                </Col>
                                </>
                            );
                        })}
                    </Row>
                    <Row className="justify-content-center pt-4">
                        {items2.map(item =>{
                            return (
                                <>
                              

                               <Col md="2">
                                   <a href={item.link} rel="noopener noreferrer" target="_blank">

                                <div
                                className="rounded img-thumbnail"
                                style={{
                                    minHeight: "1px",
                                    width: "100%",
                                    paddingTop: "100%",
                                    backgroundImage: "url("+item.src+")",
                                    backgroundPosition: "99% 50%",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                  }}
                                />
                                </a>
                                

                                    
                                </Col>
                                </>
                            );
                        })}
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default withTranslation()(Customers);