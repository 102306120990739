export default {
    index: {
        header: {
            title: "Vols que les teves accions formatives potenciïn el desenvolupament de les habilitats socials i personals?",
            subtitle: "Desafiem el statu quo, ajudant individus i organitzacions a guanyar perspectiva i créixer de dins cap a fora",
        },
        motivation: {
            title: "Per què Pentabilities?",
            description1: "Cal que les persones responguem a aquest món que canvia ràpidament. Necessitem eines que ens ajudin a adquirir aprenentatges significatius, per poder adaptar-nos, participar i transformar el món.",
            taxonomy: "The Taxonomy Project",
            taxonomydescription: "a la Universitat de Harvard, descriu com l'aprenentatge social i emocional (SEL, per les sigles en anglès) pot organitzar-se en tres àrees interrelacionades: social, emocional i cognitiu.",
            description2: "Actualment centres educatius i de treball estan perdent l'oportunitat de desenvolupar aquestes habilitats, fet que comporta:",
            item1: "El deteriorament de les motivacions internes per aprendre, adaptar-se i sobreviure",
            item2: "Ampliació de l'escletxa d'habilitats",
            item3: "Perpetuació de les desigualtats socials",
            goal: "Volem propiciar que les organitzacions i individus puguin obtenir perspectiva i facilitar el seu creixement des de dins cap a fora, és a dir, permetre a les persones aprendre, adaptar, transformar i repensar el món en què vivim.",
            description3: "Pentabilities reconeix les motivacions internes, identifica les habilitats claus que no es mesuren en les pràctiques tradicionals d'aprenentatge, i les defineix i promou a través d'avaluacions basades en evidències.",
        },
        challenges: {
            title1: "Reptes actuals",
            description1: "El problema és que la societat no té una comprensió comuna sobre el que signifiquen les habilitats socioemocionals, i sobre com desenvolupar-les a través de l'educació i el desenvolupament professional.",
            item1: "Manca de consens sobre què signifiquen les habilitats socioemocionals (SES, per les sigles en anglès).",
            item2: "Manca de coneixement sobre com millorar les SES en l'educació i el desenvolupament professional.",
            item3: "Manca de precisió i rigor en l'àmbit de l'aprenentatge socioemocional (SEL).",
            item4: "Manca d'un llenguatge comú en l'avaluació de l'aptitud socioemocional.",
            title2: "La nostra solució",
            description2: "Proposem un conjunt finit de comportaments concrets que puguin demostrar l'adquisició d'un ampli conjunt d'habilitats socials i emocionals.",
            item5: "Definir comportaments concrets que corresponguin a un ampli conjunt de SES.",
            item6: "Crear un objectiu comú clar i compartit.",
            item7: "Facilitar la recollida contínua d'evidències per part dels observadors.",
            item8: "Donar feedback concret i basat en evidències.",
            item9: "Possibilitar l'avaluació i millora de les estratègies d'aprenentatge.",
            description3: 'Comportaments concrets per demostrar habilitats socioemocionals'
        },
        projects: {
            title: 'Els nostres projectes',
            title1: 'Canviant les escoles de secundària a Catalunya (Espanya)',
            caption1: 'Difonent la metodologia i eines digitals de Pentabilities a tota la regió',
            description1_1: 'Aproximadament 1000 estudiants de secundària a Catalunya han participat de les proves de la metodologia i les eines digitals de Pentabilities.',
            description1_2: "Aquestes escoles són mentores d'altres escoles a través d'Escola Nova 21 - una xarxa de ~ 500 escoles que estan implementant estratègies d'aprenentatge col·laboratiu i autònom. Pentabilities ha treballat conjuntament amb els mestres per integrar l'avaluació formativa de l'aprenentatge socioemocional en les seves activitats i metodologies existents.",
            title2: 'Un espai concret per al canvi',
            caption2: 'Creant un producte totalment desenvolupat que es pot incorporar a qualsevol escola espanyola que vulgui implementar la metodologia Pentabilities',
            description2_1: "Moltes escoles utilitzen la classe magistral com a principal mètode pedagògic i no saben com iniciar el canvi. Per facilitar la transició, juntament amb l'escola secundària Liceu Politècnic, hem creat Fabilities.",
            description2_2: "Fabilities és un ambient d'aprenentatge que permet als estudiants desenvolupar projectes STEM que són guiats i connectats a través de la història i les humanitats. Les activitats de la classe i les evidències recollides durant tot el projecte faciliten el procés iteratiu per trobar solucions innovadores alhora que permet el desenvolupament dels estudiants com a individus i com a grup.",
            description2_3: "L'objectiu del projecte Fabilities és promoure el canvi escalable en el sistema educatiu espanyol.",
            title3: 'De les escoles de formació professional al mercat laboral',
            caption3: "El desenvolupament d'un sistema que redueix la bretxa d'habilitats i permet alinear les capacitats dels estudiants amb les necessitats del mercat laboral",
            description3_1: "Pentabilities ha proporcionat formació a través del Ministeri d'Educació a professors d'onze centres de formació professional que implementen pràctiques avançades. Hem ajudat a adaptar les seves pedagogies perquè puguin beneficiar-se plenament del cicle de desenvolupament Pentabilities - la recollida d'evidències i provisió de feedback. Les dades recollides seran utilitzades per alinear les capacitats dels estudiants amb les expectatives de les empreses.",
            description3_2: "Pentabilites va participar en el concurs ´24 hores d'innovació´, on 24 grups formats per vuit membres cadascun van competir entre si en només 24 hores per resoldre els reptes relacionats amb el món empresarial. Els seus comportaments i habilitats socioemocionals van ser registrats per observadors externs a través de l'aplicació Pentabilities. Aquells grups que van guanyar els premis del jurat eren els que tenien comportaments que indicaven unes habilitats socioemocionals més fortes.",
            title4: 'Educació superior',
            caption4: "Transformant l'educació superior a Europa",
            description4_1: 'Bonn University',
            description4_2: "En associació amb el Centre d'Innovació de la Universitat de Bonn, Pentabilities es va utilitzar en una classe d'economia en línia per primera vegada durant la pendemia de Covid-19. La Universitat vol utilitzar Pentabilities en altres cursos i disciplines.",
            description4_3: 'ESADE Business School',
            description4_4: "Responent a les necessitats de la nostra societat, ESADE té com a objectiu integrar el desenvolupament de les habilitats socioemocionals en els seus programes. Pentabilities s'ha associat amb ESADE en la creació d'un BA dirigit totalment a la capacitació d'estudiants de negocis per convertir-los en els pròxims agents de canvi. Pentabilities serà l'espina vertebral en el disseny d'aquest nou títol de BA, que s'implementarà durant els propers cinc anys."
        },
        learning: {
            title: "Pentablities facilita el cicle de desenvolupament",
            description: "Les habilitats, productes i idees són generades seguint cicles de desenvolupament. Pentabilities t'ajuda a definir el teu propi cicle i alimentar-lo amb força!",
            step1: "Estableix un objectiu:",
            step1description: "el nostre llistat de comportaments concrets facilita crear objectius específics i compartits.",
            step2: "Disseny de projectes i activitats:",
            step2description: "t'ajudem a dissenyar noves estratègies per afrontar els teus objectius.",
            step3: "Observar i registrar evidències:",
            step3description: "la nostra aplicació facilita la recollida immediata d'evidències per part dels usuaris.",
            step4: "Anàlisi:",
            step4description: "la nostra aplicació web facilita la visualització i anàlisi de dades.",
            step5: "Feedback per prendre consciència:",
            step5description: "t'ajudem a crear una cultura de creixement per mitjà de feedback específic i constructiu.",
        },
        products: {
            title: "Què oferim?",
            description1: "Ajudem a",
            client1: "escoles, universitats",
            client2: "centres de recerca",
            client3: "organitzacions no governamentals (ONG)",
            client4: "companyies",
            description2: "a crear una cultura que potencia el desenvolupament d'habilitats socioemocionals i floreix a través del feedback.",
            producttitle1: "Eines",
            productdescription1: "Eines que permeten recopilar i analitzar evidències en temps real",
            producttitle2: "Formació",
            productdescription2: "Formació pas a pas sobre com introduir i implementar Pentabilities. Pots veure els nostres vídeos introductoris a continuació:",
            producttitle4: "Suport",
            productdescription4: "Suport personalitzat durant el procés de construcció d'una cultura Pentabilities a la teva escola/ONG/empresa",
        },
        testimonials: {
            title: "El que diu la gent",
            testimonial1: "El fet de tenir les evidències és increïblement útil per crear un llenguatge comú i assegurar-nos que estem parlant del mateix.",
            position1: "Professor i Coordinador de l'IE Les Vinyes (Escola Secundària)",
            testimonial2: "La vostra proposta evita reticències per part dels directius a implementar programes corporatius que donen suport als processos de feedback continu. Permet una filosofia de lideratge a tots els nivell, fent que tots els empleats rebin feedback de la comunitat, amb control centralitzat.",
            position2: "Antiga Cap de Desenvolupament de Talent, Educació i Diversitat a EMEA, CA Technologies",
            testimonial3: "La comprensió de les habilitats sociopersonals de les persones d'una organització és essencial per a qualsevol transformació cultural. Pentabilities permet una anàlisi precisa, basada en evidències, d'aquestes habilitats.",
            position3: "Agile Coach",

        },
        partners: {
            title: "Amb qui treballem",
        }
    },
    products: {
        tools: {
            title: "Eines ",
            description: "Les nostres eines ofereixen a tots els usuaris solucions simples per ajudar-los en la captura, seguiment i desenvolupament de les habilitats socioemocionals. Les aplicacions web i mòbil són recursos vitals per a la implementació de la nostra metodologia. Si bé ja tenim una plataforma genèrica que funciona per a qualsevol organització, també oferim fer personalitzacions, integrar Pentabilities amb altres eines i/o la teva intranet o afegir noves funcionalitats. Explica'ns les teves necessitats i mirarem d'adaptar-les.",
            mobile: "Aplicació mòbil",
            mobileshortdescription: "L'aplicació mòbil facilita la recollida i classificació de l'evidència observada.",
            mobileitem1: "Accés ràpid als teus estudiants i als diferents comportaments",
            mobileitem2: "Fàcil habilitació de l'auto i co-avaluació de grups",
            mobileitem3: "Etiqueta les teves evidències per enriquir l'anàlisi posterior",
            mobiledescription1: "Ser conscient d'un mateix i capaç de guanyar perspectiva per millorar les teves pentabilitats pot ser un gran repte. Les valoracions es basen en general en percepcions subjectives, limitades i costoses. A més, no tenim estàndards per analitzar-les i comparar-les en diferents contextos i al llarg del temps, fet necessari per la seva aplicabilitat en un ampli nombre d'entorns com escoles, universitats, programes socials o empreses.",
            mobiledescription2: "L'objectiu de l'aplicació mòbil és per permetre una avaluació basada en evidències d'habilitats no cognitives. Facilitem la recol·lecció d'evidències de comportaments a través d'una aplicació mòbil, accessible en qualsevol lloc i moment. Els comportaments estan definits per a cada habilitat, el que permet als observadors recol·lectar evidències d'aquests comportaments. L'anàlisi de les evidències introduïdes per un ampli conjunt d'observadors en diferents contextos proporciona una imatge única del que ets als ulls dels altres, com canvies quan t'enfrontes a diferents reptes. Per tant, és una imatge clara de les teves fortaleses i debilitats i el teu potencial per a canviar-les.",
            web: "Aplicació web",
            webshortdescription: "L'aplicació web està dissenyada per proporcionar les gràfiques més útils de les dades recollides.",
            webitem1: "Anàlisi de les dades a nivell individual",
            webitem2: "Anàlisi de les dades a nivell de grup per a replantejar o millorar el disseny d'activitats i entorns",
            webitem3: "Generació d'informes personalitzats resumint les evidències recollides de cada estudiant/participant",
        },
        training: {
            title: "Formació",
            subtitle: "Orientació en la incorporació de Pentabilities i capacitació en cada pas de la transformació",
            description: "Proporcionem orientació en el procés d'implementació de Pentabilities, i formació dels treballadors i professors a cada pas de la transformació.",
            item1: "Inducció",
            itemdescription1: "Comprendre la filosofia Pentabilities, comportaments com a demostracions de constructes, eines multi-plataforma",
            item2: "Implementació",
            itemdescription2: "La creació d'un context adequat per observar comportaments, aprenentatge basat en projectes i activitats de col·laboració, captura d'evidències, avaluacions 360º",
            item3: "Perspectiva",
            itemdescription3: "Preparació de les sessions de feedback basades en evidències, la iteració i l'adaptació de les estratègies segons els reptes derivats que condicionaran la planificació futura.",
        },
        support: {
            title: "Suport",
            description: "Cada institució té les seves particularitats i context. Pentabilities t'ajuda a integrar la teva realitat al nostre sistema. T'ajudem a Pentabilizar la teva escola / ONG / empresa, és a dir, crear les eines, entorns i cultura perquè Pentabilities pugui catalitzar els teus objectius.",
            item1: "T'ajudem a adaptar els teus entorns i eines existents per facilitar l'observació i recollida d'evidències",
            item2: "Adaptem el nostre portal d'analítiques i t'ajudem a adaptar els teus entorns per fomentar el feedback interpersonal i promoure el creixement",
            item3: "T'ajudem a abordar els punts febles, creant plans d'acció per millorar l'aprenentatge socioemocional en la teva institució",

        }
    },
    research: {
        system: {
            shorttitle: "El sistema",
            title: "Què és el sistema Pentabilities?",
            description: "Pentabilities és el sistema per al desenvolupament de les cinc grans habilitats socials i emocionals, les pentabilitats: Responsabilitat, Cooperació, Autonomia i iniciativa, Gestió emocional i Habilitats de pensament. Està demostrat que unes bones habilitats sociopersonals ajuden a millorar el nostre benestar i el del nostre entorn al llarg de la vida, a més de facilitar el camí cap a l’èxit acadèmic i l’adaptació al mercat laboral. El sistema, dissenyat per implementar-se en centres educatius i organitzacions avançades, parteix de la creació d’un marc comú i està estructurat en tres nivells: Observar evidències, Registrar evidències i Donar bon feedback.",
            description1: "Pentabilities és el sistema per al desenvolupament de les cinc grans habilitats socials i emocionals, les pentabilitats: Responsabilitat, Cooperació, Autonomia i iniciativa, Gestió emocional i Habilitats de pensament.",
            description2: "Està demostrat que unes bones habilitats sociopersonals ajuden a millorar el nostre benestar i el del nostre entorn al llarg de la vida, a més de facilitar el camí cap a l’èxit acadèmic i l’adaptació al mercat laboral.",
            description3: "El sistema, dissenyat per implementar-se en centres educatius i organitzacions avançades, parteix de la creació d’un marc comú i està estructurat en tres nivells: Observar evidències, Registrar evidències i Donar bon feedback.",
        },
        steps: {
            title1: "Observar Evidències",
            description1: "A l’hora de valorar cada pentabilitat, proposem accions específiques, les evidències, per fer-ho. Les evidències ens permeten crear un llenguatge comú i concret a l’hora de definir les pentabilitats. A més, les evidències ens ajudaran a donar un bon feedback. Posem en pràctica l’observació d’evidències en diferents activitats planificades.",
            fulldescription11: "Les evidències de les pentabilitats les poden observar i registrar professors, monitors i companys, en diferents contextos i enfront de diferents reptes. Això dona una imatge rica, tant de la variabilitat de les pentabilitats en funció dels entorns dissenyats com de la variació de la seva percepció segons situacions i persones.",
            fulldescription12: "Abans de començar amb les observacions i els registres, és indispensable llegir les definicions de les pentabilitats i les corresponents evidències que les justifiquen, i consensuar-ne el significat per establir un llenguatge comú i concret que serveixi per delimitar les observacions.",
            fulldescription13: "És útil centrar-se primer en una sola pentabilitat i les seves corresponents evidències per adquirir una nova mirada sense etiquetes. Fer una diagnosi amb l’alumnat o amb membres de l’organització, consensuar les pentabilitats a incloure en activitats planificades i construir un marc de confiança són els primers passos per treballar l’observació d’evidències.",
            fulldescription14: "És pràctic deixar-se un temps per exercitar l’observació i l’escolta activa, sense etiquetes, abans d’incorporar també el registre d’evidències. No obstant això, una vegada exercida una nova mirada, l’observació i el registre formen una acció conjunta.",
            fulldescription15: "Aquí trobareu el llistat d’evidències, o accions específiques, que justifiquen les diferents  pentabilitats. Es tracta de dedicar temps per consensuar el significat i prioritzar les pentabilitats i escollir les evidències en funció de l’activitat i del context de cada centre educatiu o organització.",
            title2: "Registrar Evidències",
            description2: "Planifiquem l’observació i el registre d’evidències per activitats, sessions i grups. Registrem el que veiem: valorem la pentabilitat i registrem també la valoració de les evidències que la justifiquen. Fem les observacions i els registres al llarg del temps per aconseguir una mostra representativa i una visió global de l’alumnat.",
            fulldescription21: "El registre de les evidències s’ha de planificar durant les activitats en què l’alumnat interaccioni. Registrem el que veiem: valorem la pentabilitat i registrem també la valoració de les evidències que la justifiquen.",
            fulldescription22: "El nostre objectiu ha de ser que l’observació sigui representativa, és a dir, no ha de respondre a situacions extremes que es donin a l’aula. Per tant, és molt important que l’observació sigui aleatòria. Com més observacions es realitzin per alumne, millor, però no cal anar a màxims, sinó buscar representativitat en les evidències recollides.",
            fulldescription23: "El registre ha de ser immediat a l’observació d’una acció específica. D’aquesta manera, evitem que la nostra memòria ens condueixi a simplificar i etiquetar, i així aconseguirem una visió global. Per facilitar el registre d’evidències i l’anotació de comentaris, i possibilitar així el bon feedback posterior, podem utilitzar l’aplicació mòbil/tablet Pentabilities app. Aquesta aplicació, desenvolupada específicament pel projecte Pentabilities, permet el registre i l’anàlisi de les evidències de les pentabilitats a temps real a l’aula, estalviant molt de temps.",
            fulldescription24: "Per registrar les evidències, és recomanable seguir un mètode, i partir d’una programació realista i assumible, sobretot al principi. Podem anar per grups, observar i interactuar amb els alumnes entre 5 i 10 minuts al llarg d’una activitat, i, abans d’anar al següent grup, registrar el que hem vist. Primer valorem la pentabilitat i després valorem les evidències que justifiquen la valoració de la pentabilitat. En alguns casos, serà útil afegir comentaris o qualsevol altra nova evidència o comportament. ",
            fulldescription25: "Finalitzat un grup, repetim el procediment en un altre grup, i així successivament. Abans d’acabar una sessió, també és preferible destinar entre 5 i 10 minuts per grup per coavaluar els diferents membres. Una opció molt interessant és que cada grup aturi la seva activitat durant 5-10 minuts i cada membre observi i registri les pentabilitats d’un altre grup. Educar la mirada d’observador és una molt bona manera d’aprendre un mateix. Sempre és millor observar i registrar en diferents sessions que no pas fer una única observació llarga.",
            fulldescription26: "Per registrar les evidències podem utilitzar l’aplicació Pentabilities:",
            fulldescription27: "Cal tenir en compte que el registre d’evidències suposa un canvi d’hàbits. L’adaptació és lenta i pot semblar farragosa. És molt important no registrar tan sols aquelles conductes que confirmen les idees que ja tenim sobre els alumnes. Les conductes varien segons el context, i és interessant identificar aquelles situacions en què l’alumne treu el millor de si mateix, així com aquelles en què ha de centrar la seva millora.",
            fulldescription28: "La riquesa del sistema rau en disposar d’un registre de comportaments en diferents moments de les activitats i fases dels projectes, des del punt de vista de diferents professors, matèries, companys, etc. Això aporta informació molt útil per a conèixer les motivacions, els ambients i els entorns que provoquen conductes positives i conductes a evitar de cada individu.",
            title3: "Donar un bon feedback",
            description3: "El bon feedback es dona o en el moment de l’observació o posteriorment, segons la situació. Un bon feedback és una acció periòdica basada en l’anàlisi de les evidències registrades en un període de temps. Només a partir de les evidències podem donar un bon feedback, honest, concret i útil.",
            fulldescription31: "Donar un bon feedback és també una gran habilitat de comunicació, que requereix temps i molta pràctica. No es tracta de donar una indicació general, ni tampoc d’escriure un comentari al final d’una activitat per justificar una nota. Donar un bon feedback és un treball sistemàtic dins un marc de confiança i de seguretat emocional. L’alumnat ha de percebre el feedback com una activitat central, útil i de valor en el seu aprenentatge, i no com un judici.",
            fulldescription32: "Respostes habituals com “t’has d’organitzar” o “has de raonar millor” són indicacions generals, però no donen pistes de com millorar. Cal partir de les evidències observades i registrades, emmarcar-les i conduir una conversa constructiva, amb preguntes obertes i evitant afirmacions imperatives. Sempre amb un to amable, però sense fugir del rigor i de l’honestedat, tant per millorar punts dèbils com per celebrar punts forts.",
            fulldescription33: "El missatge ha de ser concret, coherent, clar i útil, allunyat de l’abstracció. Per aquest motiu, la recollida d’evidències és la clau i la via per tal que l’alumnat pugui entendre allò que no acaba de fer bé. Un bon feedback sistemàtic orienta l’alumnat per saber en quina situació es troba i què pot fer per millorar. Aquest coneixement personal és el punt de partida per posar en pràctica les indicacions rebudes en la següent tasca o projecte que es durà a terme i que per tant caldrà planificar. I així successivament.",
            fulldescription34: "Una bona manera de planificar les següents accions de millora en una activitat és utilitzant l’aplicació mòbil/tablet Pentabilities. De manera ràpida, podrem visualitzar el desenvolupament de les pentabilitats en el seu conjunt, veure i contextualitzar la valoració d’evidències per dates i activitats, entre altres, i extreure’n conclusions. Això ens permet adaptar-nos més ràpidament i ens ajuda a personalitzar l’aprenentatge.",
        },
        matter: {
            shorttitle: 'Rellevància',
            title1: "Per què són importants?",
            description11: "Està demostrat que unes bones habilitats sociopersonals ajuden a millorar el nostre benestar i el del nostre entorn al llarg de la vida, a més de facilitar el camí cap a l’èxit acadèmic i l’adaptació al mercat laboral.",
            description12: "Les pentabilitats tenen un impacte fonamental en el benestar al llarg de la vida d’una persona. Estan relacionades amb la presa de decisions adequades; l’accés, l’estabilitat i l’encaix al mercat laboral; mantenir hàbits saludables i establir relacions personals positives. Tant és així, que les desigualtats socials s’expliquen en gran manera per les diferències en aquestes habilitats, més que no pas per les habilitats cognitives.",
            description13: "Les pentabilitats són una condició necessària per tal que es produeixi l’aprenentatge: la resiliència, la perseverança, l’autonomia, l’autoconfiança i la capacitat de comunicació són habilitats imprescindibles en l’èxit acadèmic. Però la recerca demostra que les habilitats socials i emocionals no es desenvolupen de forma espontània, cal guiar-les. Per altra banda, tot i que tenen una base genètica, també són modulades per l’ambient: família, escola, companys. Fins i tot durant l’adolescència són més mal·leables que les habilitats cognitives.",
            description14: "Així doncs, donat l’impacte de les pentabilitats en les desigualtats socials, és fonamental actuar sobre aquestes habilitats en l’entorn escolar, tant per un tema de justícia com d’eficiència.",
            description15: "Referències científiques i recursos de la informació donada:",
            description16: "Els quatre pilars de la formació integral, segons l’Informe Jacques Delors de l’Organització de les Nacions Unides per a l’Educació, la Ciència i la Cultura (UNESCO) són aprendre a conèixer: adquirir els instruments de la comprensió; aprendre a fer: per poder influir en l’entorn; aprendre a conviure: per participar i cooperar amb els altres en tota activitat humana; aprendre a ser: per poder prendre decisions de forma autònoma i amb esperit crític.",
            description17: "Les pentabilitats engloben l’aprendre a ser i l’aprendre a conviure, i estan estretament relacionades amb l’aprendre a conèixer i l’aprendre a fer. També recullen les cinc grans categories de personalitat, centrals, entre d’altres, en el marc de treball de l’Organització per la Cooperació i el Desenvolupament Econòmic (OCDE). Per la seva rellevància, s’han d’incloure com a objectiu d’aprenentatge al llarg de tota l’escolaritat i la vida.",
            reference11: "Heckman, JJ I Kautz, T. 2012.",
            reference12: "Kautz et al., 2014.",
            reference13: "Deming, D. “The Growing Importance of Social Skills in the Labor Market”. NBER working paper 21473.",
            reference14: "The Myth of Achievement Tests. University of Chicago Press. 2014. Ed. Heckman, J.J. , Humphries, J.E., Kautz, T.",
            reference15: "Skills For Social Progress by James Heckman",
            reference16: "Hard Facts Soft Skills",
            title2: "En què es basen?",
            description21: "Tot i la seva importància, ben descrita i reconeguda, les habilitats sociopersonals no es treballen i avaluen de forma sistemàtica a les institucions educatives. L’obstacle principal per visualitzar el progrés i abordar les carències d’aquestes habilitats és l’absència de mesures fiables i viables per avaluar-les de forma extensiva. Registrar comportaments determinats en diferents contextos posa en evidència el desenvolupament de les diferents habilitats i el feedback que se’n desprèn és necessari per treballar aquestes capacitats apropiadament. La recollida d’evidències per a facilitar aquest feedback, doncs, és una pedra angular de l’ensenyament i aprenentatge, tant per a les disciplines acadèmiques com per a les habilitats socioemocionals.",
            description22: "El sistema Pentabilities es basa en un cicle -observar, registrar i donar bon feedback – on la clau són els comportaments i accions concretes que observem, registrem i a partir dels quals donem el feedback. Aquests comportaments i accions concretes són les evidències que ens permeten valorar cadascuna de les cinc habilitats que inclouen les habilitats sociopersonals bàsiques.",
            description23: "Proposem, doncs, un llenguatge comú, en forma d’evidències (accions concretes), a partir del qual treballar les habilitats en entorns educatius com ara escoles, centres d’educació professional, d’educació superior i en el marc laboral, per mitjà del sistema Pentabilities: observar, registrar, donar bon feedback.",
            description24: "Per saber-ne més sobre el marc de les habilitats i les evidències que descrivim, podeu ",
            description25: "clicar en aquest enllaç.",
            title3: "Evidències per a què?",
            description31: "Per al feedback, clau en el procés d’aprenentatge i creixement de l’alumne.",
            description32: "Per possibilitar l’anàlisi de quines estratègies educatives faciliten que aflorin i es treballin les pentabilitats i entendre per què. És també una eina que permet entendre com i què s’observa i detectar i corregir possibles biaixos en la percepció.",
            description33: "Poder comunicar a tercers (pares, universitats, empreses) el recorregut de la persona amb un llenguatge comú.",
        },
        project: {
            title: "El projecte",
            description11: "El sistema Pentabilities per al desenvolupament de les habilitats sociopersonals en educació i en organitzacions és la conseqüència de l’estudi finançat per l’Starting Grant de l’European Research Council (ERC) CompSCHoice 638893, concedit l’any 2014 a Caterina Calsamiglia. Aquesta investigació, basada en el mètode científic, cerca la definició d’indicadors socials i emocionals objectius, mesurables i comunicables.",
            description12: "En aquest estudi, també hi participen escoles i centres educatius que estan implementant estratègies pedagògiques en educació que incorporen el desenvolupament d’habilitats socials i emocionals, les quals s’integren en la resta de competències cognitives.",
            description13: "El sistema reflecteix les cinc grans categories de personalitat, és a dir, la tendència de les persones a respondre de determinada manera en circumstàncies concretes. Una part important de les evidències que recolzen cada una d’aquestes pentabilitats s’observa i es desenvolupa durant el treball col·laboratiu.",

        }

    },
    nav: {
        home: "Inici",
        products: "Productes i serveis",
        framework: "El nostre marc",
        blog: "Bloc",
        contact: "Equip",
        login: "Inicia sessió",
        knowledge: "Centre d'aprenentatge"
    },
    misc: {
        solutions: "Solucions",
        visit: "Visitar web",
        knowmore: "Vols saber-ne més?",
        help: "Digue'ns com et podem ajudar!",
        contact: "Contacta'ns",
        changelanguage: "Canviar idioma",
        made: "Fet amb",
        in: "a",
        name: "El teu nom",
        email: "Adreça de correu electrònic",
        message: "Escriure un missatge",
        sendmessage: "Enviar missatge",
        learnmore: "Veure'n més",
        expandknowledge: "Amplia els teus coneixements",
        articles: "Articles",
        books: "Llibres",
        videos: "Vídeos",
        seebehaviours: "Veure els comportaments",
        readmore: "Llegir més",
        readless: "Llegir menys",
        demo: "Veure demo",
        linkedin: "Seguir a LinkedIn",
        know: 'Saber-ne més'
    },
    connectors: {
        and: 'i'

    },
    pentabilities: {
        evidences: "Evidències de Pentabilities",
        title: "Les pentabilitats",
        description11: "Les pentabilitats són les cinc grans habilitats socials i emocionals de Pentabilities: Responsabilitat, Cooperació, Autonomia i iniciativa, Gestió emocional i Habilitats de pensament.",
        description12: "Les definicions de les pentabilitats proposades recullen els conceptes bàsics i prioritaris de cada habilitat, però amb una visió global i holística. L’objectiu és crear un llenguatge comú i concret, allunyat dels tecnicismes, la polisèmia i l’abstracció.",
        description13: "Les pentabilitats es dirigeixen cap a una finalitat tangible per garantir una comunicació orientadora i un bon feedback constructiu, imprescindibles en tot aprenentatge.",
        description14: "La connexió entre les diferents habilitats és tan rellevant, que moltes vegades són vasos comunicants. Per això, de vegades, per tal de millorar-ne una, serà necessari i més efectiu potenciar-ne una altra.",
        categories: {
            category0: {
                title: "Responsabilitat",
                description: "Habilitat que fa referència a l’auto-disciplina per assolir un objectiu, i a mantenir una actitud de treball i esforç vers les tasques a realitzar.",
                behaviours: {
                    total: "7",
                    behaviour0: "Treballa de forma constant.",
                    behaviour1: "Es manté connectat/da a l’activitat del grup.",
                    behaviour2: "Fa comentaris i/o activitats relacionats amb la tasca a realitzar.",
                    behaviour3: "Realitza les tasques de forma eficient.",
                    behaviour4: "Realitza les tasques amb cura.",
                    behaviour5: "Persevera davant les dificultats.",
                    behaviour6: "Respecta les normes.",
                }
            },
            category1: {
                title: "Cooperació",
                description: "Habilitat que inclou els comportaments que faciliten el treball en grup: accions relacionades amb la participació, l'escolta activa, la presa de decisions compartides i la resolució de conflictes.",
                behaviours: {
                    total: "7",
                    behaviour0: "Escolta els altres.",
                    behaviour1: "Incorpora el que diuen els altres.",
                    behaviour2: "Fomenta la participació dels membres del grup.",
                    behaviour3: "Participa en la presa de decisions consensuades.",
                    behaviour4: "Facilita la resolució de conflictes.",
                    behaviour5: "Reconeix les seves responsabilitats i les dels altres.",
                    behaviour6: "Ajuda els altres de forma desinteressada.",
                }
            },
            category2: {
                title: "Autonomia i iniciativa",
                description: "L’Autonomia implica treballar de forma independent, sense necessitat de supervisió i demanant ajuda només quan es requereix. La Iniciativa indica conﬁança per tirar les coses endavant: proposar idees i buscar solucions als problemes quan és necessari.",
                behaviours: {
                    total: "6",
                    behaviour0: "Aporta idees.",
                    behaviour1: "Fa preguntes quan s’encalla.",
                    behaviour2: "Pren decisions per avançar en el projecte.",
                    behaviour3: "Sap convèncer el grup de les seves propostes.",
                    behaviour4: "Treballa amb determinació.",
                    behaviour5: "Creu que pot iniciar canvis.",
                }
            },
            category3: {
                title: "Gestió emocional",
                description: "Habilitat que implica ser conscient de les pròpies emocions i les dels altres i gestionar-les adequadament.",
                behaviours: {
                    total: "6",
                    behaviour0: "Transmet alegria.",
                    behaviour1: "Es mostra tranquil/l·la en situacions de pressió.",
                    behaviour2: "Controla les emocions en situacions de conflicte.",
                    behaviour3: "Assumeix la possibilitat d’equivocar-se.",
                    behaviour4: "Accepta que els canvis que proposa no acabin tirant-se endavant.+",
                    behaviour5: "Adequa el comportament a les circumstàncies.",
                }
            },
            
            category4: {
                title: "Habilitats de pensament",
                description: "Habilitats que permeten captar informació i processar-la per construir i organitzar el coneixement, de manera que es pugui utilitzar per resoldre problemes en  diverses situacions. Impliquen indagació intel·lectual, generació d’idees i habilitats de metacognició.",
                behaviours: {
                    total: "9",
                    behaviour0: "Relaciona continguts nous amb coneixements previs.",
                    behaviour1: "Fa bones reflexions sobre els continguts treballats.",
                    behaviour2: "Fa bones reflexions sobre els seus processos personals interns.",
                    behaviour3: "Fa bones preguntes (o per entendre o per fer avançar).",
                    behaviour4: "Té idees creatives (explora camins alternatius).",
                    behaviour5: "Planteja bones estratègies de resolució de problemes.",
                    behaviour6: "Planifica i prioritza les tasques.",
                    behaviour7: "Té interès en explorar perspectives diferents.",
                    behaviour8: "Expressa eficaçment les idees (correció, precisió i estructura).",
                }
            }
        }
    },
    team: {
        title: "Equip",
        memberstitle: "Membres de l'equip",
        caterina: "Va obtenir el PhD al Departament d’Economia de la Universitat de Yale. Centra la seva recerca en el disseny i avaluació de polítiques educatives i en particular en aquelles centrades en el desenvolupament de les habilitats no cognitives. És membre del Human Capital and Economic Opportunity Group de la Universitat de Chicago, dirigit pel Premi Nobel James Heckman. Investigadora Principal de l’ERC Starting Grant 638893, que finança el desenvolupament de Pentabilities.",
        maria: "Doctora en l’àrea de Ciències de la Salut de la Universitat de Minnesota, té experiència en la recerca de ciències experimentals. Professora de ciències d’ESO i Batxillerat durant més de 14 anys, ha dissenyat i dirigit el programa Joves i Ciència de Catalunya Caixa i coordinat el programa d’excel·lència Ciències i Matemàtiques de la Fundació CELLEX (CiMs+CELLEX). També ha implementat i coordinat el programa de Batxillerat Internacional (BI) a Aula Escola Europea. Aquest programa, basat en la recerca educativa contemporània, planteja l’ensenyament amb una visió holística, en què tant els aspectes cognitius com els no-cognitius tenen la mateixa importància.",
        juan: "Màster en Innovació i Emprenedoria a ESADE i amb un postgrau en Gestió Tecnològica. Va ser director de tecnologia a Innpactia, una emprenedoria dedicada a trobar oportunitats de finançament per a companyies del tercer sector d’Amèrica Llatina. A més, va treballar en diferents projectes de tecnologia en Anheuser-Busch InBev i Accenture. Finalment, ha treballat de forma activa com a voluntari, entrenador, i president local en CISV, una organització dedicada al desenvolupament d’habilitats, actituds i coneixements que busquen inspirar i educar accions per un món més pacífic i just.",
        ece: "Amb un context multicultural que inclou des de Canadà a Sud-àfrica, Ece té dos títols de màster en Economia, especialitzant-se en l'economia del desenvolupament i ciències del comportament. Combinant la investigació rigorosa i el treball de camp aplicat, es centra en proporcionar solucions de comportament informat als diversos reptes socioeconòmics, que van des de l'accés a l'energia sostenible a l'empoderament dels joves. És consultora per al Banc Mundial com a economista i dirigeix ​​treball de camp, així com RCTs, a l'Àfrica subsahariana.",
        mikel: "Desenvolupador web i dissenyador, amb dos títols superiors de desenvolupament web i multiplataforma. Desenvolupa i implementa noves funcionalitats i s'assegura que tot funcioni sense problemes en Pentabilities.",
        jacek: "S'aferra a les experiències. Aficionat a la tecnologia amb experiències que van des de les infraestructures crítiques en entorns civils i governamentals, passant per molts anys en Fintechs i en la programació d'escala, o fent recerca sobre el desenvolupament d'eines tecnològiques pel món de l'emprenedoria. Des de fa més de 15 anys Jacek intenta combinar les necessitats de el món dels negocis amb la tecnologia més avançada. Posseeix diverses patents i és autor de nombroses publicacions tècniques i científiques.",
    },
    terms: {
        line1: "AVÍS LEGAL",
        line2: "Aquesta aplicació és la propietat de Pentabilities S.L., titular de CIF B67363291 amb domicili social a Panamà, 1, 08173 Sant Cugat del Vallès, Catalunya, Espanya; i inscrita en el Registre Mercantil de Barcelona: vol. 46.746, full 56, Entrada 1.",
        line3: "Per a qualsevol consulta o proposta, contacti'ns en admi",
        line4: "TERMES I CONDICIONS",
        line5: "Aquesta aplicació es regeix per la normativa exclusivament aplicable a Espanya, tant als usuaris nacionals i estrangers d'aquesta aplicació estar subjectes als mateixos.",
        line6: "l'accés de l'usuari a la nostra aplicació és gratis i no depèn de la lectura i la plena i acceptar expressament i sense reserves aquests TERMES I CONDICIONS & nbsp; que estan en vigor en el moment de l'accés, que li preguem que llegeixin atentament. A l'utilitzar el nostre portal, els seus continguts o serveis, l'usuari & nbsp; accepta i se sotmet a les condicions generals per a l'ús de la mateixa manera expressa. Si l'usuari no estar d'acord amb aquests termes i condicions, ell o ella ha d'abstenir d'utilitzar aquest portal i operar a través d'ell.",
        line7: "PROPIETAT INTEL·LECTUAL",
        line8: "Tots els continguts, textos, imatges i codis font són propietat de Pentabilities S.L. o tercers per la qual els drets d'explotació van ser adquirides i estan protegits pels drets de Propietat Intel·lectual i Industrial.",
        line9: "L'usuari únicament té dret a realitzar un ús privat dels mateixos, sense ànim de lucre, i necessita autorització expressa de modificar, reproduir, distribuir o explotar tals o exercir qualsevol dret pertanyent al seu titular.",
        line10: "Condicions d'accés",
        line11: "L'accés a la nostra aplicació és gratis i no requereix prèvia subscripció o registre.",
        line12: "No obstant això, els serveis específics poden estar restringides a usuaris específics i requereixen un procés de registre i / o la identificació de l'usuari utilitzant una contrasenya. En qualsevol cas, aquests serveis seran degudament identificats en l'aplicació.",
        line13: "Qualsevol enviar les seves dades personals implica l'acceptació expressa per part de l'USUARI de la nostra política de privacitat.",
        line14: "L'usuari ha d'accedir a la nostra aplicació amb el principi de la bona fe, les normes d'ordre públic i les presents condicions d'accés i ús. L'accés a la nostra aplicació és exclusivament sota la responsabilitat de l'usuari i serà responsable dels danys que puguin causar a nosaltres o tercers.",
        line15: "Tenint en compte la impossibilitat de controlar la informació, continguts i serveis continguts en altres llocs web de tercers que són accessibles a través dels enllaços a la nostra pàgina web, l'informem que nosaltres no serem responsables de cap dany de cap tipus que puguin derivar-se de l' ús d'aquestes pàgines web de tercers per part de l'usuari.",
        line16: "CONDICIONS GENERALS D'ÚS DE LA SOL·LICITUD",
        line17: "Els usuaris que accedeixen i utilitzen l'aplicació es comprometen a complir amb les següents condicions d'ús:",
        line18: "Aquesta aplicació no s'ha d'utilitzar per a fins personals o fins diferents d'aquells per als quals va ser creat.",
        line19: "Contingut que pot ser il·legal, contrari a la moral o als l'ordre públic o que és un llenguatge inadequat i / o usos de caràcter ofensiu no ha de ser publicada.",
        line20: "Els usuaris no han d'emetre informació falsa, enganyosa, comentaris o informació enganyosos o fraudulents.",
        line21: "Els usuaris han de preservar la confidencialitat de les credencials d'autenticació i la informació inclosa en la sol·licitud.",
        line22: "Els usuaris no han de suplantar la identitat d'altres usuaris.",
        line23: "Els usuaris no han d'utilitzar tècniques per alterar, desmuntar o eludir les limitacions tècniques dels mecanismes de productes o de seguretat, o permetre que un tercer ho faci en nom seu.",
        line24: "Els usuaris no han d'usar o obtenir els serveis, productes i continguts oferts a la present sol·licitud, per procediments diferents als estipulats en les presents condicions d'ús.",
        line25: "Els usuaris no han de portar a terme qualsevol tipus d'acció de la nostra pàgina web que causa una excessiva sobrecàrrega de funcionament als nostres sistemes informàtics, així com la introducció de virus, o instal·lació de robots, o programari que alteri el normal funcionament de la nostra aplicació, o poden en última instància, causar danys als nostres sistemes informàtics.",
        line26: "Pentabilities S.L. es reserva el dret unilateral de cancel·lar sense previ avís qualsevol usuari que l'organització entén que ha violat les condicions que regeixen l'ús de la nostra aplicació, sense que l'usuari tingui dret a cap tipus de reclamació per aquest tipus d'accions. Així mateix es reserva el dret d'exercir les accions legals pertinents contra aquells que violen les presents condicions generals d'ús, acceptant l'USUARI que la no iniciació d'aquestes accions no constitueix una renúncia formal, romanent en vigor fins que els terminis legals de prescripció de les infraccions.",
        line27: "De la mateixa manera, Pentabilities S.L. Es reserva el dret a modificar la presentació i configuració de la nostra aplicació, ampliar o reduir serveis, i fins i tot suprimir-la, o la càrrega de l'aplicació i / o serveis i el contingut proporcionat, en qualsevol moment i tot això de forma unilateral i sense previ avís.",
        line28: "Limitació de responsabilitat",
        line29: "No assumim cap responsabilitat derivada, o que pogués derivar, de la informació, continguts, avaluacions, opinions i comentaris que són publicats pels usuaris. De la mateixa manera, no serem responsable de la pèrdua, dany directe o indirecte de qualsevol naturalesa derivats de l'accés, ús o mal ús de l'aplicació atribuïbles a l'entitat contractant i / o als usuaris autoritzats per ell.",
        line30: "Condicions d'ús per a les entitats contractants",
        line31: "L'aplicació permet la creació de diferents perfils d'usuari que determinen el grau d'accés a la informació que s'introdueix posteriorment en l'aplicació.",
        line32: "El registre d'usuaris i estudiants per l'administrador o altres usuaris de l'entitat.",
        line33: "Les entitats que contracte el servei tindrà accés a l'aplicació a través de codis d'accés i serà l'encarregat de donar accés als diferents usuaris, així com limitar el grau d'aquest accés a la informació continguda en la sol·licitud. En el cas de la identificació dels usuaris amb les seves dades personals, aquests seran utilitzades exclusivament per a fins d'identificació.",
        line34: "L'usuari administrador de l'entitat contractant serà l'únic usuari amb la capacitat per determinar els perfils d'accés establerts, de manera que, en qualsevol cas, serà l'entitat contractant i, si escau, l'Administrador, el responsable de determinar correctament els perfils i limitacions L'accés d'usuari. En qualsevol cas, Pentabilities S.L. no es fa responsable d'aquest fet.",
        line35: "L'entitat contractant serà l'únic responsable de proporcionar la informació necessària a les persones les dades personals s'utilitzen, quant als objectius i la forma en que les seves dades personals seran tractades en aquesta sol·licitud i, si escau, serà responsable d'obtenir el seu consentiment quan això és necessari.",
        line36: "L'avaluació dels estudiants.",
        line37: "L'aplicació és una eina la finalitat és l'avaluació dels aspectes socio-emocionals dels estudiants, de manera que els usuaris autoritzats puguin avaluar els diferents paràmetres establerts, així com afegir altres avaluacions que considerin pertinents. Les avaluacions individuals i altra informació recollida servirà de base per a les estadístiques i generar una visió general de l'evolució dels diferents aspectes de l'estudiant, de manera que cal que les avaluacions realitzades són veritables i les observacions registrades en el sistema són rellevants per complir aquest propòsit. En qualsevol cas, els usuaris han d'usar un llenguatge apropiat. Les avaluacions poden ser visibles als usuaris autoritzats, tenint en compte les limitacions d'accés establertes per l'entitat contractant.",
        line38: "CONDICIONS PER AL TRACTAMENT DE DADES PERSONALS",
        line39: "Quan Pentabilities S.L. processa les dades personals dels empleats, col·laboradors i estudiants de l'entitat contractant, aquesta entitat actuarà com la persona a càrrec de el processament de les dades personals d'aquestes parts interessades, mentre que Pentabilities SL, actuarà com l'Administrador de tractament, ja que la disposició de la els serveis actuals implica l'accés a les dades personals.",
        line40: "OBLIGACIONS Pentabilities S.L. PROCESSADOR com dades.",
        line41: "El processador de dades i tot el seu personal estan compromesos amb el compliment de totes les obligacions següents per tal de processar l'usuari o les dades personals d'altres persones en nom de l'tractament de les dades:",
        line42: "Utilitzar les dades personals processats o recollit per la seva inclusió amb l'única finalitat d'aquest compromís. En cap cas es poden utilitzar les dades per als seus propis fins.",
        line43: "Processar la conservació de les dades amb les instruccions de l'controlador de dades. informar immediatament a l'responsable de l'tractament en el cas que les dades que estimi processador qualsevol de les instruccions infringeixi cap llei de protecció de dades.",
        line44: "Quan així ho exigeixi la llei de protecció de dades, per mantenir un registre de les categories de processament de les activitats dutes a terme en el compte de el controlador que conté els requisits de la llei de protecció de dades.",
        line45: "No revelen les dades a terceres persones a menys que aquest últim està expressament autoritzat pel controlador de dades en un escenari legalment admissible.",
        line46: "No subcontractar qualsevol dels serveis que entrin dins de l'objecte d'aquest contracte que pot implicar el tractament de dades personals, amb excepció dels serveis auxiliars que puguin ser necessaris per a la correcta execució dels serveis.",
        line47: "Si fos necessari subcontractar qualsevol activitat de processament, el controlador ha de ser informat per escrit prèviament amb preavís d'1 mes, indicant el processament pot ser subcontractat i clara i inequívoca identificació de l'subcontractista i les seves dades de contacte. La subcontractació es pot dur a terme si el controlador no s'oposa dins el termini indicat.",
        line48: "A l'acceptar els presents Termes i Condicions, el tractament de les dades permet i autoritza la subcontractació de l'aplicació de serveis d'allotjament a Google Ireland Limited o Google LCC.",
        line49: "En el cas que la informació emmagatzemada per Google es troba fora de l'Àrea Econòmica Europea, les parts han signat les clàusules contractuals tipus aprovades per la Comissió Europea. A més, Google compleix amb la UE - Privadesa marcs Shield.",
        line50: "Complir amb el seu deure de confidencialitat respecte a les dades personals que ha tingut accés a l'empara d'aquesta, incloent la posterior a la terminació del seu propòsit.",
        line51: "Comproveu que les persones autoritzades per tractar les dades personals es comprometen, de manera expressa i per escrit, a respectar la seva confidencialitat ia complir amb les mesures de seguretat pertinents, que han de ser degudament informat de.",
        line52: "Quan els afectats exercir els seus drets d'accés, rectificació, cancel·lació, oposició i altres drets continguts en la llei de protecció de dades vis-a-vis el processador de dades, aquest ha d'informar immediatament a el controlador i sota cap circumstància, com a molt tard el següent dia hàbil recepció de la sol·licitud, juntament amb tota la informació rellevant per al compliment de la sol·licitud.",
        line53: "En el cas que el processador de dades de coneixement de qualsevol violació de la seguretat de dades que constitueix un risc per als drets i llibertats de les persones físiques, ho ha de notificar a l'responsable de l'tractament sense demora indeguda i dins d'un termini de 72 hores , juntament amb tota la informació rellevant per documentar i informar sobre l'incident.",
        line54: "Proporcionar el controlador tota la informació necessària per demostrar el compliment de les seves obligacions i per permetre la realització d'auditories o inspeccions dutes a terme pel controlador o l'altre auditor autoritzat per ella.",
        line55: "Aplicar les mesures de seguretat necessàries d'acord amb la naturalesa, l'abast, el context i els propòsits de l'processament per tal de garantir la confidencialitat, integritat, disponibilitat i capacitat de recuperació en curs dels sistemes i serveis de processament i verificar, avaluar i valorar de forma regular l'eficàcia de les mesures tècniques i organitzatives introduïdes per garantir la seguretat de l'tractament.",
        line56: "A l'acabament d'aquest contracte, el processador de dades, d'acord amb les instruccions de l'responsable de l'tractament, ha d'eliminar o retornar totes les dades personals en el seu poder emmagatzemada en paper o digitals còpies o, si s'escau, la transferència a altres processos designats pel Controlador de dades. Qualsevol de les dues opcions vol dir que el processador no tindrà en el seu poder les dades personals en poder de titular de l'tractament llevat que el processador ha de emmagatzemar-lo, degudament bloquejades, mentre que poden sorgeixin passius de la prestació de servei.",
        line57: "OBLIGACIONS DEL dades de controlador",
        line58: "El controlador de dades haurà de:",
        line59: "Lliurar a l'processador de les dades necessàries per a prestar el servei com es descriu en el present document.",
        line60: "Dur a terme les consultes prèvies pertinents.",
        line61: "Supervisar el processament, incloent la realització d'inspeccions i auditories.",
        line62: "DECLARACIÓ DE PROCESSADOR RESPONSABLE DADES PROCESSAMENT per les dades",
        line63: "Afirma el processador de dades que compleix els requisits de processament amb els de el present Reglament i garanteix la protecció dels drets de la part interessada, en particular:",
        line64: "Que en el curs de la seva activitat que compleix amb les obligacions i els principis continguts en el Reglament General de Protecció de Dades (UE 2016/679).",
        line65: "Que manté un registre de les dades de processament d'activitats realitzades sota la seva responsabilitat.",
        line66: "Que ha adoptat les mesures de seguretat necessàries d'acord amb la naturalesa, l'abast, el context i els propòsits de l'processament per tal de garantir la confidencialitat i seguretat de les dades tractades en la paperassa i els mitjans digitals.",
        line67: "Es compromet a complir amb el deure de confidencialitat, incloent la posterior a la terminació de la relació.",
        line68: "Que es porta a terme de forma proactiva la verificació periòdica de l'acompliment de les obligacions i les mesures de seguretat tècniques i organitzatives.",
        line69: "POLÍTICA DE PRIVACITAT",
        line70: "La confidencialitat i la seguretat són valors fonamentals de Pentabilities S.L. i per tant ens comprometem a garantir la privacitat de l'usuari en tot moment i no recollir tota la informació innecessària. A continuació oferim tota la informació necessària pel que fa a la nostra política de privacitat en relació amb les dades personals recollides, explicant:",
        line71: "Qui és el responsable de les seves dades.",
        line72: "Què són les dades que recollim.",
        line73: "El propòsit per al qual es reuneixen les dades sol·licitades.",
        line74: "Quina és la legitimació per al seu processament.",
        line75: "La longitud de temps que emmagatzemar les dades.",
        line76: "Qui seves dades estan assignats.",
        line77: "Quins són els seus drets i com poden exercir-los.",
        line78: "CONTROLADOR DE DADES:",
        line79: "El controlador de les seves dades és Pentabilities S.L., titular de l'CIF & nbsp; & Nbsp B67363291; la seu social es trobi a Panamà, 1, 08173 Sant Cugat del Vallès, Catalunya, Espanya.",
        line80: "DADES recol·lectats:",
        line81: "Depenent de la finalitat per a la qual es processa la seva informació, que necessitem per processar la següent informació:",
        line82: "Dades d'identitat, adreça, i informació de contacte per al processament de les seves sol·licituds.",
        line83: "PROPÒSITS, legitimació i emmagatzematge dels TRACTAMENT DE DADES.",
        line84: "Depenent de la forma d'interactuar amb la nostra aplicació, tractarem les seves dades per als següents propòsits:",
        line85: "entitat de registre",
        line86: "Propòsit: El procés de registre per esdevenir una entitat registrada per gestionar els serveis sol·licitats. Amb el temps, enviar-li informació comercial sobre els nostres productes i serveis, inclosos els mitjans electrònics també.",
        line87: "Legitimació: Executar i complir les obligacions de la relació contractual. El nostre interès legítim que li enviï informació comercial en la seva qualitat de client / usuari registrat.",
        line88: "Conservació: Durant el temps en què l'entitat s'ha registrat en el servei.",
        line89: "Ús de Cookies:",
        line90: "Aquesta aplicació pot utilitzar cookies, però en cap cas aquestes tractar les dades personals, els hàbits de navegació d'l'usuari captura o s'utilitzen amb fins publicitaris, i per tant estan exempts de compliment de les obligacions establertes en l'article 22 de la Llei de serveis de la Societat de la Informació. No obstant això, l'informem que podem utilitzar cookies que ens permeten la comunicació entre l'equip de l'usuari i la xarxa, proporcionem un servei expressament sol·licitat per l'usuari, autenticació o identificació de l'usuari (única sessió), la seguretat d'usuari Give, sessions de reproductor de mitjans, sessions per equilibrar la càrrega, la personalització de la interfície d'usuari i els complements (plug-in) i l'intercanvi de continguts socials. En qualsevol cas, l'usuari pot, si ho desitja, desactivar i / o eliminar aquestes cookies seguint les instruccions del seu navegador d'Internet.",
        line91: "Obligació de proporcionar les seves dades personals i les conseqüències de no fer-ho.",
        line92: "El subministrament de les dades personals requereix una edat mínima de 14 anys, o, si la capacitat legal aplicable, suficient per entrar en un contracte.",
        line93: "Proporcionar informació personal no és obligatòria en qualsevol cas, per tant, les dades d'identificació poden ser substituïts per codis o altres conceptes.",
        line94: "Destinataris de les dades",
        line95: "Les seves dades són confidencials i no seran transferits a tercers, d'acord amb les Condicions de Processament de Dades (vegeu la secció D). No obstant això, podem transferir les seves dades personals en el cas de les obligacions legals o quan el servei implica la necessitat d'una relació contractual amb els proveïdors de processadors de dades que no va a tractar dades personals per als seus propis fins.",
        line96: "DRETS A la Protecció de dades personals.",
        line97: "Vostè pot exercir els següents drets:",
        line98: "Sol·licitud d'accés a les seves dades personals o la seva rectificació quan inexacta.",
        line99: "Sol·licitar la seva eliminació quan, entre altres raons, que ja no és necessari per al propòsit per al qual van ser governats són.",
        line100: "Sol·licitar la limitació de tractament en determinades circumstàncies.",
        line101: "Sol·licitud oposició a el tractament de les seves dades per la seva situació particular.",
        line102: "sol·licitud de portabilitat de les dades quan es proporciona per la llei.",
        line103: "Altres drets reconeguts per la llei aplicable.",
        line104: "On i com exercir els seus drets: Per escrit a el controlador de dades Pentabilities S.L. amb la referència Dades Personals '' o similar i que indica el dret a ser exercida i pel que fa al fet que el processament a l'adreça postal o electrònic (administra",
        line105: "En el cas de qualsevol conflicte amb l'empresa pel que fa a el tractament de les seves dades pot presentar una reclamació davant l'Autoritat de Protecció de Dades (www.agpd.es).",
        line106: "SEGURETAT DE LES SEVES DADES PERSONALS",
        line107: "Per tal de salvaguardar la seguretat de les seves dades personals, li informem que hem adoptat totes les mesures necessàries d'índole tècnica i organitzatives necessàries que garanteixin la seguretat de les seves dades personals contra l'alteració, pèrdua i tractament no autoritzat i l'accés.",
        line108: "ACTUALITZACIÓ DE DADES",
        line109: "És important que ens informi sempre que hagi hagut alguna modificació perquè puguem actualitzar les seves dades personals; en cas contrari no podem ser responsables.",
        line110: "Nosaltres no serem responsables de la política de privacitat respecte a les dades personals que pugui subministrar a tercers a través dels enllaços a la nostra pàgina web.",
        line111: "Podem modificar aquestes condicions de privacitat per adaptar-les a les modificacions realitzades a la nostra pàgina web, juntament amb les modificacions legals o jurisprudencials respecte a les dades personals que poden aparèixer, i per tant se li demana que llegeixi aquesta política cada vegada que proporcioni les seves dades a través d'aquesta aplicació.",
        line112: "RESPONSABILITATS",
        line113: "A l'col·locar aquesta aplicació a disposició a l'usuari, volem oferir un servei de qualitat aplicant la màxima diligència en la prestació dels mateixos i als mitjans tecnològics utilitzats. No obstant això, nosaltres no serem responsables de la presència de virus o altres elements que puguin danyar el sistema informàtic de l'usuari.",
        line114: "Pentabilities S.L. no garanteix que la disponibilitat de l'servei és continu i ininterromput, a causa de circumstàncies causades per problemes a la xarxa Internet, avaries en els dispositius o altres circumstàncies imprevisibles de computació, de manera que l'usuari es compromet a donar suport a aquestes circumstàncies dins de límits raonables, per la qual cosa expressament Renúncia afirmen que el nom de el client cap responsabilitat per possibles fallades, errors i ús de el servei.",
        line115: "L'usuari assumeix tota la responsabilitat derivada de l'ús de la nostra aplicació, sent l'únic responsable de tot efecte directe o indirecte que pugui derivar-se de l'aplicació, incloent, però no limitat a, qualsevol resultat econòmic, tècnic i / o jurídic advers, així com la decepció de les expectatives generades pel nostre portal, l'usuari està obligat a mantenir indemne _____________ per reclamacions derivades, directament o indirectament de tals fets.",
        line116: "_____________ no garanteix l'exactitud, veracitat i vigència dels continguts d'aquesta sol·licitud, ja sigui pròpia, de tercers, o vinculat a altres llocs web, sent eximir totalment de qualsevol responsabilitat derivada de la utilització dels mateixos.",
        line117: "_____________ queda exempta de qualsevol responsabilitat derivada de qualsevol reclamació, inclòs el pagament d'honoraris d'advocats i reclamacions originades per tercers per l'incompliment per part de l'USUARI de les nostres condicions d'ús, accés i política de privacitat, o qualsevol altra reclamació per l'incompliment amb la legislació vigent.",
        line118: "L'usuari reconeix la comprensió de tota la informació relativa a les condicions d'ús del nostre portal, i reconeix que són suficients per a l'exclusió de l'error en ells i, per tant, les accepta íntegrament i de forma expressa.",
        line119: "L'USUARI és plenament conscient que la mera navegació a través d'aquesta aplicació, així com l'ús dels seus serveis, implica l'acceptació d'aquestes condicions d'ús.",
        line120: "Tot el relacionat amb la nostra aplicació, es regeix exclusivament per les lleis espanyoles. En el cas de qualsevol tipus de discrepància o diferència entre les parts en relació amb la interpretació i contingut d'aquesta sol·licitud, totes les parts es sotmeten, amb renúncia expressa a qualsevol altre fur, als Jutjats i Tribunals de Barcelona",
        line121: "Validesa de les condicions generals de l'aplicació",
        line122: "Aquests termes i condicions han estat modificades amb data 2020.01.10, però en qualsevol moment podem procedir a modificar-los. Si us plau, comprovi la data d'emissió cada vegada que es connecti a la nostra sol·licitud i tindrà la seguretat que no hi ha hagut cap canvi que l'afecta.",
    },
    infographics: {
        key: {
            emotional: "Emocional",
            cognitive: "Cognitiu",
            social: "Social",
            key1: "Habilitats claus que determinen",
            key2: "la capacitat per superar",
            key3: "reptes",
        },
        doodle: {
            concept11: "Socioemocional",
            concept12: "habilitats",
            concept2: "Caràcter",
            concept31: "Treballant",
            concept32: "Memòria",
            concept4: "Autoeficàcia",
            concept51: "K12 SEL",
            concept52: "Estàndards",
            concept6: "CASEL",
            concept7: "OCDE",
            concept81: "Hàbits",
            concept82: "de la ment",
            concept91: "aprenentatge de l'IB",
            concept92: "Perfil",
            concept101: "Segle 21",
            concept102: "Habilitats",
            concept111: "Big Five",
            concept112: "Trets de personalitat",
            concept121: "Tots els volem, però també",
            concept122: "ens aclaparen",

        },
        cycle: {
            step11: "Definir un",
            step12: "objectiu",
            step21: "projectes de disseny",
            step22: "i activitats",
            step31: "observar i",
            step32: "registrar evidències",
            step4: "Anàlisi",
            step51: "Feedback per prendre",
            step52: "consciència",
        },
        header1: {
            link: "assets/img/header/HeaderPics-01.png",
        },
        header2: {
            link: "assets/img/header/HeaderPics-08.png",
            description: "Va mantenir la calma tot i que s'acostava l'entrega",
        },
        header3: {
            link: "assets/img/header/HeaderPics-09.png",
            description1: "Has estat millorant molt en",
            description2: "aportar idees",
        },
        header4: {
            link: "assets/img/header/HeaderPics-10.png",
            description1: "Hem de repensar com estimular",
            description2: "l'Autonomia i iniciativa!",
        }
    },
    landing: {
        title: 'Coneix millor Pentabilities',
        video1title: 'Per què les habilitats socioemocionals?',
        video1key1: "Les habilitats socioemocionals són clau per l’èxit personal, acadèmic i professional al llarg de la vida.",
        video1key2: "Les mètriques d’assoliment acadèmic convencional, com les notes d’exàmens, no avaluen les habilitats socioemocionals.",
        video1key3: 'Les habilitats socioemocionals són intangibles i difícils de mesurar, però Pentabilites ofereix eines per fer-ho.',
        video1description: '',
        video2title: 'Pentabilitzant l’avaluació de les habilitats socioemocionals',
        video2key1: "Pentabilities es basa en cinc trets bàsics de la personalitat i els seus comportaments associats, que s’utilitzen com a expressions i evidències d’aquestes habilitats.",
        video2key2: "Pentabilites proposa l’avaluació formativa per desenvolupar les habilitats socioemocionals: recollir els comportaments observats durant el procés i donar-ne feedback.",
        video2key3: 'Les avaluacions de les habilitats i comportaments formen la base de les anàlisis a les quals es pot accedir a través de la plataforma web i utilitzar-les en les sessions de feedback.',
        video2key4: ' ',
        video2description: '',
        video3title: 'Com funciona el Sistema Pentabilities',
        video3key1: "Com preparar-se per a utilitzar l’aplicació Pentabilities.",
        video3key2: "Les dades recollides tenen un doble ús: com a feedback a les estratègies que utilitzen els professors a classe i per a oferir un feedback als alumnes basat en evidències.",
        video3key3: "Com a guia d’ensenyament i avaluació de les habilitats socioemocionals.",
        video3description: '',
        alert1: "El curs complet de Pentabilities es compon de 12 mòduls. Aquí trobareu els primers 3 vídeos introductoris. Si vols accedir a la resta de mòduls:",
        alert2: "Ja tens un compte de Pentabilities? Només",
        alert3: "fes click aquí",
        alert4: "Ets nou a Pentabilities?",
        alert5: "Fes click aquí",
        alert6: "per obtenir més informació sobre la membresía."
    },
    landing2: {
        button1: "Vull saber-ne més",
        button2: "Uneix-te al projecte",
        button3: "Apunta-t’hi",
        button4: 'Preinscripció',
        button5: 'Descarrega',
        alert: "Vine a la sessió informativa del 28 de juny a les 16.00h a la Universitat Pompeu Fabra de Barcelona ",
        alert2: "Descarrega't aquí el full de sol·licitud per a fer la inscripció. Una vegada completat, envia'l a info@pentabilities.com",
        title1: 'Encén',
        title2: 'habilitats de vida',
        subtitle1: "Programa gratuït per integrar el treball de les habilitats socials i personals a l'aula durant el curs 2022-2023",
        subtitle2: "Projecte desenvolupat sota la direcció científica i acadèmica de l'Institut d'Economia Política i Governança (IPEG) de la Universitat Pompeu Fabra (UPF) de Barcelona i implementat per la Fundació Bofill",
        section1_1: "Encara hi ets a temps! Si ets un professor de secundària o dirigeixes un institut i vols integrar l'educació socioemocional a l'aula per a la",
        section1_2: "millora de l'èxit escolar,",
        section1_3: "fes la teva preinscripció aquí i et contactarem de seguida.",
        section2_1: "5 raons per sumart'hi",
        section2_2: 'Èxit escolar',
        section2_3: "Treballar les habilitats socials i personals (‘soft skills’) implica una millora de les ràtios d’absentisme, resultats acadèmics i participació a l’aula, segons es desprèn d’una àmplia recerca internacional en aquesta àrea.",
        section2_4: "Efectivitat",
        section2_5: "Són en gran mesura aquestes habilitats les que fan possible un aprenentatge significatiu i no al revés. D’acord amb diversos estudis de l’Education Endowment Foundation (EFF), fer reflexionar sobre el propi aprenentatge pot suposar un progrés de set mesos.",
        section2_6: "Oportunitats",
        section2_7: "Els programes d’educació social i emocional són especialment beneficiosos per als alumnes d’un menor nivell socioeconòmic i amb baix rendiment.",
        section2_8: "Dia a dia",
        section2_9: "La millora de les competències transversals és més probable quan l’educació socioemocional s’incorpora a la pràctica educativa rutinària i està recolzada pel desenvolupament professional i la formació del personal.",
        section2_10: "Inclusió social",
        section2_11: "El domini efectiu de les habilitats socials i emocionals recolza l’assoliment de resultats positius a la vida, incloent una bona salut, el benestar social i educatiu i l’ocupació professional mentre evita dificultats com addiccions, malalties mentals i conductes criminals.",
        section3_1: "Students who, because of their careers in the regular classroom, their future seemed very limited, now we see that the impact is real and we see that what we are doing leads them to lead work teams and take responsibility.",
        section3_2: "Pere Mas, professor del Fab Lab del Liceu Politècnic",
        section4_1: "Ara hem entès que les habilitats socials i personals són importants, es poden mesurar i ens poden ajudar a dotar d’eines als nostres joves per ser allò que volen ser i esdevenir la seva millor versió",
        section4_2: "James Heckman, Premi Nobel d’Economia",
        section5_1: "Què diuen els professors que han fet servir Pentabilities a la seva aula?",
        section5_2: "No només té un impacte dins l’aula, sinó també en la seva vida personal",
        section5_3: "(Pere Mas, Liceu Politècnic)",
        section5_4: "Treballar-les condueix a petits progressos, a reflexionar, a reconèixer on hi ha les dificultats. I, de rebot, tot això afecta al seu entorn.",
        section5_5: "(Laia Creixells, educadora social a l’Escola Prim)",
        title3: "Com funciona Pentabilities a l'aula?",
        menu1: "Qui som",
        menu2: "El projecte",
        menu3: "Característiques",
        menu4: "Contacte",
        menu5: 'Inici',
        join1: {
            title: 'Disseny de projectes i activitats',
            short: "Promou una dinàmica a l'aula que faci aflorar les habilitats que vols treballar.",
            description1: "Per començar, promou una dinàmica a l’aula que faci aflorar les habilitats que vols observar i treballar. O sigui, és l’acció a l’aula allò que facilita la observació de les habilitats que ens interessa observar i treballar, i no al revés.",
            description2: "Per això cal definir com es desenvoluparà la classe –parlo jo i els alumnes escolten, llanço la pregunta i ells discuteixen, treballen sols o per parelles, etcètera–. També cal definir els comportaments i les actituds que vull observar."
        },
        join2: {
            title: 'Observa i registra els comportaments',
            short: 'Professors i alumnes valoren les conductes observades quan els alumnes interactuen.',
            description1: "En segon lloc, els professors i també els mateixos alumnes valoren les conductes observades durant la interacció. Els moments d’auto i coavaluació els determina el professor.",
            description2: "Mentre els alumnes pensen, planifiquen, busquen o debaten, fixa’t en les habilitats que t’has proposat observar i interactua amb ells, si ho creus necessari, preguntant-los o guiant-los. És així com emergeixen les conductes que, d’altra manera, no s’expressen. Com que els comportaments es registren a nivell individual, no té sentit observar tots els estudiants en una sola sessió ni avaluar els 35 comportaments de la llista. Planifica quins alumnes observaràs en quines sessions i només registra els comportaments que has pogut observar.",
            description3: "Però Pentabilities no és una eina tant per avaluar com per a desenvolupar. I aquest treball és molt més efectiu si els alumnes també s’auto i coavaluen perquè prenen consciència d’ells mateixos i interioritzen el significat i rellevància de les habilitats que treballen."

        },
        join3: {
            title: 'Anàlisi',
            short: 'Visualitza les dades registrades en informes multidimensionals: per persones, per grups, per habilitats i més.',
            description: "Les observacions registrades de professors, alumnes i companys resulten en uns informes multidimensionals que agreguen les dades per persones, per grups, per habilitats i més.  Aquests mostren les evidències recollides de manera que cada estudiant i docent en pot extreure fàcilment un significat i unes conclusions que, al seu torn, serveixen per iniciar una reflexió i una conversa."
        },
        join4: {
            title: "Reflexió i conversa",
            short: "El feedback facilita posar en comú punts forts i punts de millora per a prendre consciència.",
            description: "La reflexió s’inicia a partir del feedback del professor a l’alumne, però sobretot a partir del sentit que extreu el propi alumne de l’anàlisi. L’ideal és que el propi estudiant miri l’informe i es plantegi què el sorprèn, quins punts li faciliten establir dinàmiques positives i quins punts interfereixen en el seu desenvolupament dins d’un context determinat. El professor escolta o llegeix les reflexions personals i, tot seguit, fomenta una conversa entre els membres de l’equip."
        },
        join5: {
            title: "Estableix objectius",
            short: "Partint del llistat de comportaments, crea objectius específics i compartits.",
            description: "Els objectius són fruit de la reflexió i la conversa amb l’equip. Així, un cop els alumnes han pres consciència dels punts forts i febles, professors i alumnes defineixen les metes. En aquest punt, el mestre també pot valorar quina dinàmica de classe és més favorable per aconseguir determinats objectius individuals o col·lectius i, en definitiva, per millorar les habilitats socioemocionals dels estudiants i així assolir un aprenentatge més significatiu."
        }
    },
    whats: {
        title: "El projecte ‘Pentabilities, encén habilitats de vida’",

        text1: "Encén habilitats de vida",
        text2: "és un programa de millora de les habilitats socials i emocionals (‘soft skills’) a les aules de secundària amb una eina validada i una metodologia científica:",
        text3: "Pentabilitites.",
        text4: "Alhora, el projecte vol investigar a gran escala els efectes de treballar les competències no cognitives sobre el rendiment acadèmic i el benestar dels estudiants de secundària, així com analitzar l’elaboració de polítiques públiques en base a l’evidència recollida.",
        text5: "Què implica sumar-s’hi?",
        text6: "Obtenir accés a una",
        text7: "app àgil",
        text8: ", dissenyada amb un llenguatge",
        text9: "comprensible i adaptable",
        text10: "a qualsevol context: Pentabilities és una eina per convertir les evidències en palanca per a la millora de les ‘soft skills’.",
        text11: "Accés a una plataforma per agregar i interpretar les valoracions d’alumnes, companys i professors amb",
        text12: "informes multidimensionals",
        text13: "com a eina de conversa i reflexió per a guiar el desenvolupament de les habilitats socioemocionals.",
        text14: "Desenvolupament professional del professorat.",
        text15: "Formació d’un grup de dos a sis professors per implementar Pentabilities en un dels seus cursos de primer o tercer d’ESO en espais on els alumnes interactuen entre ells. ",
        text16: "Assessorament",
        text17: ", suport tècnic i acompanyament durant tot el curs. L’equip posarà a disposició dels instituts el material necessari per a la implementació de l’eina.",
        text18: "Focus groups",
        text19: "trimestrals amb altres professors i centres participants.",
        text20: "Fablab del Liceu Politècnic de Sant Cugat del Vallès."
    },
    characteristics: {
        title: "Característiques del projecte",
        text1: "Pentabilities s’estructura en base a 35 comportaments socials i personals reunits en cinc grans grups perquè docents i estudiants puguin identificar, valorar, modelar i guiar les habilitats socioemocionals.  ",
        text2: "Enfocament 360º:",
        text3: "A les activitats de classe, professors, alumnes i companys aprenen a identificar els comportaments i les actituds, que al llarg de les sessions són anotats i utilitzats com a evidències per a la reflexió, la interiorització i el treball socioemocional.",
        text4: "Agilitat:",
        text5: "L’aplicació permet enregistrar a temps real les diferents conductes de manera ràpida i sistemàtica.",
        text6: "Feedback:",
        text7: "Pentabilities permet visualitzar els resultats en el temps en uns informes clars i precisos. L’objectiu d’aquest feedback és identificar els punts forts i de millora per tal de proposar fites concretes ajustades a cada cas, sempre afavorint la reflexió i el diàleg amb un mateix i els altres observadors.",
        text8: "Transversal:",
        text9: "El treball socioemocional s’integra als aprenentatges curriculars, oferint un ventall d’espais i situacions perquè les habilitats emergeixin amb la finalitat de guiar el seu desenvolupament.",
        text10: "Contrastable:",
        text11: "Descobreix les estratègies i dinàmiques que funcionen millor i també aquelles que convé canviar en base a l’evidència."
    },
    ignite: {
        annoucement: "Oberta la preinscripció per al nostre programa pilot per integrar el desenvolupament de les habilitats socioemocionals a la teva aula",
        caterina: "Directora de l’avaluació d’impacte del pilot en estreta col·laboració amb entitats internacionals independents d’excel·lència en recerca com J-PAL, The Abdul Latif Jameel Poverty Action Lab, i CEMFI. Doctorada al Departament d’Economia de la Universitat de Yale. Investigadora ICREA de l’Institute for Political Economy and Governance (IPEG) de la Universitat Pompeu Fabra, centra la seva recerca en el disseny i avaluació de polítiques educatives i, en particular, en aquelles centrades en el desenvolupament de les habilitats socioemocionals. És membre del Human Capital and Economic Opportunity Group de la Universitat de Chicago, dirigit pel Premi Nobel James Heckman i investigadora Principal de l’ERC Starting Grant 638893, que ha finançat el desenvolupament de Pentabilities.",
        maria: "Directora pedagògica del projecte. Doctora en Medicina Veterinària per la Universitat de Minnesota i després investigadora al CReSA-IRTA. Professora de ciències d’ESO i Batxillerat durant més de 14 anys, ha dissenyat i dirigit el programa Joves i Ciència de Catalunya Caixa i coordinat el programa d’excel·lència Ciències i Matemàtiques de la Fundació CELLEX (CiMs+CELLEX). També ha implementat i coordinat el programa de Batxillerat Internacional (BI) a Aula Escola Europea. Actualment fa recerca educativa a l'IPEG desenvolupant i implementant indicadors per a l’avaluació i desenvolupament d’habilitats socioemocionals. "
    },
    faq: {
        title: "Preguntes freqüents",
        question1: "Què significa ‘Pentabilities. Encén habilitats de vida’? ",
        answer1: "Les habilitats socials i personals (popularment conegudes com a ‘soft skills’) cada dia es mostren més determinants en la trajectòria vital i l’èxit escolar dels infants i adolescents: són, per a nosaltres, habilitats de vida. Per això hem dissenyat una eina que permet identificar, valorar i modelar aquestes competències basada en l’estratègia número 1 de l’aprenentatge: el feedback per a impulsar una millora. La nostra metodologia parteix de la observació de trenta-cinc comportaments que mostren les diferents dimensions de les cinc grans habilitats que cobreixen tot el rang de la personalitat basades en el marc més acceptat de la psicologia, els ‘Big Five’, i que és també amb el que treballa l’Organització per a la Cooperació i el Desenvolupament Econòmic (OCDE). D’aquí ve el nom de Pentabilities.",
        question2: "Però...es poden educar aquestes habilitats?",
        answer2: "Històricament, s’han concebut les habilitats socioemocionals com una característica intrínseca i inamovible de la persona. Per això, fins fa pocs anys no s’han inclòs als programes educatius. Però després d’anys d’investigació, hem après que fer front a la incertesa, generar una actitud d’esforç o escoltar els altres són competències que es poden aprendre. I, a més, fer-ho implica donar eines per a superar desigualtats socials. D’acord amb diversos psicòlegs com Brent Roberts, aquests trets són modulats sobretot per l’ambient —la família, l’escola, els companys i altres factors— i, sobretot durant l’adolescència i la joventut, són més mal·leables que les habilitats cognitives. De fet, Roberts inclou a la seva recerca diversos programes que han resultat efectius contra el bullying a les escoles. Aquests exemples demostren que els comportaments mantinguts en el temps afecten els trets de caràcter. D’aquesta manera, les habilitats socioemocionals es poden educar i s’han d’incloure entre els objectius d’aprenentatge.",
        question3: "Quin és el principal benefici d’implementar Pentabilities a la dinàmica diària de classe?",
        answer3: "Millorar l’eficiència de l’aprenentatge dels alumnes és un dels principals beneficis d’introduir Pentabilities a l’aula. El programa suposa una guia i un acompanyament per a treballar les competències socioemocionals que revertirà de manera notable en l’assimilació de coneixements dels estudiants: són en gran mesura aquestes habilitats les que fan possible un aprenentatge significatiu i no al revés. De fet, segons un estudi de l’Education Endowment Foundation, fer reflexionar sobre el propi aprenentatge pot suposar un progrés addicional de set mesos. Tot i així, potenciar i fer créixer les habilitats de vida dels alumnes té una altra gran avantatge: donar oportunitats reals als alumnes d’entorns desfavorits. Mentre que el desenvolupament de les habilitats socioemocionals aporta un benefici d’un o dos punts als alumnes de major nivell socioeconòmic, per als alumnes d’un menor nivell els pot aportar un benefici de fins a sis punts.",
        question4: "Com a docent, quina càrrega de feina em comportarà implementar el programa o, com a director/a, quins recursos hi hauré d’invertir?",
        answer4: "El treball socioemocional s’integra de manera transversal amb la pròpia activitat docent i no resulta en feina extra. Així doncs, encendre habilitats de vida implica, sobretot, entrenar la mirada i seguir fent classe a la teva manera aprofitant els espais on els alumnes interactuen —bé sigui pensant, planificant, debatent o buscant informació— per fer aflorar, avaluar i guiar les habilitats no cognitives dels estudiants. La feina del professor consisteix sobretot en promoure una dinàmica que faci emergir les competències socioemocionals que vol treballar. A partir d’aquí, rau en observar i registrar els comportaments durant la classe, extreure un significat a partir de l’evidència recollida amb l’ajuda dels propis alumnes i oferir un feedback perquè els alumnes prenguin consciència dels punts forts i de millora per a establir nous objectius.",
        question5: "En què consisteix la formació i l’acompanyament?",
        answer5: "La formació serà reconeguda pel Departament d’Educació i tindrà una duració de 8 hores en total. Per una banda, impartim una formació inicial en línia i asincrònica que introdueix el marc conceptual, la descripció dels indicadors, el funcionament de l’eina, la metodologia d’observació a classe i de les sessions de feedback amb els alumnes. Consisteix en la visualització de dotze píndoles formatives en format vídeo i un formulari de preguntes després de cada vídeo. Per altra banda, impartim una formació presencial i molt pràctica abans de començar a aplicar. Farem una sessió en grup de professors participants on posarem l’eina en pràctica, simularem una dinàmica de grup, treballarem amb casos reals, prepararem planificacions per a dissenyar espais on poder fer aflorar i avaluar les habilitats socioemocionals per a, després, interpretar les dades i donar un feedback.",
        question6: "Quina informació es recollirà sobre els alumnes i qui tindrà accés a aquestes dades?",
        answer6: "La informació que es recull són comportaments. Només té accés als noms i les dades dels alumnes els centres i els professors. I no surten d’allà. La recerca treballa amb dades que s’encripten; o sigui, que no es lliguen a una persona en concret. L’estudi es realitzarà seguint un protocol acceptat per un comitè ètic i de protecció de dades que garanteix els drets dels menors i les seves famílies.",
        question7: "Què passa si no som objectius?",
        answer7: "L’objectivitat en l’avaluació és molt difícil d’assolir, i més si avaluem termes de significat ampli i genèric, com són les pentabilitats. Si registrem evidències conductuals sembla que la subjectivitat disminueix, però, tot i així, hi ha una interpretació personal de les accions dels alumnes. La idea és que a partir de moltes subjectivitats (les de cada professor i alumne), en diferents moments i contextos, ens permetran tenir una idea més objectiva de cada alumne.",
        question8: "Qui financia aquest projecte?",
        answer8: "Aquesta iniciativa és una de les propostes seleccionades pel Ministeri d'Inclusió, Seguretat Social i Migracions a través d’una crida oberta a entitats d’acció social, administracions autonòmiques i locals per al desenvolupament d’itineraris d’inclusió social i la seva avaluació. És un pilot impulsat amb fons europeus NextGeneration del Plan de Recuperación, Transformación y Resiliencia per un període de 2 anys."
    }
};