import React, { useState } from "react";
import { Modal, Button } from "antd";
import i18n from "./../../i18n";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
  Row,
  Col,
} from "reactstrap";

// i18N hook
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";

import { MenuOutlined } from '@ant-design/icons'



import Contact from "../../views/landing/Contact";

import Cookies from 'js-cookie';
import useWindowDimensions from "utilities/WindowDimensionsHelper";





function IndexNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState("#FF6530");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [windowDimensions, setWindowDimensions] = React.useState(useWindowDimensions());
  const changing = (lang) => {

    i18n.changeLanguage(lang)
    Cookies.set('i18next', lang);
  };

  console.log(useWindowDimensions());

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 99 ||
        document.body.scrollTop > 99
      ) {
        setNavbarColor("#FF6530");
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor("#FF6530");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });


  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={"fixed-top " + navbarColor}
        expand="lg"
        color="secondary-2-0"
      >
        <Container>
          <div className="navbar-translate">
            {props.showLogo ? (
              <>
                <NavbarBrand href="/landing" id="navbar-brand">


                  {window.location.pathname === '/enciende' ? (<img src={require("assets/img/ignite_es.png")} />) : (<img src={require("assets/img/ignite_ca.png")} />)}

                </NavbarBrand>
              </>
            ) : <NavbarBrand href="/landing" id="navbar-brand" />}
           
            
            

          </div>

          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
            horizontal
          >
            <Nav navbar>
              <NavItem>
                <NavLink href="/encende">
                  <p>
                    <Trans>landing2.menu5</Trans>
                  </p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/skills-team">
                  <p>
                    <Trans>landing2.menu1</Trans>
                  </p>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="/project">
                  <p>
                    <Trans>landing2.menu2</Trans>
                  </p>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="/characteristics">
                  <p>
                    <Trans>landing2.menu3</Trans>
                  </p>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="/faq">
                  <p>
                    <Trans>FAQ's</Trans>
                  </p>
                </NavLink>
              </NavItem>

              {/** 
               <NavItem>
                <Contact />
              </NavItem>
              */}

              {props.langs ? (
                <><UncontrolledDropdown nav>
                  <DropdownToggle nav id="language">
                    <i className="fa fa-language fa-lg" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {props.langs.map((lang) => (
                      <>
                        <DropdownItem onClick={() => changing(lang.langKey)}>
                          {lang.langName}
                        </DropdownItem>
                      </>
                    ))}
                    <UncontrolledTooltip delay={0} target="language">
                      <Trans>misc.changelanguage</Trans>
                    </UncontrolledTooltip>
                  </DropdownMenu>
                </UncontrolledDropdown>
                </>
              ) : (
                <></>
              )}

              <NavItem>
                <NavLink target="_blank" href="https://twitter.com/pentabilities">
                  <i className="fa fa-twitter fa-lg" />
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
        {useWindowDimensions().width < 992 && (<Button icon={<MenuOutlined />} ghost 
            onClick={() => {
              document.documentElement.classList.toggle("nav-open");
              setCollapseOpen(!collapseOpen);
            }}
            
            >
              Menu
            </Button>)}
      </Navbar>
    </>
  );
}

export default withTranslation()(IndexNavbar);
