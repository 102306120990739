import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardImg,
    CardText,
} from "reactstrap";

// i18N hook
import { Trans } from 'react-i18next';
import { withTranslation } from "react-i18next";

// Other elements
import PentaModal from "components/General/Modal";

function Pentabilities() {
    const category0 = [
        <Trans>pentabilities.categories.category0.behaviours.behaviour0</Trans>,
        <Trans>pentabilities.categories.category0.behaviours.behaviour1</Trans>,
        <Trans>pentabilities.categories.category0.behaviours.behaviour2</Trans>,
        <Trans>pentabilities.categories.category0.behaviours.behaviour3</Trans>,
        <Trans>pentabilities.categories.category0.behaviours.behaviour4</Trans>,
        <Trans>pentabilities.categories.category0.behaviours.behaviour5</Trans>,
        <Trans>pentabilities.categories.category0.behaviours.behaviour6</Trans>
    ];
    const category1 = [
        <Trans>pentabilities.categories.category1.behaviours.behaviour0</Trans>,
        <Trans>pentabilities.categories.category1.behaviours.behaviour1</Trans>,
        <Trans>pentabilities.categories.category1.behaviours.behaviour2</Trans>,
        <Trans>pentabilities.categories.category1.behaviours.behaviour3</Trans>,
        <Trans>pentabilities.categories.category1.behaviours.behaviour4</Trans>,
        <Trans>pentabilities.categories.category1.behaviours.behaviour5</Trans>,
        <Trans>pentabilities.categories.category1.behaviours.behaviour6</Trans>
    ];
    const category2 = [
        <Trans>pentabilities.categories.category2.behaviours.behaviour0</Trans>,
        <Trans>pentabilities.categories.category2.behaviours.behaviour1</Trans>,
        <Trans>pentabilities.categories.category2.behaviours.behaviour2</Trans>,
        <Trans>pentabilities.categories.category2.behaviours.behaviour3</Trans>,
        <Trans>pentabilities.categories.category2.behaviours.behaviour4</Trans>,
        <Trans>pentabilities.categories.category2.behaviours.behaviour5</Trans>
    ];
    const category3 = [
        <Trans>pentabilities.categories.category3.behaviours.behaviour0</Trans>,
        <Trans>pentabilities.categories.category3.behaviours.behaviour1</Trans>,
        <Trans>pentabilities.categories.category3.behaviours.behaviour2</Trans>,
        <Trans>pentabilities.categories.category3.behaviours.behaviour3</Trans>,
        <Trans>pentabilities.categories.category3.behaviours.behaviour4</Trans>,
        <Trans>pentabilities.categories.category3.behaviours.behaviour5</Trans>
    ];
    
    const category4 = [
        <Trans>pentabilities.categories.category4.behaviours.behaviour0</Trans>,
        <Trans>pentabilities.categories.category4.behaviours.behaviour1</Trans>,
        <Trans>pentabilities.categories.category4.behaviours.behaviour2</Trans>,
        <Trans>pentabilities.categories.category4.behaviours.behaviour3</Trans>,
        <Trans>pentabilities.categories.category4.behaviours.behaviour4</Trans>,
        <Trans>pentabilities.categories.category4.behaviours.behaviour5</Trans>,
        <Trans>pentabilities.categories.category4.behaviours.behaviour6</Trans>,
        <Trans>pentabilities.categories.category4.behaviours.behaviour7</Trans>,
        <Trans>pentabilities.categories.category4.behaviours.behaviour8</Trans>
    ];
    return (
        <>
            <div className="section pt-1">
                <Container>
                    <div className="penta-title mt-1">
                        <Trans>pentabilities.title</Trans>
                    </div>
                    <Row>
                        <Col md="12">
                            <h5>
                                <Trans>pentabilities.description11</Trans>
                                <br></br><br></br>
                                <Trans>pentabilities.description12</Trans>
                                <br></br><br></br>
                                <Trans>pentabilities.description13</Trans>
                                <br></br><br></br>
                                <Trans>pentabilities.description14</Trans>
                            </h5>
                        </Col>
                        <Col md="4">
                            <Card>
                                <CardImg top width="100%" src={require("assets/img/pentabilities/category0.jpg")} alt="Card image cap" />
                                <CardBody>
                                    <CardTitle><Trans>pentabilities.categories.category0.title</Trans></CardTitle>
                                    <CardText><Trans>pentabilities.categories.category0.description</Trans></CardText>
                                    <center>
                                    <PentaModal
                                    footer={false}
                                    buttonLabel={<Trans>misc.seebehaviours</Trans>}
                                    header=
                                    {
                                       <h5 className="text-underline-primary-0"><Trans>pentabilities.categories.category0.title</Trans></h5>
                                    }
                                    bodyHtml={
                                        category0.map(category0 => {
                                            return (
                                                <h4 className="my-0"><li>{category0}</li></h4>
                                            );
                                        })
                                    } 
                                    />
                                    </center>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <CardImg top width="100%" src={require("assets/img/pentabilities/category1.jpg")} alt="Card image cap" />
                                <CardBody>
                                    <CardTitle><Trans>pentabilities.categories.category1.title</Trans></CardTitle>
                                    <CardText><Trans>pentabilities.categories.category1.description</Trans></CardText>
                                    <PentaModal
                                    footer={false}
                                    buttonLabel={<Trans>misc.seebehaviours</Trans>}
                                    header=
                                    {
                                        <h5 className="text-underline-primary-0"><Trans>pentabilities.categories.category1.title</Trans></h5>
                                    }
                                    bodyHtml={
                                        category1.map(category1 => {
                                            return (
                                                <h4 className="my-0"><li>{category1}</li></h4>
                                            );
                                        })
                                    } 
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <CardImg top width="100%" src={require("assets/img/pentabilities/category2.jpg")} alt="Card image cap" />
                                <CardBody>
                                    <CardTitle><Trans>pentabilities.categories.category2.title</Trans></CardTitle>
                                    <CardText><Trans>pentabilities.categories.category2.description</Trans></CardText>
                                    <PentaModal
                                    footer={false}
                                    buttonLabel={<Trans>misc.seebehaviours</Trans>}
                                    header=
                                    {
                                        <h5 className="text-underline-primary-0"><Trans>pentabilities.categories.category2.title</Trans></h5>
                                    }
                                    bodyHtml={
                                        category2.map(category2 => {
                                            return (
                                                <h4 className="my-0"><li>{category2}</li></h4>
                                            );
                                        })
                                    } 
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={{ size: 4, offset: 2 }}>
                            <Card>
                                <CardImg top width="100%" src={require("assets/img/pentabilities/category3.jpg")} alt="Card image cap" />
                                <CardBody>
                                    <CardTitle><Trans>pentabilities.categories.category3.title</Trans></CardTitle>
                                    <CardText><Trans>pentabilities.categories.category3.description</Trans></CardText>
                                    <PentaModal
                                    footer={false}
                                    buttonLabel={<Trans>misc.seebehaviours</Trans>}
                                    header=
                                    {
                                        <h5 className="text-underline-primary-0"><Trans>pentabilities.categories.category3.title</Trans></h5>
                                    }
                                    bodyHtml={
                                        category3.map(category3 => {
                                            return (
                                                <h4 className="my-0"><li>{category3}</li></h4>
                                            );
                                        })
                                    } 
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <CardImg top width="100%" src={require("assets/img/pentabilities/category3.jpg")} alt="Card image cap" />
                                <CardBody>
                                    <CardTitle><Trans>pentabilities.categories.category4.title</Trans></CardTitle>
                                    <CardText><Trans>pentabilities.categories.category4.description</Trans></CardText>
                                    <PentaModal
                                    footer={false}
                                    buttonLabel={<Trans>misc.seebehaviours</Trans>}
                                    header=
                                    {
                                        <h5 className="text-underline-primary-0"><Trans>pentabilities.categories.category4.title</Trans></h5>
                                    }
                                    bodyHtml={
                                        category4.map(category4 => {
                                            return (
                                                <h5 className="my-0"><li>{category4}</li></h5>
                                            );
                                        })
                                    } 
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default withTranslation()(Pentabilities);