import React from "react";
import { Row, Col, Container } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbarLanding";
import PentaHeader from "components/Headers/PentaHeader";
import PentaFooter from "components/Footers/PentaFooter";

// Other elements
import Member from "components/General/Member";

// i18N hook
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";

const FuelTeam = (props) => {
  const team = [
    {
      photo: require("assets/img/team/caterina.jpg"),
      name: "Caterina Calsamiglia",
      position: "CEO and Co-founder",
      description: <Trans>ignite.caterina</Trans>,
      linkedIn: "https://www.linkedin.com/in/caterina-calsamiglia-a601b3a/",
      twitter: "https://google.com",
      email: "caterina.calsamiglia@pentabilities.com",
    },
    {
      photo: require("assets/img/team/maria.jpg"),
      name: "Maria Calsamiglia",
      position: "Co-founder",
      description: <Trans>ignite.maria</Trans>,
      linkedIn: "https://www.linkedin.com/in/maria-calsamiglia-costa-a029b916/",
      twitter: "https://google.com",
      email: "maria.calsamiglia@pentabilities.com",
    },
  ];
  return (
    <>
      <IndexNavbar showLogo={true} langs={[{langName:'English', langKey: 'en'}, {langName:'Català', langKey: 'ca'}, {langName: 'Castellano', langKey: 'es'}]} />
      <div className="wrapper">
        <PentaHeader
          pageTitle={<Trans>team.title</Trans>}
          backColor={'var(--color-secondary-2-0)'}
        />
        <div className="main">
          <div className="section pt-2">
            <Container>
            <Row className="justify-content-center">
                <br />
                <br />
                {team.map((member) => {
                  return (
                    <Col md="6">
                      <Member
                        photo={member.photo}
                        name={member.name}
                        position={member.position}
                        description={member.description}
                        linkedIn={member.linkedIn}
                        twitter={member.twitter}
                        email={member.email}
                      />
                    </Col>
                  );
                })}
              </Row></Container></div></div></div>
    </>
  );
};

export default withTranslation()(FuelTeam);
