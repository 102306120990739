import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from "i18next-http-backend";
// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

import en from "./languages/en.js"
import es from "./languages/es.js";
import ca from "./languages/ca.js";

i18n
// load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
// learn more: https://github.com/i18next/i18next-xhr-backend
    .use(XHR)
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'ca',
        debug: true,
        detection: { order: ['cookie', 'querystring', 'navigator'], lookupQuerystring: 'lng', lookupCookie: 'i18next', },
        supportedLngs: ['en', 'es', 'ca'],

        resources: {
            en: { translation: en },
            es: { translation: es },
            ca: { translation: ca }
        },

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        react: {
            useSuspense: false
        },
        debug: true,
    });


export default i18n;