import React, { useState } from "react";

import { Card, Drawer } from "antd";

import { CloseCircleTwoTone } from "@ant-design/icons";

// reactstrap components
import { Col, Container, Row } from "reactstrap";

// i18N hook
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";

const { Meta } = Card;

const Projects = (props) => {
  let [selection, setSelection] = useState({
    1: true,
    2: true,
    3: true,
    4: true,
  });

  const unselectAll = () => {
    setSelection({ 1: true, 2: true, 3: true, 4: true });
  };

  const handleClick = (id) => {
    let current = { 1: false, 2: false, 3: false, 4: false };
    current[id] = true;
    setSelection(current);
  };

  console.log(props.pentaTitle);

  return (
    <>
      <div className="section bg-alternative-3-5">
        <Container>
          <div className="site-drawer-render-in-current-wrapper">
            <Row>
              <Col>
                <div
                  className={
                    props.pentaTitle ? "penta-title-alternative-1-0" : ""
                  }
                >
                    <h4 style={{color:'white',fontFamily: "'DIN Alternate', 'Roboto', sans-serif",fontWeight:'bold', marginTop: '0', marginBottom: '0'}}>
                  <Trans>index.projects.title</Trans></h4>
                </div>
                < br/>
                <Row>
                  <Col
                    md="3"
                    style={{ display: selection[1] ? "block" : "none" }}
                  >
                    <Card
                      className="penta-card penta-card-alternative-1-1"
                      hoverable
                      cover={
                        <img
                          alt="example"
                          src={require("assets/img/projects/secondary.jpg")}
                        />
                      }
                      onClick={() => handleClick(1)}
                    >
                      <Meta
                        title={<Trans>index.projects.title1</Trans>}
                        description={<Trans>index.projects.caption1</Trans>}
                      />
                    </Card>
                  </Col>
                  <Col
                    md="3"
                    style={{
                      display: selection[2] === true ? "block" : "none",
                    }}
                  >
                    <Card
                      className="penta-card penta-card-alternative-1-2"
                      hoverable
                      cover={
                        <img
                          alt="example"
                          src={require("assets/img/projects/fabilities.jpg")}
                        />
                      }
                      onClick={() => handleClick(2)}
                    >
                      <Meta
                        title={<Trans>index.projects.title2</Trans>}
                        description={<Trans>index.projects.caption2</Trans>}
                      />
                    </Card>
                  </Col>
                  <Col
                    md="3"
                    style={{
                      display: selection[3] === true ? "block" : "none",
                    }}
                  >
                    <Card
                      className="penta-card penta-card-alternative-1-0"
                      hoverable
                      cover={
                        <img
                          alt="example"
                          src={require("assets/img/projects/fp.jpg")}
                        />
                      }
                      onClick={() => handleClick(3)}
                    >
                      <Meta
                        title={<Trans>index.projects.title3</Trans>}
                        description={<Trans>index.projects.caption3</Trans>}
                      />
                    </Card>
                  </Col>
                  <Col
                    md="3"
                    style={{
                      display: selection[4] === true ? "block" : "none",
                    }}
                  >
                    <Card
                      className="penta-card penta-card-alternative-1-3"
                      hoverable
                      cover={
                        <img
                          alt="example"
                          src={require("assets/img/projects/university.jpg")}
                        />
                      }
                      onClick={() => handleClick(4)}
                    >
                      <Meta
                        title={<Trans>index.projects.title4</Trans>}
                        description={<Trans>index.projects.caption4</Trans>}
                      />
                    </Card>
                  </Col>
                </Row>

                <Drawer
                  className="pentadrawer"
                  title={<Trans>index.projects.title1</Trans>}
                  placement="right"
                  onClose={() => unselectAll()}
                  visible={selection[1] && !selection[2]}
                  getContainer={false}
                  style={{ position: "absolute" }}
                  closable={true}
                  closeIcon={
                    <CloseCircleTwoTone
                      twoToneColor="#FE4100"
                      style={{ fontSize: "30px" }}
                    />
                  }
                >
                  <h5
                    style={{ "fontStyle": "italic" }}
                    className="no-margin-top"
                  >
                    <strong>{<Trans>index.projects.caption1</Trans>}</strong>
                  </h5>
                  <h5>{<Trans>index.projects.description1_1</Trans>}</h5>
                  <h5>{<Trans>index.projects.description1_2</Trans>}</h5>
                </Drawer>

                <Drawer
                  className="pentadrawer"
                  title={<Trans>index.projects.title2</Trans>}
                  placement="right"
                  onClose={() => unselectAll()}
                  visible={selection[2] && !selection[1]}
                  getContainer={false}
                  style={{ position: "absolute" }}
                  closable={true}
                  closeIcon={
                    <CloseCircleTwoTone
                      twoToneColor="#FE4100"
                      style={{ fontSize: "30px" }}
                    />
                  }
                >
                  <h5
                    style={{ "font-style": "italic" }}
                    className="no-margin-top"
                  >
                    <strong>{<Trans>index.projects.caption2</Trans>}</strong>
                  </h5>
                  <h5>{<Trans>index.projects.description2_1</Trans>}</h5>
                  <h5>{<Trans>index.projects.description2_2</Trans>}</h5>
                  <h5>{<Trans>index.projects.description2_2</Trans>}</h5>
                </Drawer>
                <Drawer
                  className="pentadrawer"
                  title={<Trans>index.projects.title3</Trans>}
                  placement="right"
                  onClose={() => unselectAll()}
                  visible={selection[3] && !selection[1]}
                  getContainer={false}
                  style={{ position: "absolute" }}
                  closable={true}
                  closeIcon={
                    <CloseCircleTwoTone
                      twoToneColor="#FE4100"
                      style={{ fontSize: "30px" }}
                    />
                  }
                >
                  <h5
                    style={{ "font-style": "italic" }}
                    className="no-margin-top"
                  >
                    <strong>{<Trans>index.projects.caption3</Trans>}</strong>
                  </h5>
                  <h5>{<Trans>index.projects.description3_1</Trans>}</h5>
                  <h5>{<Trans>index.projects.description3_2</Trans>}</h5>
                </Drawer>
                <Drawer
                  className="pentadrawer"
                  title={<Trans>index.projects.title4</Trans>}
                  placement="right"
                  onClose={() => unselectAll()}
                  visible={selection[4] && !selection[1]}
                  getContainer={false}
                  style={{ position: "absolute" }}
                  closable={true}
                  closeIcon={
                    <CloseCircleTwoTone
                      twoToneColor="#FE4100"
                      style={{ fontSize: "30px" }}
                    />
                  }
                >
                  <h5
                    style={{ "font-style": "italic" }}
                    className="no-margin-top"
                  >
                    <strong>{<Trans>index.projects.caption4</Trans>}</strong>
                  </h5>
                  <h5
                    style={{ "font-style": "italic" }}
                    className="no-margin-top"
                  >
                    <strong>
                      {<Trans>index.projects.description4_1</Trans>}
                    </strong>
                  </h5>
                  <h5>{<Trans>index.projects.description4_2</Trans>}</h5>
                  <h5
                    style={{ "font-style": "italic" }}
                    className="no-margin-top"
                  >
                    <strong>
                      {<Trans>index.projects.description4_3</Trans>}
                    </strong>
                  </h5>
                  <h5>{<Trans>index.projects.description4_4</Trans>}</h5>
                </Drawer>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(Projects);
