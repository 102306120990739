import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,

} from "reactstrap";

// i18N hook
import { Trans } from 'react-i18next';
import { withTranslation } from "react-i18next";
import i18next from 'i18next';

function Customers() {
    return (
        <>
            <div className="section bg-alternative-3-5">
                <Container>
                    <div className="penta-title-alternative-2-0"><Trans>index.learning.title</Trans></div>
                    <Row className="justify-content-center">
                        <Col md="12">
                            <h4><Trans>index.learning.description</Trans></h4>
                        </Col>
                        <Col md="6">
                            <img src={require('assets/img/infographics/Cycle-' + i18next.language.substring(0,2) + '.png')} />
                        </Col>
                        <Col md="6">
                            <h4>
                                <ol>
                                    <li><span className="text-underline-alternative-1-0"><Trans>index.learning.step1</Trans></span> <Trans>index.learning.step1description</Trans></li>
                                    <li><span className="text-underline-secondary-2-0"><Trans>index.learning.step2</Trans></span> <Trans>index.learning.step2description</Trans></li>
                                    <li><span className="text-underline-primary-0"><Trans>index.learning.step3</Trans></span> <Trans>index.learning.step3description</Trans></li>
                                    <li><span className="text-underline-alternative-2-0"><Trans>index.learning.step4</Trans></span> <Trans>index.learning.step4description</Trans></li>
                                    <li><span className="text-underline-secondary-1-0"><Trans>index.learning.step5</Trans></span> <Trans>index.learning.step5description</Trans></li>
                                </ol>
                            </h4>
                        </Col>

                    </Row>
                </Container>
            </div>
        </>
    );
}

export default withTranslation()(Customers);