import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "reactstrap";
import { BackTop, notification } from "antd";

// core components
import IndexNavbar from "components/Navbars/IndexNavbarLanding";

import PentaFooter from "components/Footers/PentaFooter";
import LandingHeader from "components/Headers/LandingHeader";

import Projects from "../landing/Projects";
import Testimonials from "../landing/Testimonials";
import Customers from "../landing/Customers";
import Steps from "../landing/Steps";

import Player from "components/Video/YouTubeResponsive";

// i18N hook
import { Trans } from "react-i18next";
import { useTranslation, withTranslation } from "react-i18next";

import Cookies from "js-cookie";

function Terms(props) {
  const { t, i18n } = useTranslation();

  const openNotification = () => {
    notification.open({
      message: "",
      duration: 0,
      top: 50,
      style: { backgroundColor: "var(--color-primary-0)", color: "white" },
      description: (
        <>
          <Row className="">
            <Col className="align-self-center">
              <center>
                <h4 style={{ color: "white", fontWeight: "bold" }}>
                  {i18n.t("landing2.alert2")}
                </h4>
                <Button
                  onClick={() =>
                    window.open(
                      i18n.language == "ca"
                        ? "https://pentabilities.com/resources/inscripcio.pdf"
                        : "https://pentabilitiescom-my.sharepoint.com/:w:/g/personal/juan_valencia_pentabilities_com/EfUNOXDsZZhPmtP1QVmhGSMB5TQ9XrnHUBPGkspvMWyXwQ?e=tacjaE",
                      "_blank"
                    )
                  }
                  size={"lg"}
                  style={{
                    backgroundColor: "white",
                    color: "var(--color-primary-0)",
                    fontWeight: "bold",
                  }}
                >
                  {i18n.t("landing2.button5")}
                </Button>
              </center>
            </Col>
          </Row>
        </>
      ),
    });
  };
  useEffect(() => {
    notification.destroy();
    openNotification();
  }, [i18n.language]);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  useEffect(async () => {
    let location = {};
    const response = await fetch("https://ipapi.co/json/");
    if (response.status === 200) {
      const data = await response.json();
      location = data;
    }

    let userLang = Cookies.get("i18next") ? Cookies.get("i18next") : false;
    let defaultLang;
    if (location && location.country) {
      let ipLang = location.languages.split(",")[0].split("-")[0];
      setCountry(location.country);
      setRegion(location.region_code);
      console.log(location);
      if (location.country === "ES" && location.region_code === "CT") {
        defaultLang = "ca";
      } else {
        if(location.country === "ES"){
          
          window.location.replace("/enciende");
        }
       
      }
    }


    if (defaultLang && !userLang) {
      i18n.changeLanguage(defaultLang);
      Cookies.set('i18next', defaultLang);
      
    }
  }, []);
  return (
    <>
      <BackTop visibilityHeight={40} />

      <IndexNavbar
        showLogo={true}
        langs={[
          { langName: "Català", langKey: "ca" },
          { langName: "Castellano", langKey: "es" },
        ]}
        defaultLanguage={"ca"}
      />

      <div className="wrapper">
        <LandingHeader i18n={i18n} />
        <div className="main">
          {/**Section 1 */}
          <div className="section bg-alternative-3-5">
            <Container>
              <Row className="align-items-center">
                <Col md="12">
                  <center>
                  <h2>
                    <Trans>landing2.section1_1</Trans>{" "}
                      <span style={{ fontWeight: "bold" }}>
                        <Trans>landing2.section1_2</Trans>
                      </span>{" "}
                      <Trans>landing2.section1_3</Trans>
                    </h2>

                    <Button
                      onClick={() =>
                        window.open(
                          i18n.language == "ca"
                            ? "https://forms.office.com/r/0j9zhwvQFz"
                            : "https://forms.office.com/r/1m6mX1jqwX",
                          "_blank"
                        )
                      }
                      size={"lg"}
                      style={{
                        backgroundColor: "var(--color-secondary-2-0)",
                        fontWeight: "bold",
                      }}
                    >
                      <Trans>landing2.button1</Trans>
                    </Button>

                    <br />
                  </center>
                </Col>
              </Row>
              <Row
                className="justify-content-center"
                style={{ paddingTop: "20px" }}
              ></Row>
            </Container>
          </div>

          {/**New Section 2 */}

          <Container className="section">
            <Row className="row align-items-center">
              <Col md="6" xs="12" className="align-items-center">
                <Player youtubeId="xIQMkcgG5u4?cc_load_policy=1" />
              </Col>

              <Col md="6" xs="12">
                <h2
                  style={{
                    color: "var(--color-secondary-2-0)",
                    fontWeight: "bold",
                  }}
                >
                  <Trans>landing2.section5_1</Trans>
                </h2>

               
                <h5
                  style={{
                    
                    fontWeight: 'normal',
                    textAlign: 'right'
                  }}
                >
                  <span style={{fontStyle: 'italic'}}>"<Trans>landing2.section5_2</Trans>"</span> <span style={{fontWeight: 'bold'}}><Trans>landing2.section5_3</Trans></span>
                  
                </h5>

                <h5
                  style={{
                    
                    fontWeight: 'normal',
                    textAlign: 'right'
                  }}
                >
                  <span style={{fontStyle: 'italic'}}>"<Trans>landing2.section5_4</Trans>"</span> <span style={{fontWeight: 'bold'}}><Trans>landing2.section5_5</Trans></span>
                  
                </h5>
              </Col>
            </Row>
          </Container>

          {/**Section 2 */}

          <div className="section bg-landing-img">
            <Container>
              <Row
                className="align-items-center"
                style={{ position: "relative" }}
              >
                <Col>
                  <center>
                    <h2
                      style={{
                        color: "var(--color-secondary-2-0)",
                        fontWeight: "bold",
                      }}
                    >
                      <Trans>landing2.section2_1</Trans>
                    </h2>
                  </center>
                </Col>
              </Row>
              <br />
              <Row className="align-items-top" style={{ position: "relative" }}>
                <Col md={{ size: 5 }}>
                  <h6>
                    <span style={{ color: "var(--color-secondary-2-0)" }}>
                      1 - <Trans>landing2.section2_2</Trans>
                    </span>
                  </h6>
                  <p>
                    <Trans>landing2.section2_3</Trans>
                  </p>
                </Col>
                <Col md={{ size: 5, offset: 2 }}>
                  <h6>
                    <span style={{ color: "var(--color-secondary-2-0)" }}>
                      2 - <Trans>landing2.section2_4</Trans>
                    </span>
                  </h6>
                  <p>
                    <Trans>landing2.section2_5</Trans>
                  </p>
                </Col>
              </Row>
              <br />
              <Row
                className="align-items-center"
                style={{ position: "relative" }}
              >
                <Col md={{ size: 6, offset: 3 }}>
                  <h6>
                    <span style={{ color: "var(--color-secondary-2-0)" }}>
                      3 - <Trans>landing2.section2_6</Trans>
                    </span>
                  </h6>
                  <p>
                    <Trans>landing2.section2_7</Trans>
                  </p>
                </Col>
              </Row>
              <br />
              <Row className="align-items-top" style={{ position: "relative" }}>
                <Col md={{ size: 5 }}>
                  <h6>
                    <span style={{ color: "var(--color-secondary-2-0)" }}>
                      4 - <Trans>landing2.section2_8</Trans>
                    </span>
                  </h6>
                  <p>
                    <Trans>landing2.section2_9</Trans>
                  </p>
                </Col>
                <Col md={{ size: 5, offset: 2 }}>
                  <h6>
                    <span style={{ color: "var(--color-secondary-2-0)" }}>
                      5 - <Trans>landing2.section2_10</Trans>
                    </span>
                  </h6>
                  <p>
                    <Trans>landing2.section2_11</Trans>
                  </p>
                </Col>
              </Row>
              <Row
                className="align-items-center"
                style={{ position: "relative" }}
              >
                <Col className="mx-auto">
                  <center>
                    <Button
                      onClick={() =>
                        window.open(
                          i18n.language == "ca"
                            ? "https://forms.office.com/r/0j9zhwvQFz"
                            : "https://forms.office.com/r/1m6mX1jqwX",
                          "_blank"
                        )
                      }
                      size={"lg"}
                      style={{
                        backgroundColor: "var(--color-secondary-2-0)",
                        fontWeight: "bold",
                      }}
                    >
                      <Trans>landing2.button2</Trans>
                    </Button>
                  </center>
                </Col>
              </Row>
            </Container>
          </div>
          {/**Section 3 */}

          <div className="section">
            <Container>
              <Row className="align-items-start">
                <Col>
                  <center>
                    <h2
                      style={{
                        color: "var(--color-secondary-2-0)",
                        fontWeight: "bold",
                      }}
                    >
                      <Trans>landing2.title3</Trans>
                    </h2>
                  </center>
                </Col>
              </Row>
              <Row className="align-items-start">
                <Col md={{ size: 3 }}>
                  <img src={require("assets/img/pentagon.png")} />
                </Col>
                <Col md={{ size: 9 }}>
                  <Steps />
                </Col>
              </Row>
            </Container>
          </div>
          
          {/**Section 4  {/**Section 4 - Hidden by suggestion of Beatriz Jimenez and Anna Schnabel 
          <div className="section bg-secondary-2-0">
            <Container>
              <Row className="align-items-center">
                <Col md="12">
                  <center>
                    <h5
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                        color: "white",
                        opacity: "80%",
                      }}
                    >
                      "<Trans>landing2.section4_1</Trans>"
                      <br />
                      <br />
                      <span style={{ fontStyle: "normal" }}>
                        <Trans>landing2.section4_2</Trans>
                      </span>
                    </h5>
                  </center>
                </Col>
              </Row>
            </Container>
          </div>
          <Projects pentaTitle={false} backgroundColor="bg-alternative-2-0" />
          */}

          {/**Section 5 */}
          <Testimonials
            childStyle={{ backgroundColor: "var(--color-secondary-2-0)" }}
          />
        </div>
        <PentaFooter />
      </div>
    </>
  );
}

export default withTranslation()(Terms);
