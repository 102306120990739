import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Button,
    Col,
    Card,
    CardBody,
    ListGroup,
    ListGroupItem,
    ListGroupItemHeading,
    ListGroupItemText,
    UncontrolledCollapse,
} from "reactstrap";

// i18N hook
import { Trans } from 'react-i18next';
import { withTranslation } from "react-i18next";

// FontAwesome hook
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faCalendarCheck, faComments } from '@fortawesome/free-solid-svg-icons'

// Other elements
import PentaModal from "components/General/Modal";

function System() {
    const category0 = [
        <Trans>pentabilities.categories.category0.behaviours.behaviour0</Trans>,
        <Trans>pentabilities.categories.category0.behaviours.behaviour1</Trans>,
        <Trans>pentabilities.categories.category0.behaviours.behaviour2</Trans>,
        <Trans>pentabilities.categories.category0.behaviours.behaviour3</Trans>,
        <Trans>pentabilities.categories.category0.behaviours.behaviour4</Trans>,
        <Trans>pentabilities.categories.category0.behaviours.behaviour5</Trans>,
        <Trans>pentabilities.categories.category0.behaviours.behaviour6</Trans>
    ];
    const category1 = [
        <Trans>pentabilities.categories.category1.behaviours.behaviour0</Trans>,
        <Trans>pentabilities.categories.category1.behaviours.behaviour1</Trans>,
        <Trans>pentabilities.categories.category1.behaviours.behaviour2</Trans>,
        <Trans>pentabilities.categories.category1.behaviours.behaviour3</Trans>,
        <Trans>pentabilities.categories.category1.behaviours.behaviour4</Trans>,
        <Trans>pentabilities.categories.category1.behaviours.behaviour5</Trans>,
        <Trans>pentabilities.categories.category1.behaviours.behaviour6</Trans>
    ];
    const category2 = [
        <Trans>pentabilities.categories.category2.behaviours.behaviour0</Trans>,
        <Trans>pentabilities.categories.category2.behaviours.behaviour1</Trans>,
        <Trans>pentabilities.categories.category2.behaviours.behaviour2</Trans>,
        <Trans>pentabilities.categories.category2.behaviours.behaviour3</Trans>,
        <Trans>pentabilities.categories.category2.behaviours.behaviour4</Trans>,
        <Trans>pentabilities.categories.category2.behaviours.behaviour5</Trans>
    ];
    const category3 = [
        <Trans>pentabilities.categories.category3.behaviours.behaviour0</Trans>,
        <Trans>pentabilities.categories.category3.behaviours.behaviour1</Trans>,
        <Trans>pentabilities.categories.category3.behaviours.behaviour2</Trans>,
        <Trans>pentabilities.categories.category3.behaviours.behaviour3</Trans>,
        <Trans>pentabilities.categories.category3.behaviours.behaviour4</Trans>,
        <Trans>pentabilities.categories.category3.behaviours.behaviour5</Trans>
    ];
    const category4 = [
        <Trans>pentabilities.categories.category4.behaviours.behaviour0</Trans>,
        <Trans>pentabilities.categories.category4.behaviours.behaviour1</Trans>,
        <Trans>pentabilities.categories.category4.behaviours.behaviour2</Trans>,
        <Trans>pentabilities.categories.category4.behaviours.behaviour3</Trans>,
        <Trans>pentabilities.categories.category4.behaviours.behaviour4</Trans>,
        <Trans>pentabilities.categories.category4.behaviours.behaviour5</Trans>,
        <Trans>pentabilities.categories.category4.behaviours.behaviour6</Trans>,
        <Trans>pentabilities.categories.category4.behaviours.behaviour7</Trans>
    ];
    return (
        <>
            <div className="section pt-1">
                <Container>
                    <div className="penta-title mt-1">
                        <Trans>research.system.shorttitle</Trans>
                    </div>
                    <Row>
                        <Col md="7">
                            <h5>
                                <Trans>research.system.description1</Trans>
                                <br></br><br></br>
                                <Trans>research.system.description2</Trans>
                                <br></br><br></br>
                                <Trans>research.system.description3</Trans>
                            </h5>
                        </Col>
                        <Col md="5">
                            <h4 className="my-0 text-underline-primary-0"><Trans>misc.expandknowledge</Trans></h4>
                            <Button color="primary-0" id="toggler" style={{ marginBottom: '1rem' }}>
                                <FontAwesomeIcon icon={faEye} />
                                {' '}
                                <Trans>research.steps.title1</Trans>
                            </Button>
                            <UncontrolledCollapse toggler="#toggler">
                                <Card className="bg-primary-0">

                                    <CardBody>
                                        <h5 className="text-white">
                                            <FontAwesomeIcon icon={faEye} />
                                            {' '}
                                            <Trans>research.steps.title1</Trans>
                                        </h5>
                                        <span className="text-white"><Trans>research.steps.description1</Trans></span>
                                    </CardBody>
                                    <center>
                                        <PentaModal
                                            btnColor = 'primary-1'
                                            buttonLabel={<Trans>misc.learnmore</Trans>}
                                            header={<h5><span className="text-underline-primary-0"><Trans>research.steps.title1</Trans></span></h5>}
                                            bodyHtml={
                                                <Row>
                                                    <Col md="6">
                                                        <h5>
                                                            <Trans>research.steps.fulldescription11</Trans>
                                                            <br></br><br></br>
                                                            <Trans>research.steps.fulldescription12</Trans>
                                                            <br></br><br></br>
                                                            <Trans>research.steps.fulldescription13</Trans>
                                                            <br></br><br></br>
                                                            <Trans>research.steps.fulldescription14</Trans>
                                                            <br></br><br></br>
                                                            <Trans>research.steps.fulldescription15</Trans>
                                                            <br></br><br></br>
                                                        </h5>
                                                    </Col>
                                                    <Col md="6">
                                                        <ListGroup>
                                                            <ListGroupItem>
                                                                <ListGroupItemHeading className="text-underline-primary-0"><Trans>pentabilities.categories.category0.title</Trans></ListGroupItemHeading>
                                                                {category0.map(category0 => {
                                                                    return (
                                                                        <ListGroupItemText className="my-0">{category0}</ListGroupItemText>
                                                                    );
                                                                })}
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <ListGroupItemHeading className="text-underline-secondary-1-0"><Trans>pentabilities.categories.category1.title</Trans></ListGroupItemHeading>
                                                                {category1.map(category1 => {
                                                                    return (
                                                                        <ListGroupItemText className="my-0">{category1}</ListGroupItemText>
                                                                    );
                                                                })}
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <ListGroupItemHeading className="text-underline-secondary-2-0"><Trans>pentabilities.categories.category2.title</Trans></ListGroupItemHeading>
                                                                {category2.map(category2 => {
                                                                    return (
                                                                        <ListGroupItemText className="my-0">{category2}</ListGroupItemText>
                                                                    );
                                                                })}
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <ListGroupItemHeading className="text-underline-alternative-1-0"><Trans>pentabilities.categories.category3.title</Trans></ListGroupItemHeading>
                                                                {category3.map(category3 => {
                                                                    return (
                                                                        <ListGroupItemText className="my-0">{category3}</ListGroupItemText>
                                                                    );
                                                                })}
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <ListGroupItemHeading className="text-underline-alternative-2-0"><Trans>pentabilities.categories.category4.title</Trans></ListGroupItemHeading>
                                                                {category4.map(category4 => {
                                                                    return (
                                                                        <ListGroupItemText className="my-0">{category4}</ListGroupItemText>
                                                                    );
                                                                })}
                                                            </ListGroupItem>
                                                            
                                                        </ListGroup>
                                                    </Col>
                                                </Row>
                                            }
                                        />
                                    </center>
                                </Card>
                            </UncontrolledCollapse>
                            <br></br>
                            <Button color="secondary-1-0" id="toggler2" style={{ marginBottom: '1rem' }}>
                                <FontAwesomeIcon icon={faCalendarCheck} />
                                {' '}
                                <Trans>research.steps.title2</Trans>
                            </Button>
                            <UncontrolledCollapse toggler="#toggler2">
                                <Card className="bg-secondary-1-0">

                                    <CardBody>
                                        <h5 className="text-white">
                                            <FontAwesomeIcon icon={faCalendarCheck} />
                                            {' '}
                                            <Trans>research.steps.title2</Trans>
                                        </h5>
                                        <span className="text-white"><Trans>research.steps.description2</Trans></span>
                                    </CardBody>
                                    <center>
                                        <PentaModal
                                            btnColor = 'secondary-1-1'
                                            buttonLabel={<Trans>misc.learnmore</Trans>}
                                            header={<h5><span className="text-underline-secondary-1-0"><Trans>research.steps.title2</Trans></span></h5>}
                                            bodyHtml={
                                                <Row>
                                                    <Col md="12">
                                                       <h5>
                                                           <Trans>research.steps.fulldescription21</Trans>
                                                           <br></br><br></br>
                                                           <Trans>research.steps.fulldescription22</Trans>
                                                           <br></br><br></br>
                                                           <Trans>research.steps.fulldescription23</Trans>
                                                           <br></br><br></br>
                                                           <Trans>research.steps.fulldescription24</Trans>
                                                           <br></br><br></br>
                                                           <Trans>research.steps.fulldescription25</Trans>
                                                           <br></br><br></br>
                                                           <Trans>research.steps.fulldescription26</Trans>
                                                           <br></br><br></br>
                                                           <Trans>research.steps.fulldescription27</Trans>
                                                           <br></br><br></br>
                                                           <Trans>research.steps.fulldescription28</Trans>
                                                       </h5>
                                                    </Col>
                                                </Row>
                                            }
                                        />
                                    </center>
                                </Card>
                            </UncontrolledCollapse>
                            <br></br>
                            <Button color="secondary-2-0" id="toggler3" style={{ marginBottom: '1rem' }}>
                                <FontAwesomeIcon icon={faComments} />
                                {' '}
                                <Trans>research.steps.title3</Trans>
                            </Button>
                            <UncontrolledCollapse toggler="#toggler3">
                                <Card className="bg-secondary-2-0">

                                    <CardBody>
                                        <h5 className="text-white">
                                            <FontAwesomeIcon icon={faComments} />
                                            {' '}
                                            <Trans>research.steps.title3</Trans>
                                        </h5>
                                        <span className="text-white"><Trans>research.steps.description3</Trans></span>
                                    </CardBody>
                                    <center>
                                        <PentaModal
                                            btnColor = 'secondary-2-1'
                                            buttonLabel={<Trans>misc.learnmore</Trans>}
                                            header={<h5><span className="text-underline-secondary-2-0"><Trans>research.steps.title3</Trans></span></h5>}
                                            bodyHtml={
                                                <Row>
                                                    <Col md="12">
                                                       <h5>
                                                           <Trans>research.steps.fulldescription31</Trans>
                                                           <br></br><br></br>
                                                           <Trans>research.steps.fulldescription32</Trans>
                                                           <br></br><br></br>
                                                           <Trans>research.steps.fulldescription33</Trans>
                                                           <br></br><br></br>
                                                           <Trans>research.steps.fulldescription34</Trans>
                                                       </h5>
                                                    </Col>
                                                </Row>
                                            }
                                        />
                                    </center>
                                </Card>
                            </UncontrolledCollapse>

                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    );
}

export default withTranslation()(System);