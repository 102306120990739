import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// i18N hook
import { Trans, withTranslation, useTranslation } from "react-i18next";


// Other elements
import PentaModal from "components/General/Modal";

function Relevance() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="section pt-1">
        <Container>
          <div className="penta-title mt-1">
            <Trans>research.matter.title1</Trans>
          </div>
          <Row>
            <Col md="9">
              <h5>
                <Trans>research.matter.description11</Trans>
                <br></br>
                <br></br>
                <Trans>research.matter.description12</Trans>
                <br></br>
                <br></br>
                <Trans>research.matter.description13</Trans>
                <br></br>
                <br></br>
                <Trans>research.matter.description14</Trans>
              </h5>
              <blockquote>
                <h5 className="blockquote-penta blockquote-secondary-2-0">
                  <Trans>research.matter.description15</Trans>
                  <br></br>
                  <br></br>
                  <Trans>misc.articles</Trans>:{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.sciencedirect.com/science/article/abs/pii/S0927537112000577?via%3Dihub"
                  >
                    <Trans>research.matter.reference11</Trans>
                  </a>
                  ,{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://www.oecd.org/education/ceri/Fostering-and-Measuring-Skills-Improving-Cognitive-and-Non-Co"
                  >
                    <Trans>research.matter.reference12</Trans>
                  </a>
                  , <Trans>research.metter.reference13</Trans>.<br></br>
                  <Trans>misc.books</Trans>:{" "}
                  <Trans>research.matter.reference14</Trans>.<br></br>
                  <Trans>misc.videos</Trans>:{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/watch?v=jYXXbL_LKwk"
                  >
                    <Trans>research.matter.reference15</Trans>
                  </a>
                  ,{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/watch?v=yKzQYQNtYts&list=PL420VB-f-6lgaQ5xGvEGHK1F29hA9QAFy"
                  >
                    <Trans>research.matter.reference16</Trans>
                  </a>
                  .
                </h5>
              </blockquote>
              <h5>
                <Trans>research.matter.description16</Trans>
                <br></br>
                <br></br>
                <Trans>research.matter.description17</Trans>
              </h5>
            </Col>
            <Col md="3">
              <h4 className="my-0">
                <Trans>misc.expandknowledge</Trans>
              </h4>
              <PentaModal
                buttonLabel={<Trans>research.matter.title2</Trans>}
                header={<Trans>research.matter.title2</Trans>}
                bodyHtml={
                  <h5>
                    <Trans>research.matter.description21</Trans>
                    <br></br>
                    <br></br>
                    <Trans>research.matter.description22</Trans>
                    <br></br>
                    <br></br>
                    <Trans>research.matter.description23</Trans>
                    <br></br>
                    <br></br>
                    <Trans>research.matter.description24</Trans>
                    {console.log(((i18n.language).split('-'))[0])}
                    <a href={process.env.PUBLIC_URL + '/resources/Pentabilities Explanatory Document - '+(((i18n.language).split('-'))[0]).toUpperCase()+'.pdf'} target="_blank">
                      <Trans>research.matter.description25</Trans>
                    </a>
                  </h5>
                }
              />
              <br></br>
              <PentaModal
                buttonLabel={<Trans>research.matter.title3</Trans>}
                header={<Trans>research.matter.title3</Trans>}
                bodyHtml={
                  <h5>
                    <Trans>research.matter.description31</Trans>
                    <br></br>
                    <br></br>
                    <Trans>research.matter.description32</Trans>
                    <br></br>
                    <br></br>
                    <Trans>research.matter.description33</Trans>
                  </h5>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default withTranslation()(Relevance);
