import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Container,
} from "reactstrap";
import classnames from "classnames";
import { BackTop } from "antd";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar";
import PentaHeader from "components/Headers/PentaHeader";
import PentaFooter from "components/Footers/PentaFooter";

import Tools from "views/products-sections/Tools";
import Training from "views/products-sections/Training";
import Support from "views/products-sections/Support";

// i18N hook
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";

function Research() {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  React.useEffect(() => {
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
  });
  return (
    <>
      <BackTop />
      <IndexNavbar />
      <div className="wrapper">
        <PentaHeader pageTitle={<Trans>nav.products</Trans>} />
        <div className="main">
          <div className="section pt-2">
            <Container>
              <Nav tabs className="justify-content-end">
                <NavItem className="penta-tab">
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    <Trans>products.tools.title</Trans>
                  </NavLink>
                </NavItem>
                <NavItem className="penta-tab">
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    <Trans>products.training.title</Trans>
                  </NavLink>
                </NavItem>
                <NavItem className="penta-tab">
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    <Trans>products.support.title</Trans>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <Tools />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <Training />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <Support />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Container>
          </div>
        </div>
        <PentaFooter />
      </div>
    </>
  );
}
export default withTranslation()(Research);
