/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const PentaModal = (props) => {
  const {
    buttonLabel,
    className,
    header,
    bodyHtml,
    footer,
    btnColor
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button color={btnColor} onClick={toggle}>{buttonLabel}</Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{header}</ModalHeader>
        <ModalBody>
          {bodyHtml}
        </ModalBody>
        {footer === true &&
          <ModalFooter>
            <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        }

      </Modal>
    </div>
  );
}

export default PentaModal;