import React from "react";
import ReactDOM from "react-dom";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardGroup,
  CardHeader,
} from "reactstrap";

import {
    PlaySquareOutlined,
  } from '@ant-design/icons';

// i18N hook
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";

// FontAwesome hook
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandshake,
  faChalkboardTeacher,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";


// core components
class Products extends React.Component {
  render() {
    return (
      <>
        <div className="section" id="products" ref="products">
          <Container>
            <div className="penta-title">
              <Trans>index.products.title</Trans>
            </div>
            <h4>
              <Trans>index.products.description1</Trans>{" "}
              <span className="text-underline-alternative-2-1">
                <Trans>index.products.client1</Trans>
              </span>
              ,{" "}
              <span className="text-underline-alternative-3-1">
                <Trans>index.products.client2</Trans>
              </span>
              ,{" "}
              <span className="text-underline-alternative-2-1">
                <Trans>index.products.client3</Trans>
              </span>
              , <Trans>connectors.and</Trans>{" "}
              <span className="text-underline-alternative-3-1">
                <Trans>index.products.client4</Trans>
              </span>{" "}
              <Trans>index.products.description2</Trans>
            </h4>
            <Row className="justify-content-center">
              <Col md="9">
                <Row className="my-3 justify-content-center align-items-center" style={{ border: "solid var(--color-primary-0)", borderRadius:"0.1875rem" }}>
                  <Col
                    md="3"
                    className="bg-primary-0 justify-content-center align-items-center"
                  >
                    <center>
                      <h1 className="text-white my-4">
                        <FontAwesomeIcon icon={faToolbox} />
                      </h1>
                      <h3 className="text-white my-4">
                        <Trans>index.products.producttitle1</Trans>
                      </h3>
                    </center>
                  </Col>
                  <Col md="9">
                  <h5 className="my-0"><Trans>index.products.productdescription1</Trans></h5>
                  </Col>
                </Row>
                

                <Row className="my-3 justify-content-center align-items-center" style={{ border: "solid var(--color-alternative-1-0)",borderRadius:"0.1875rem" }}>
                  <Col
                    md="3"
                    className="bg-alternative-1-0 justify-content-center align-items-center"
                  >
                    <center>
                      <h1 className="text-white my-4">
                        <FontAwesomeIcon icon={faChalkboardTeacher} />
                      </h1>
                      <h3 className="text-white my-4">
                      <Trans>index.products.producttitle2</Trans>
                      </h3>
                    </center>
                  </Col>
                  <Col md="9">
                  <h5 className="mb-2"><Trans>index.products.productdescription2</Trans></h5>
                  <h5 className="mb-2"><a href="/landing-videos"><PlaySquareOutlined className="mr-3" style={{ fontSize: '24px', verticalAlign: 'middle'}} /><Trans>landing.video1title</Trans></a></h5>
                  <h5 className="mb-2"><a href="/landing-videos"><PlaySquareOutlined className="mr-3" style={{ fontSize: '24px', verticalAlign: 'middle'}}/><Trans>landing.video2title</Trans></a></h5>
                  <h5 className="mb-2"><a href="/landing-videos"><PlaySquareOutlined className="mr-3" style={{ fontSize: '24px', verticalAlign: 'middle'}}/><Trans>landing.video3title</Trans></a></h5>
                  </Col>
                </Row>

                <Row className="my-3 justify-content-center align-items-center" style={{ border: "solid var(--color-alternative-2-0)",borderRadius:"0.1875rem" }}>
                  <Col
                    md="3"
                    className="bg-alternative-2-0 justify-content-center align-items-center"
                  >
                    <center>
                      <h1 className="text-white my-4">
                        <FontAwesomeIcon icon={faHandshake} />
                      </h1>
                      <h3 className="text-white my-4">
                        <Trans>index.products.producttitle4</Trans>
                      </h3>
                    </center>
                  </Col>
                  <Col md="9">
                  <h5 className="my-0"><Trans>index.products.productdescription4</Trans></h5>
                  </Col>
                </Row>

                
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default withTranslation()(Products);
