import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselCaption,
  CarouselIndicators
} from "reactstrap";

// i18N hook
import { Trans } from 'react-i18next';
import { withTranslation } from "react-i18next";

const items = [
  { 
    name: "Sergi del Moral",
    caption: <Trans>index.testimonials.testimonial1</Trans>,
    position: <Trans>index.testimonials.position1</Trans>,
    src: require("assets/img/sergi.jpg")
  },
  { 
    caption:  <Trans>index.testimonials.testimonial2</Trans>,
    name: "Sue Henley",
    position:  <Trans>index.testimonials.position2</Trans>,
    src: require("assets/img/sue.jpg")
  },
  { 
    caption:  <Trans>index.testimonials.testimonial3</Trans>,
    name: "Marc Marin",
    position: <Trans>index.testimonials.position3</Trans>,
    src: require("assets/img/marc.jpg")
  }
];

function Testimonials(props) {
  console.log(props);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="section" style={props.childStyle}>
        <Container>
          <div className="penta-title-alternate">
          <Trans>index.testimonials.title</Trans>
              </div>
         
              <Carousel
                className="no-shadow-carousel"
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
               
                {items.map(item => {
                  return (
                    
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.src}
                    >
                     
                      <div className="text-white">
                        <center>
                          
                          <Row>
                          <Col md="3">
                            <img alt="..." class="rounded" src={item.src} />
                          </Col>
                          <Col md="9">

                          <h1 className="testimonial" style={{marginTop:'2%'}}>{item.caption}</h1>
                         
                          <h5><span className="text-bold text-white">{item.name+', '}</span><span className="text-light-white">{item.position}</span></h5>

                          </Col>
                          </Row>
                         
                          
                        </center>
                        
                      </div>
                       
                    </CarouselItem>
                  );
                })}
               
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-left"></i>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </a>
              </Carousel>
           
        </Container>
      </div>
    </>
  );
}

export default withTranslation()(Testimonials);