/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';

// i18N hook
import { Trans } from 'react-i18next';
import { withTranslation } from "react-i18next";

// FontAwesome hook
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Member = (props) => {
    const {
        photo,
        name,
        description,
        linkedIn,
        email
    } = props;
    return (
        <div>
            <center>
                <img
                    alt="..."
                    className="rounded-circle w-75"
                    src={photo}
                ></img>
                <h4 className="mb-1 font-weight-bold">{name}</h4>
                {/*<h4 className="font-italic mt-1">{position}</h4>*/}
            </center>
            <p>{description}</p>
            <center>
                <div className="button-container">
                    <a href={linkedIn} rel="noopener noreferrer" target="_blank">
                        <Button
                            className="btn-round btn-icon"
                            color="default"
                            id="linkedin"
                            size="lg"
                        >
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </Button>
                    </a>
                    <UncontrolledTooltip delay={0} target="linkedin">
                        <Trans>misc.linkedin</Trans>
                      </UncontrolledTooltip>
                     
                   
                      <a href={"mailto:"+email} rel="noopener noreferrer" target="_blank">
                        <Button
                            className="btn-round btn-icon"
                            color="default"
                            id="tooltip515203352"
                            size="lg"
                        >
                            <FontAwesomeIcon icon={faEnvelope} />
                        </Button>
                    </a>
                    <UncontrolledTooltip delay={0} target="tooltip515203352">
                    <Trans>misc.sendmessage</Trans>
                      </UncontrolledTooltip>
                  
                </div>
            </center>

        </div>
    );
}

export default withTranslation()(Member);