import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Collapse, Space } from "antd";

// core components
import IndexNavbar from "components/Navbars/IndexNavbarLanding";
import PentaHeader from "components/Headers/PentaHeader";
import PentaFooter from "components/Footers/PentaFooter";

// i18N hook
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";

const { Panel } = Collapse;

function Terms() {
  return (
    <>
      <IndexNavbar showLogo={true} langs={[{langName:'English', langKey: 'en'}, {langName:'Català', langKey: 'ca'}, {langName: 'Castellano', langKey: 'es'}]} />
      <div className="wrapper">
        <PentaHeader
          pageTitle={<Trans>faq.title</Trans>}
          backColor={"var(--color-secondary-2-0)"}
        />
        <div className="main">
          <div className="section pt-2">
            <Container>
              
              <Row>
                <Col md="6">
                  <Space direction="vertical">
                    <Collapse>
                      <Panel
                        header={<Trans>faq.question1</Trans>}
                        key="1"
                      >
                        <p><Trans>faq.answer1</Trans></p>
                      </Panel>
                    </Collapse>
                    <Collapse>
                      <Panel
                        header={<Trans>faq.question2</Trans>}
                        key="1"
                      >
                        <p><Trans>faq.answer2</Trans></p>
                      </Panel>
                    </Collapse>
                    <Collapse>
                      <Panel
                        header={<Trans>faq.question3</Trans>}
                        key="1"
                      >
                        <p><Trans>faq.answer3</Trans></p>
                      </Panel>
                    </Collapse>
                    <Collapse>
                      <Panel
                        header={<Trans>faq.question4</Trans>}
                        key="1"
                      >
                        <p><Trans>faq.answer4</Trans></p>
                      </Panel>
                    </Collapse>
                   
                  </Space>
                </Col>
                <Col md="6">
                  <Space direction="vertical">
                  <Collapse>
                      <Panel
                        header={<Trans>faq.question5</Trans>}
                        key="1"
                      >
                        <p><Trans>faq.answer5</Trans></p>
                      </Panel>
                    </Collapse>
                    <Collapse>
                      <Panel
                        header={<Trans>faq.question6</Trans>}
                        key="1"
                      >
                        <p><Trans>faq.answer6</Trans></p>
                      </Panel>
                    </Collapse>
                    <Collapse>
                      <Panel
                        header={<Trans>faq.question7</Trans>}
                        key="1"
                      >
                        <p><Trans>faq.answer7</Trans></p>
                      </Panel>
                    </Collapse>
                    <Collapse>
                      <Panel
                        header={<Trans>faq.question8</Trans>}
                        key="1"
                      >
                        <p><Trans>faq.answer8</Trans></p>
                      </Panel>
                    </Collapse>
                  </Space>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <PentaFooter />
      </div>
    </>
  );
}

export default withTranslation()(Terms);
