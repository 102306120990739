/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// the hook
import { Trans } from 'react-i18next';
import { withTranslation } from "react-i18next";

function PentaFooter() {
  return (
    <>
    <footer className="footer bg-alternative-3-5">
       <Container>
            <Row>
              <Col md="6">
                <div className="text-left">
                  © {new Date().getFullYear()}{" "}
                    Pentabilities S.L. | <a href="/terms">Terms and Conditions </a>
                </div>
              </Col>
              <Col md="6">
                <div className="text-right">
                <Trans>misc.made</Trans> <i className="fa fa-heart color-primary-0"></i> <Trans>misc.in</Trans> Barcelona

                </div>
                
              </Col>
            </Row>
          </Container>
    </footer>
   
</>
  );
}

export default withTranslation()(PentaFooter);