import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// i18N hook
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";
import i18next from "i18next";

function Motivation() {
  return (
    <>
      <div className="section">
        <Container>
          <div className="penta-title-secondary-1-0">
            <Trans>index.motivation.title</Trans>
          </div>

          <Row>
            <Col md="6" className="align-self-center">
              <div>
                <blockquote>
                  <h4 className="blockquote-penta blockquote-secondary-2-0 no-margin-top">
                    <center>
                      <Trans>index.motivation.goal</Trans>
                    </center>
                  </h4>
                </blockquote>
              </div>
            </Col>
            <Col
              md="6"
              className="align-self-center  d-none d-sm-none d-md-block "
            >
              <center>
                <img
                  src={require("assets/img/infographics/Lock-" +
                    i18next.language.substring(0, 2) +
                    ".jpg")}
                />
              </center>
              <p className="blockquote-footer">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://easel.gse.harvard.edu/taxonomy-project"
                >
                  <Trans>index.motivation.taxonomy</Trans>
                </a>{" "}
                <Trans>index.motivation.taxonomydescription</Trans>
              </p>
            </Col>
            <Col md="6">
              <h4>
                <Trans>index.motivation.description1</Trans>
              </h4>
            </Col>
            <Col
              md="6"
              className="align-self-center d-block d-sm-block d-md-none"
            >
              <center>
                <img
                  src={require("assets/img/infographics/Lock-" +
                    i18next.language.substring(0, 2) +
                    ".jpg")}
                />
              </center>
              <p className="blockquote-footer">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://easel.gse.harvard.edu/taxonomy-project"
                >
                  <Trans>index.motivation.taxonomy</Trans>
                </a>{" "}
                <Trans>index.motivation.taxonomydescription</Trans>
              </p>
            </Col>

            <Col md="6">
              <div>
                <h4>
                  <Trans>index.motivation.description3</Trans>
                </h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default withTranslation()(Motivation);
