import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { BackTop } from "antd";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar";
import PentaHeader from "components/Headers/PentaHeader";
import PentaFooter from "components/Footers/PentaFooter";
import Player from "components/Video/Player";

// i18N hook
import { Trans } from "react-i18next";
import { useTranslation, withTranslation } from "react-i18next";

import { Alert } from "antd";
import Cookies from "js-cookie";

function Terms() {
  
  const { t, i18n } = useTranslation();
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  useEffect(async () => {
    let location = {};
    const response = await fetch("https://ipapi.co/json/");
    if (response.status === 200) {
      const data = await response.json();
      location = data;
    }

    let userLang = Cookies.get("i18next") ? Cookies.get("i18next") : false;
    let defaultLang;
    if (location && location.country) {
      let ipLang = location.languages.split(",")[0].split("-")[0];
      setCountry(location.country);
      setRegion(location.region_code);
      console.log(location);
      if (location.country === "ES" && location.region_code === "CT") {
        defaultLang = "ca";
      } else {
        if (ipLang === "es" || ipLang === "ca" || ipLang === "en") {
          defaultLang = ipLang;
        } else {
          defaultLang = "en";
        }
      }
    }
    

    if (defaultLang && !userLang) {
      i18n.changeLanguage(defaultLang);
    }
  }, []);

  return (
    <>
      <BackTop />
      <IndexNavbar />
      <div className="wrapper">
        <PentaHeader pageTitle={<Trans>landing.title</Trans>} />
        <div className="main">
          <div className="section pt-2">
            <Container>
              <Row>
                <Col md="12">
                  <Alert
                    className="my-4"
                    message={
                      <h5 className="mb-0">
                        <Trans>landing.alert1</Trans>
                        <ul>
                          <li>
                            <Trans>landing.alert2</Trans>{" "}
                            <a href="https://learning.pentabilities.com">
                              <Trans>landing.alert3</Trans>
                            </a>
                            .
                          </li>
                          <li>
                            <Trans>landing.alert4</Trans>{" "}
                            <a href="/index#contact">
                              <Trans>landing.alert5</Trans>
                            </a>{" "}
                            <Trans>landing.alert6</Trans>
                          </li>
                        </ul>
                      </h5>
                    }
                    type="info"
                  />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md="6">
                  <h1 className="my-0 my-4 penta-h1">
                    <Trans>landing.video1title</Trans>
                  </h1>
                </Col>
                <Col className="align-items-center" md="6">
                  {i18n.language.includes("en") && (
                    <Player url="https://firebasestorage.googleapis.com/v0/b/givme5-2e84c.appspot.com/o/kb%2Fonlinecourse%2Fen%2F1%20ENG%20v6.mp4?alt=media&token=54e82993-0684-418b-90e6-ddc1ce0cf528" />
                  )}
                  {i18n.language.includes("es") && (
                    <Player url="https://firebasestorage.googleapis.com/v0/b/givme5-2e84c.appspot.com/o/kb%2Fonlinecourse%2Fes%2F1%20CAST%20v15.mp4?alt=media&token=7d321527-35c4-4052-943f-2d8dfe1d893d" />
                  )}
                  {i18n.language.includes("ca") && (
                    <Player url="https://firebasestorage.googleapis.com/v0/b/givme5-2e84c.appspot.com/o/kb%2Fonlinecourse%2Fca%2F1%20CAT%20v15.mp4?alt=media&token=9494c9c8-3a9e-4a91-97b2-ff696b5a16af" />
                  )}
                </Col>
                <h4>
                  <ul>
                    <li>
                      <Trans>landing.video1key1</Trans>
                    </li>
                    <li>
                      <Trans>landing.video1key2</Trans>
                    </li>
                    <li>
                      <Trans>landing.video1key3</Trans>
                    </li>
                  </ul>
                </h4>
              </Row>
            </Container>
          </div>
          <div className="section bg-alternative-3-5">
            <Container>
              <Row className="align-items-center">
                <Col md="6">
                  {i18n.language.includes("en") && (
                    <Player url="https://firebasestorage.googleapis.com/v0/b/givme5-2e84c.appspot.com/o/kb%2Fonlinecourse%2Fen%2F2%20ENG%20v10.mp4?alt=media&token=88e06663-32d4-4d15-96bf-994f63c775a0" />
                  )}
                  {i18n.language.includes("es") && (
                    <Player url="https://firebasestorage.googleapis.com/v0/b/givme5-2e84c.appspot.com/o/kb%2Fonlinecourse%2Fes%2F2%20CAST%20v15.mp4?alt=media&token=98dd717f-6fc6-41b5-b94e-6719899be625" />
                  )}
                  {i18n.language.includes("ca") && (
                    <Player url="https://firebasestorage.googleapis.com/v0/b/givme5-2e84c.appspot.com/o/kb%2Fonlinecourse%2Fca%2F2%20CAT%20v15.mp4?alt=media&token=02059706-1896-48f9-be52-55807345e60e" />
                  )}
                </Col>
                <Col md="6">
                  <h1
                    style={{ textAlign: "right" }}
                    className="my-0 my-4 penta-h1"
                  >
                    <Trans>landing.video2title</Trans>
                  </h1>
                </Col>

                <h4>
                  <ul>
                    <li>
                      <Trans>landing.video2key1</Trans>
                    </li>
                    <li>
                      <Trans>landing.video2key2</Trans>
                    </li>
                    <li>
                      <Trans>landing.video2key3</Trans>
                    </li>
                    <li>
                      <Trans>landing.video2key4</Trans>
                    </li>
                  </ul>
                </h4>
              </Row>
            </Container>
          </div>
          <div className="section pt-2">
            <Container>
              <Row className="align-items-center">
                <Col md="6">
                  <h1 className="my-0 my-4 penta-h1">
                    <Trans>landing.video3title</Trans>
                  </h1>
                </Col>
                <Col className="align-items-center" md="6">
                  {i18n.language.includes("en") && (
                    <Player url="https://firebasestorage.googleapis.com/v0/b/givme5-2e84c.appspot.com/o/kb%2Fonlinecourse%2Fen%2F3%20ENG%20v10.mp4?alt=media&token=0348e32f-bd13-49f2-a083-6567ca38a66d" />
                  )}
                  {i18n.language.includes("es") && (
                    <Player url="https://firebasestorage.googleapis.com/v0/b/givme5-2e84c.appspot.com/o/kb%2Fonlinecourse%2Fes%2F3%20CAST%20v15.mp4?alt=media&token=f8ac5ce1-b77a-4223-9789-090894f8b6e5" />
                  )}
                  {i18n.language.includes("ca") && (
                    <Player url="https://firebasestorage.googleapis.com/v0/b/givme5-2e84c.appspot.com/o/kb%2Fonlinecourse%2Fca%2F3%20CAT%20v15.mp4?alt=media&token=e3caced1-3175-49f9-85d2-4e106be0ca10" />
                  )}
                </Col>
                <h4>
                  <ul>
                    <li>
                      <Trans>landing.video3key1</Trans>
                    </li>
                    <li>
                      <Trans>landing.video3key2</Trans>
                    </li>
                    <li>
                      <Trans>landing.video3key3</Trans>
                    </li>
                  </ul>
                </h4>
              </Row>
            </Container>
          </div>
        </div>
        <PentaFooter />
      </div>
    </>
  );
}

export default withTranslation()(Terms);
