import React from "react";
import { Row, Col, Container } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbarLanding";
import PentaHeader from "components/Headers/PentaHeader";
import PentaFooter from "components/Footers/PentaFooter";

// i18N hook
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";

function Terms() {
  return (
    <>
      <IndexNavbar showLogo={true} langs={[{langName:'English', langKey: 'en'}, {langName:'Català', langKey: 'ca'}, {langName: 'Castellano', langKey: 'es'}]} />
      <div className="wrapper">
        <PentaHeader
          pageTitle={<Trans>whats.title</Trans>}
          backColor={'var(--color-secondary-2-0)'}
        />
        <div className="main">
          <div className="section pt-2">
            <Container>
              <Row>
                <Col md="12">
                  <br />
                  <br />
                  <p>
                    <span style={{ color: "var(--color-secondary-2-0)", fontWeight: "700" }}>
                      <Trans>whats.text1</Trans>
                    </span>{" "}
                    <Trans>whats.text2</Trans>{" "}
                    <span style={{ color: "var(--color-secondary-2-0)", fontWeight: "700" }}>
                      <Trans>whats.text3</Trans>
                    </span>
                  </p>
                  <p>
                    <Trans>whats.text4</Trans>
                  </p>
                  < br/>
                  <center>
                    <h2 style={{fontWeight:'bold'}}>
                      <Trans>whats.text5</Trans>
                    </h2>
                  </center>
                  <ol>
                    <li>
                      <p>
                        <Trans>whats.text6</Trans>
                        <span style={{ color: "var(--color-secondary-2-0)", fontWeight: "700" }}>
                          {" "}
                          <Trans>whats.text7</Trans>
                        </span>
                        <Trans>whats.text8</Trans>
                        <span style={{ color: "var(--color-secondary-2-0)", fontWeight: "700" }}>
                          {" "}
                          <Trans>whats.text9</Trans>{" "}
                        </span>
                        <Trans>whats.text10</Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <Trans>whats.text11</Trans>
                        <span style={{ color: "var(--color-secondary-2-0)", fontWeight: "700" }}>
                          {" "}
                          <Trans>whats.text12</Trans>{" "}
                        </span>
                        <Trans>whats.text13</Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ color: "var(--color-secondary-2-0)", fontWeight: "700" }}>
                          <Trans>whats.text14</Trans>{" "}
                        </span>
                        <Trans>whats.text15</Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ color: "var(--color-secondary-2-0)", fontWeight: "700" }}>
                          <Trans>whats.text16</Trans>{" "}
                        </span>
                        <Trans>whats.text17</Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style={{ color: "var(--color-secondary-2-0)", fontWeight: "700" }}>
                          <Trans>whats.text18</Trans>{" "}
                        </span>
                        <Trans>whats.text19</Trans>
                      </p>
                    </li>
                  </ol>

                  <br />
                  <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                      <img src={require("assets/img/landing-1.png")} />
                      <p>
                        <Trans>whats.text20</Trans>
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <PentaFooter />
      </div>
    </>
  );
}

export default withTranslation()(Terms);
