import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

// i18N hook
import { Trans } from 'react-i18next';
import { withTranslation } from "react-i18next";

// FontAwesome hook
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faToggleOn, faTag, faChild, faUsers, faChartPie } from '@fortawesome/free-solid-svg-icons'

function Project() {
    return (
        <>
            <div className="section pt-1">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="penta-title mt-1">
                                <Trans>products.tools.title</Trans>
                            </div>
                            <h5><Trans>products.tools.description</Trans></h5>
                            <h3><Trans>products.tools.mobile</Trans></h3>
                            <h5><Trans>products.tools.mobileshortdescription</Trans></h5>
                        </Col>

                    </Row>
                    <Row>
                        <Col md="4">
                            <center>

                                <div class="bg-primary-0 text-white indicator-div"><i className="indicator-icon"><FontAwesomeIcon icon={faList} /></i></div>
                                <h5><Trans>products.tools.mobileitem1</Trans></h5>
                            </center>
                        </Col>
                        <Col md="4">
                            <center>
                                <div class="bg-secondary-1-0 text-white indicator-div"><i className="indicator-icon"><FontAwesomeIcon icon={faToggleOn} /></i></div>
                                <h5><Trans>products.tools.mobileitem2</Trans></h5>
                            </center>
                        </Col>
                        <Col md="4">
                            <center>
                                <div class="bg-secondary-2-0 text-white indicator-div"><i className="indicator-icon"><FontAwesomeIcon icon={faTag} /></i></div>
                                <h5><Trans>products.tools.mobileitem3</Trans></h5>
                            </center>
                        </Col>
                    </Row>

                    <Row className="py-5">
                        <Col md="6">
                            <h4><Trans>misc.demo</Trans></h4>
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe title="How to use Pentabilities mobile app" className="embed-responsive-item" src="https://www.youtube.com/embed/stoJqMx3o4w" allowfullscreen></iframe>
                            </div>
                        </Col>
                        <Col md="6">
                            <img alt="Pentabilities mobile app" src={require("assets/img/app.png")}></img>
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <h3><Trans>products.tools.web</Trans></h3>
                            <h5><Trans>products.tools.webshortdescription</Trans></h5>
                        </Col>

                    </Row>
                    <Row>
                        <Col md="4">
                            <center>

                                <div class="bg-primary-0 text-white indicator-div"><i className="indicator-icon"><FontAwesomeIcon icon={faChild} /></i></div>
                                <h5><Trans>products.tools.webitem1</Trans></h5>
                            </center>
                        </Col>
                        <Col md="4">
                            <center>
                                <div class="bg-secondary-1-0 text-white indicator-div"><i className="indicator-icon"><FontAwesomeIcon icon={faUsers} /></i></div>
                                <h5><Trans>products.tools.webitem2</Trans></h5>
                            </center>
                        </Col>
                        <Col md="4">
                            <center>
                                <div class="bg-secondary-2-0 text-white indicator-div"><i className="indicator-icon"><FontAwesomeIcon icon={faChartPie} /></i></div>
                                <h5><Trans>products.tools.webitem3</Trans></h5>
                            </center>
                        </Col>
                    </Row>

                    <Row className="py-5">
                    <Col md="6">
                            <img alt="Pentabilities web app" src={require("assets/img/webapp.png")}></img>
                        </Col>
                        <Col md="6">
                            <h4><Trans>misc.demo</Trans></h4>
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe title="How to use Pentabilities web app" className="embed-responsive-item" src="https://www.youtube.com/embed/CXu6kRNPfmo" allowfullscreen></iframe>
                            </div>
                        </Col>
                        
                    </Row>
                    

                    
                </Container>
            </div>

        </>
    )
}

export default withTranslation()(Project);