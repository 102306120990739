import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

// i18N hook
import { Trans } from 'react-i18next';
import { withTranslation } from "react-i18next";

function Project() {
    return (
        <>
            <div className="section pt-1">
                <Container>
                    <div className="penta-title mt-1">
                        <Trans>research.project.title</Trans>
                    </div>
                    <Row>
                        <Col md="12">
                            <h5>
                                <Trans>research.project.description11</Trans>
                                <br></br><br></br>
                                <Trans>research.project.description12</Trans>
                                <br></br><br></br>
                                <Trans>research.project.description13</Trans>
                            </h5>

                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )
}

export default withTranslation()(Project);