import React from 'react';
import { Row, Col, Container } from 'reactstrap';

// core components
import IndexNavbar from "components/Navbars/IndexNavbar";
import PentaHeader from "components/Headers/PentaHeader";
import PentaFooter from "components/Footers/PentaFooter";

// i18N hook
import { Trans } from 'react-i18next';
import { withTranslation } from "react-i18next";

function Terms() {
    return (
        <>
            <IndexNavbar />
            <div className="wrapper">
                <PentaHeader pageTitle={"Terms & conditions"} />
                <div className="main">

                    <div className="section pt-2">
                        <Container>
                            <Row>
                                <Col md="12">

                                    <h3><Trans>terms.line1</Trans></h3>
                                    <p><Trans>terms.line2</Trans></p>
                                    <p><Trans>terms.line3</Trans></p>
                                    <h3><Trans>terms.line4</Trans></h3>
                                    <p><Trans>terms.line5</Trans></p>
                                    <p><Trans>terms.line6</Trans></p>
                                    <h4>
                                        <ol type="A" start="1">
                                            <li><Trans>terms.line7</Trans></li>
                                        </ol>
                                    </h4>
                                    <p><Trans>terms.line8</Trans></p>
                                    <p><Trans>terms.line9</Trans></p>
                                    <h4>
                                        <ol type="A" start="2">
                                            <li><Trans>terms.line10</Trans></li>
                                        </ol>
                                    </h4>
                                    <p><Trans>terms.line11</Trans></p>
                                    <p><Trans>terms.line12</Trans></p>
                                    <p><Trans>terms.line13</Trans></p>
                                    <p><Trans>terms.line14</Trans></p>
                                    <p><Trans>terms.line15</Trans></p>
                                    <h4>
                                        <ol type="A" start="3">
                                            <li><Trans>terms.line16</Trans></li>
                                        </ol>
                                    </h4>
                                    <p><Trans>terms.line17</Trans></p>
                                    <p>
                                        <ul>
                                            <li><Trans>terms.line18</Trans></li>
                                            <li><Trans>terms.line19</Trans></li>
                                            <li><Trans>terms.line20</Trans></li>
                                            <li><Trans>terms.line21</Trans></li>
                                            <li><Trans>terms.line22</Trans></li>
                                            <li><Trans>terms.line23</Trans></li>
                                            <li><Trans>terms.line24</Trans></li>
                                            <li><Trans>terms.line25</Trans></li>
                                        </ul>
                                    </p>
                                    <p><Trans>terms.line26</Trans></p>
                                    <p><Trans>terms.line27</Trans></p>
                                    <p><Trans>terms.line28</Trans></p>
                                    <p><Trans>terms.line29</Trans></p>
                                    <p><Trans>terms.line30</Trans></p>
                                    <p><Trans>terms.line31</Trans></p>
                                    <p><Trans>terms.line32</Trans></p>
                                    <p><Trans>terms.line33</Trans></p>
                                    <p><Trans>terms.line34</Trans></p>
                                    <p><Trans>terms.line35</Trans></p>
                                    <p><Trans>terms.line36</Trans></p>
                                    <p><Trans>terms.line37</Trans></p>
                                    <h4>
                                        <ol type="A" start="4">
                                            <li><Trans>terms.line38</Trans></li>
                                        </ol>
                                    </h4>
                                    <p><Trans>terms.line39</Trans></p>
                                    <h4>
                                        <ol type="A" start="4">
                                            <li>
                                                <ol start="1">
                                                    <li><Trans>terms.line40</Trans></li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </h4>
                                    <p><Trans>terms.line41</Trans></p>
                                    <p>
                                        <ol>
                                            <li><Trans>terms.line42</Trans></li>
                                            <li><Trans>terms.line43</Trans></li>
                                            <li><Trans>terms.line44</Trans></li>
                                            <li><Trans>terms.line45</Trans></li>
                                            <li><Trans>terms.line46</Trans></li>
                                        </ol>
                                    </p>
                                    <p><Trans>terms.line47</Trans></p>
                                    <p><Trans>terms.line48</Trans></p>
                                    <p>
                                        <ol>
                                            <li><Trans>terms.line50</Trans></li>
                                            <li><Trans>terms.line51</Trans></li>
                                            <li><Trans>terms.line52</Trans></li>
                                            <li><Trans>terms.line53</Trans></li>
                                            <li><Trans>terms.line54</Trans></li>
                                            <li><Trans>terms.line55</Trans></li>
                                            <li><Trans>terms.line56</Trans></li>
                                        </ol>
                                    </p>
                                    <h4>
                                        <ol type="A" start="4">
                                            <li>
                                                <ol start="2">
                                                    <li><Trans>terms.line57</Trans></li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </h4>
                                    <p><Trans>terms.line58</Trans></p>
                                    <p>
                                        <ol>
                                            <li><Trans>terms.line59</Trans></li>
                                            <li><Trans>terms.line60</Trans></li>
                                            <li><Trans>terms.line61</Trans></li>
                                        </ol>
                                    </p>
                                    <h4>
                                        <ol type="A" start="4">
                                            <li>
                                                <ol start="3">
                                                    <li><Trans>terms.line62</Trans></li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </h4>
                                    <p><Trans>terms.line63</Trans></p>
                                    <p>
                                        <ol>
                                            <li><Trans>terms.line64</Trans></li>
                                            <li><Trans>terms.line65</Trans></li>
                                            <li><Trans>terms.line66</Trans></li>
                                            <li><Trans>terms.line67</Trans></li>
                                            <li><Trans>terms.line68</Trans></li>
                                        </ol>
                                    </p>
                                    <h4>
                                        <ol type="A" start="5">
                                            <li><Trans>terms.line69</Trans></li>
                                        </ol>
                                    </h4>
                                    <p><Trans>terms.line70</Trans></p>
                                    <p>
                                        <ul>
                                            <li><Trans>terms.line71</Trans></li>
                                            <li><Trans>terms.line72</Trans></li>
                                            <li><Trans>terms.line73</Trans></li>
                                            <li><Trans>terms.line74</Trans></li>
                                            <li><Trans>terms.line75</Trans></li>
                                            <li><Trans>terms.line76</Trans></li>
                                            <li><Trans>terms.line77</Trans></li>
                                        </ul>
                                    </p>
                                    <p>
                                        <ol>
                                            <li><Trans>terms.line78</Trans></li>
                                        </ol>
                                    </p>
                                    <p><Trans>terms.line79</Trans></p>
                                    <p>
                                        <ol start="2">
                                            <li><Trans>terms.line80</Trans></li>
                                        </ol>
                                    </p>
                                    <p><Trans>terms.line81</Trans></p>
                                    <p>
                                        <ul>
                                            <li><Trans>terms.line82</Trans></li>
                                        </ul>
                                    </p>
                                    <p>
                                        <ol start="3">
                                            <li><Trans>terms.line83</Trans></li>
                                        </ol>
                                    </p>
                                    <p><Trans>terms.line84</Trans></p>
                                    <p>
                                        <ul>
                                            <li><Trans>terms.line85</Trans></li>
                                        </ul>
                                    </p>
                                    <p><Trans>terms.line86</Trans></p>
                                    <p><Trans>terms.line87</Trans></p>
                                    <p><Trans>terms.line88</Trans></p>
                                    <p>
                                        <ul>
                                            <li><Trans>terms.line89</Trans></li>
                                        </ul>
                                    </p>
                                    <p><Trans>terms.line90</Trans></p>
                                    <p><Trans>terms.line91</Trans></p>
                                    <p><Trans>terms.line92</Trans></p>
                                    <p><Trans>terms.line93</Trans></p>
                                    <p>
                                        <ol start="4">
                                            <li><Trans>terms.line94</Trans></li>
                                        </ol>
                                    </p>
                                    <p><Trans>terms.line95</Trans></p>
                                    <p>
                                        <ol start="5">
                                            <li><Trans>terms.line96</Trans></li>
                                        </ol>
                                    </p>
                                    <p><Trans>terms.line97</Trans></p>
                                    <p>
                                        <ul>
                                            <li><Trans>terms.line98</Trans></li>
                                            <li><Trans>terms.line99</Trans></li>
                                            <li><Trans>terms.line100</Trans></li>
                                            <li><Trans>terms.line101</Trans></li>
                                            <li><Trans>terms.line102</Trans></li>
                                            <li><Trans>terms.line103</Trans></li>
                                        </ul>
                                    </p>
                                    <p><Trans>terms.line104</Trans></p>
                                    <p><Trans>terms.line105</Trans></p>
                                    <p>
                                        <ol start="6">
                                            <li><Trans>terms.line106</Trans></li>
                                        </ol>
                                    </p>
                                    <p><Trans>terms.line107</Trans></p>
                                    <p>
                                        <ol start="7">
                                            <li><Trans>terms.line108</Trans></li>
                                        </ol>
                                    </p>
                                    <p><Trans>terms.line109</Trans></p>
                                    <p><Trans>terms.line110</Trans></p>
                                    <p><Trans>terms.line111</Trans></p>
                                    <h4>
                                        <ol type="A" start="6">
                                            <li><Trans>terms.line112</Trans></li>
                                        </ol>
                                    </h4>
                                    <p><Trans>terms.line113</Trans></p>
                                    <p><Trans>terms.line114</Trans></p>
                                    <p><Trans>terms.line115</Trans></p>
                                    <p><Trans>terms.line116</Trans></p>
                                    <p><Trans>terms.line117</Trans></p>
                                    <p><Trans>terms.line118</Trans></p>
                                    <p><Trans>terms.line119</Trans></p>
                                    <p><Trans>terms.line120</Trans></p>
                                    <h4>
                                        <ol type="A" start="7">
                                            <li><Trans>terms.line121</Trans></li>
                                        </ol>
                                    </h4>
                                    <p><Trans>terms.line122</Trans></p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <PentaFooter />
            </div>


        </>

    );
}

export default withTranslation()(Terms);


