import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// i18N hook
import { Trans } from 'react-i18next';
import { withTranslation } from "react-i18next";
import i18next from 'i18next';

// FontAwesome hook
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'

function Challenges() {
    return (
        <>
            <div className="section">
                <Container>
                    <Row>

                        <Col md="6">
                            <div className="penta-title-secondary-2-0"><Trans>index.challenges.title1</Trans></div>
                            <h5 className="color-secondary-2-2"><FontAwesomeIcon icon={faTimes} /> <Trans>index.challenges.item1</Trans></h5>
                            <h5 className="color-secondary-2-2"><FontAwesomeIcon icon={faTimes} /> <Trans>index.challenges.item2</Trans></h5>
                            <h5 className="color-secondary-2-2"><FontAwesomeIcon icon={faTimes} /> <Trans>index.challenges.item3</Trans></h5>
                            <h5 className="color-secondary-2-2"><FontAwesomeIcon icon={faTimes} /> <Trans>index.challenges.item4</Trans></h5>
                        </Col>
                        <Col md="6">
                        <img src={require('assets/img/infographics/Doodle-'+i18next.language.substring(0,2)+'.png')} />
                        </Col>
                        <Col md="6">
                            <center>
                                <img
                                    className="pt-5"
                                    src={require("assets/img/brand/logomark.svg")}
                                    style={{
                                        maxWidth: '15%'
                                    }}
                                    alt="Pentabilities"
                                />
                                <Col md="10">
                                    <h3 className="color-alternative-2-0 font-weight-bolder"><Trans>index.challenges.description3</Trans></h3>
                                </Col>
                            </center>
                        </Col>
                        <Col md="6">
                            <div className="penta-title-alternative-2-0"><Trans>index.challenges.title2</Trans></div>
                            <h5 className="color-alternative-2-0"><FontAwesomeIcon icon={faCheck} /> <Trans>index.challenges.item5</Trans></h5>
                            <h5 className="color-alternative-2-0"><FontAwesomeIcon icon={faCheck} /> <Trans>index.challenges.item6</Trans></h5>
                            <h5 className="color-alternative-2-0"><FontAwesomeIcon icon={faCheck} /> <Trans>index.challenges.item7</Trans></h5>
                            <h5 className="color-alternative-2-0"><FontAwesomeIcon icon={faCheck} /> <Trans>index.challenges.item8</Trans></h5>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default withTranslation()(Challenges);