import React, { useEffect, useState } from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar";
import HomeHeader from "components/Headers/HomeHeader";
import PentaFooter from "components/Footers/PentaFooter";
import { BackTop } from "antd";

// sections for this page
import Motivation from "./index-sections/Motivation";
import Challenges from "./index-sections/Challenges";
import Learning from "./index-sections/Learning";
import Products from "./index-sections/Products";
import Testimonials from "./index-sections/Testimonials";
import Projects from "./index-sections/Projects";
import Customers from "./index-sections/Customers";
import Contact from "./index-sections/Contact";
import Annoucements from "./index-sections/Annoucements";
import { useTranslation, withTranslation } from "react-i18next";

import Cookies from "js-cookie";

const Index = (props) => {
  const { t, i18n } = useTranslation();
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  useEffect(async () => {
    let location = {};
    const response = await fetch("https://ipapi.co/json/");
    if (response.status === 200) {
      const data = await response.json();
      location = data;
    }

    let userLang = Cookies.get("i18next") ? Cookies.get("i18next") : false;
    let defaultLang;
    if (location && location.country) {
      let ipLang = location.languages.split(",")[0].split("-")[0];
      setCountry(location.country);
      setRegion(location.region_code);
      console.log(location);
      if (location.country === "ES" && location.region_code === "CT") {
        defaultLang = "ca";
      } else {
        if (ipLang === "es" || ipLang === "ca" || ipLang === "en") {
          defaultLang = ipLang;
        } else {
          defaultLang = "en";
        }
      }
    }
    

    if (defaultLang && !userLang) {
      i18n.changeLanguage(defaultLang);
      Cookies.set('i18next', defaultLang);
    }
  }, []);
  return (
    <>
      <BackTop />
      <IndexNavbar
        langs={[
          { langName: "English", langKey: "en" },
          { langName: "Català", langKey: "ca" },
          { langName: "Castellano", langKey: "es" },
        ]}
      />
      <div className="wrapper">
        <HomeHeader />
        <div className="main">
          
          <Challenges />

          <Learning />
          <Products />
          <Projects pentaTitle={true} />
          <Testimonials
            childStyle={{ backgroundColor: "var(--color-secondary-2-0)" }}
          />

          <Customers />
          <Contact />
        </div>
        <PentaFooter />
      </div>
    </>
  );
};

export default withTranslation()(Index);
