import React from "react";
import ReactPlayer from "react-player";
import { findDOMNode } from "react-dom";
import screenfull from "screenfull";

import { Button, Slider } from "antd";
import {
  CaretRightOutlined,
  PauseOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";

const Test = (props) => {
  const [url, setUrl] = React.useState(props.url);
  const [playing, setPlaying] = React.useState(false);
  const [played, setPlayed] = React.useState(0);
  const [seeking, setSeeking] = React.useState(false);
  const player = React.useRef(null);

  const handlePlay = () => {
    setPlaying(!playing);
  };
  const handleSlider = (value) => {
    player.current.seekTo(value / 1000);
  };
  const handleProgress = (state) => {
    if (!seeking) {
      setPlayed(state.played * 1000);
    }
  };
  const fullScreen = () => {
    console.log("fulle");
    screenfull.request(findDOMNode(player.current));
  };

  return (
    <>
      <ReactPlayer
        ref={player}
        url={url}
        width="100%"
        controls={true}
        onProgress={handleProgress}
        height="100%"
        playing={playing}
        config={{
          file: {
            attributes: {
              onContextMenu: (e) => e.preventDefault(),
              controlsList: "nodownload",
            },
          },
        }}
      />
      {/*<Button  onClick={handlePlay} type="primary" shape="circle" icon={playing?<PauseOutlined />:<CaretRightOutlined />} />
        <Slider
            min={0}
            max={1000}
            onChange={handleSlider}
            value = {played}

        />
        <Button onClick={fullScreen} type="primary" shape="circle" icon={<FullscreenOutlined />} />
*/}
    </>
  );
};

export default Test;
